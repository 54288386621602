import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function Txt({ URL }) {
  const [contentTxt, setContentTxt] = useState('')

  useEffect(() => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'text/plain; charset=UTF-8')
    fetch(URL)
      .then(r => r.arrayBuffer())
      .then(buf => {
        const decoder = new TextDecoder('utf-8')
        const text = decoder.decode(buf).replace(/\n/g, '<br />')
        setContentTxt(text)
      })
      .catch(() => {
        setContentTxt('')
      })
  }, [])

  return (
    <div
      style={{
        width: '80vw',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: '20px',
        margin: 'auto',
        marginTop: '100px',
      }}
    >
      {/* <div>{contentTxt}</div> */}
      <div
        style={{
          height: '80vh',
          overflowY: 'scroll',
        }}
        dangerouslySetInnerHTML={{ __html: contentTxt }}
      />
    </div>
  )
}

export default Txt
