import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_FOLDER_PATH, MY_FOLDER, SHARED_FOLDER, TRASH_BIN } from 'config/constant'
import { verifyPath } from 'utils'

const initialState = {
  currentFolder: DEFAULT_FOLDER_PATH,
  modeFolder: MY_FOLDER,
  inMyFolder: true,
  inSharedFolder: false,
  inTrashBin: false,
}

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    resetRouter: state => {
      state.currentFolder = DEFAULT_FOLDER_PATH
      state.modeFolder = MY_FOLDER
      state.inMyFolder = true
      state.inSharedFolder = false
      state.inTrashBin = false
    },

    toMyFolder: state => {
      state.currentFolder = DEFAULT_FOLDER_PATH
      state.modeFolder = MY_FOLDER
      state.inMyFolder = true
      state.inSharedFolder = false
      state.inTrashBin = false
    },
    toSharedFolder: state => {
      state.currentFolder = DEFAULT_FOLDER_PATH
      state.modeFolder = SHARED_FOLDER
      state.inMyFolder = false
      state.inSharedFolder = true
      state.inTrashBin = false
    },
    toTrashBin: state => {
      state.currentFolder = DEFAULT_FOLDER_PATH
      state.modeFolder = TRASH_BIN
      state.inMyFolder = false
      state.inSharedFolder = false
      state.inTrashBin = true
    },
    setModeFolder: (state, action) => {
      if (action.payload) state.modeFolder = action.payload
      state.inMyFolder = action.payload === MY_FOLDER
      state.inSharedFolder = action.payload === SHARED_FOLDER
      state.inTrashBin = action.payload === TRASH_BIN
    },
    setCurrentFolder: (state, action) => {
      state.currentFolder = verifyPath(action.payload)
    },
    setRouter: (state, action) => {
      const { currentFolder, modeFolder } = action.payload
      state.modeFolder = modeFolder
      state.currentFolder = verifyPath(currentFolder)
      state.inMyFolder = modeFolder === MY_FOLDER
      state.inSharedFolder = modeFolder === SHARED_FOLDER
      state.inTrashBin = modeFolder === TRASH_BIN
    },
  },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = routerSlice
export const {
  resetRouter,
  toMyFolder,
  toSharedFolder,
  toTrashBin,
  setCurrentFolder,
  setModeFolder,
  setRouter,
} = actions
export default reducer
