import { Button } from 'antd'
import React from 'react'
import Ripples from 'react-ripples'

export function ButtonIcon({ icon, onClick }) {
  return (
    <Ripples>
      <Button shape="circle" type="link" icon={icon} onClick={onClick} />
    </Ripples>
  )
}

export default { ButtonIcon }
