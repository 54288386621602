import { ACTION, MODAL_TYPES } from 'config/constant'
import { publish } from 'events/event'
import {
  onSelect,
  onSelectContext,
  onSelectMore,
  setPermissionEdit,
} from 'features/manager/managerSlice'
import { useHandleKeyboard, useHandlePermission, useRouter } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectedFileSelector } from 'redux/selectors'
import { notUgly, validFile } from 'utils'

export function useHandleMouse() {
  const dispatch = useDispatch()
  const { router, navigateTo } = useRouter()
  const { selectedFiles } = useSelector(selectedFileSelector)
  const { inSharedFolder, inFreebieFolder, inTrashBin } = router
  const { cmdKeyPress } = useHandleKeyboard()
  const { preventDrag, preventUpload } = useHandlePermission()

  const handlePreview = file => {
    const previewFile = validFile(file) ? file : selectedFiles[0]
    publish(ACTION.SHOW_MODAL, {
      type: MODAL_TYPES.filePreview,
      data: previewFile,
    })
  }

  const handleClick = value => {
    updateUUID(value)
    if (cmdKeyPress) dispatch(onSelectMore(value.key))
    else dispatch(onSelect(value.key))
  }

  const handleContextMenu = value => {
    updateUUID(value)
    dispatch(onSelectContext(value.key))
  }

  const handleDragStart = value => {
    if (preventDrag || preventUpload) return
    updateUUID(value)
    dispatch(onSelectContext(value.key))
  }

  const handleDoubleClick = value => {
    if (inTrashBin) {
      return
    }

    if (inSharedFolder && notUgly(value.canEdit)) {
      dispatch(setPermissionEdit(value.canEdit))
    }

    if (value.type === 'folder') {
      navigateTo(encodeURIComponent(value.path))
    }
    if (value.type === 'file') {
      handlePreview(value)
    }
  }

  const updateUUID = value => {
    if (inSharedFolder && notUgly(value.share_uuid)) {
      localStorage.setItem('share_uuid', value.share_uuid)
    }
    if (inSharedFolder && notUgly(value.updated_at)) {
      localStorage.setItem('updated_at', value.updated_at)
    }
  }

  return {
    handleClick,
    handleContextMenu,
    handleDragStart,
    handleDoubleClick,
  }
}

export default useHandleMouse
