import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { SORT_BY_ORDER, SORT_BY_TYPE } from 'config/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { sortSelector, viewSelector } from 'redux/selectors'
import { setOrder, setType, sortBy } from './sortSlice'

export function SortComponent() {
  const { t } = useTranslation()

  const { type, order } = useSelector(sortSelector)
  const { inGridView, inListView } = useSelector(viewSelector)

  const dispatch = useDispatch()

  const changeOrder = () => {
    if (order === SORT_BY_ORDER.DESC) dispatch(setOrder(SORT_BY_ORDER.ASC))
    else dispatch(setOrder(SORT_BY_ORDER.DESC))
  }

  const [viewType, setViewType] = useState(
    localStorage.getItem('viewType') === null ? 'grid' : localStorage.getItem('viewType'),
  )

  const sortByComponent = () => {
    return (
      <Menu style={{ width: '200px' }} mode="inline">
        <Menu.Item
          key={SORT_BY_TYPE.NAME}
          onClick={() => {
            dispatch(setType(SORT_BY_TYPE.NAME))
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {type === SORT_BY_TYPE.NAME && <CheckOutlined />}
            </span>
            <span>{t('grid_sort_by_name')}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          key={SORT_BY_TYPE.LAST_MODIFIDED}
          onClick={() => {
            dispatch(setType(SORT_BY_TYPE.LAST_MODIFIDED))
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {type === SORT_BY_TYPE.LAST_MODIFIDED && <CheckOutlined />}
            </span>
            <span>{t('grid_sort_by_last_modified')}</span>
          </div>
        </Menu.Item>
      </Menu>
    )
  }

  if (inGridView)
    return (
      <div style={{ textAlign: 'end' }}>
        <Dropdown overlay={sortByComponent()} trigger={['click']}>
          <Button style={{ border: 'none', padding: '3.2px 10px' }}>
            {type === SORT_BY_TYPE.NAME ? t('grid_sort_by_name') : t('grid_sort_by_last_modified')}
          </Button>
        </Dropdown>
        <Button style={{ border: 'none', padding: '3.2px 10px' }} onClick={changeOrder}>
          {order === SORT_BY_ORDER.DESC ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
        </Button>
      </div>
    )
}

export default SortComponent
