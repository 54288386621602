import {
  ACTION,
  DEFAULT_FREEBIE_PATH,
  FREEBIE_FOLDER,
  FREEBIE_FOLDER_URL,
  MY_FOLDER,
  MY_FOLDER_URL,
  SHARED_FOLDER,
  SHARED_FOLDER_URL,
  TRASH_BIN,
  TRASH_BIN_URL,
} from 'config/constant'
import _path from 'path'
import { subscribe, unsubscribe } from 'events/event'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { parseUrl } from 'utils'
import { useDispatch } from 'react-redux'
import { getFolders } from 'features/manager/folder/folderActions'
import { filterSearch } from 'features/view-by/filter/filterSlice'

export function useRouter() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const router = useMemo(() => {
    return parseUrl(location.pathname)
  }, [location])

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/folder')
    }
  }, [])

  const navigateTo = value => {
    const { modeFolder } = router
    let root = ''
    if (modeFolder === MY_FOLDER) root = MY_FOLDER_URL
    if (modeFolder === TRASH_BIN) root = TRASH_BIN_URL
    if (modeFolder === SHARED_FOLDER) root = SHARED_FOLDER_URL
    if (modeFolder === FREEBIE_FOLDER) {
      root = FREEBIE_FOLDER_URL
      // value = value.replace(DEFAULT_FREEBIE_PATH, '')
    }

    const url = _path.join(root, value)
    dispatch(filterSearch(''))
    navigate(url)
  }

  const reload = () => {
    const { currentFolder } = router
    navigateTo(encodeURIComponent(currentFolder).replace(/%2F/g, '/'))
  }

  const reloadAll = (reloadAllPage = true) => {
    if (reloadAllPage) dispatch(getFolders())
    const { currentFolder } = router
    navigateTo(encodeURIComponent(currentFolder).replace(/%2F/g, '/'))
  }

  return {
    router,
    reload,
    reloadAll,
    navigateTo,
    location,
    navigate,
    currentFolder: router.currentFolder,
  }
}

export default useRouter
