import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import DownloadLayout from './download'
import ShareLayout from './share'
import AppLayout from './app'

const Layouts = {
  download: DownloadLayout,
  share: ShareLayout,
  app: AppLayout,
}
export function LayoutApp({ children, path }) {
  // const location = useLocation()
  // const { pathname } = location
  // Layout Rendering
  const getLayout = () => {
    if (path === '/') {
      return 'app'
    }
    if (/^\/download(?=\/|$)/i.test(path) || /^\/d(?=\/|$)/i.test(path)) {
      return 'download'
    }
    if (/^\/s(?=\/|$)/i.test(path)) {
      return 'share'
    }
    return 'app'
  }

  const Container = Layouts[getLayout()]

  const BootstrappedLayout = () => {
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="大容量無料ファイル転送ならACデータ" />
      {BootstrappedLayout()}
      <ToastContainer
        style={{ zIndex: 20000 }}
        enableMultiContainer
        containerId="alert"
        position={toast.POSITION.TOP_RIGHT}
        autoClose={1500}
        draggable={false}
        theme="light"
        limit={1}
        hideProgressBar
      />
      <ToastContainer
        limit={1}
        className="Uploader__toast-container"
        toastClassName="Uploader__toast"
        bodyClassName="Uploader__toast-body"
        autoClose={false}
        enableMultiContainer
        closeOnClick={false}
        containerId="upload"
        position={toast.POSITION.BOTTOM_RIGHT}
        draggable={false}
        theme="light"
        hideProgressBar
      />
      {/* Default container for toast without containerId */}
      <ToastContainer
        style={{ zIndex: 20000 }}
        className="LTR_toast-container"
        toastClassName="LTR_toast"
        progressStyle={{ transformOrigin: 'right' }}
        enableMultiContainer
        position={toast.POSITION.TOP_RIGHT}
        draggable={false}
        theme="light"
        limit={1}
      />
    </Fragment>
  )
}

export default LayoutApp
