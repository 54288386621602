import { Progress } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCookie, humanFileSize } from 'utils'
import { USER_TYPE } from 'config/constant'
import { useTranslation } from 'react-i18next'
import { storageSelector, userSelector } from 'redux/selectors'
import { validatePremiumUser } from 'services/users/user.services'
import { getStorage } from './storageActions'

function UserStorage() {
  const { t } = useTranslation()
  const sessionUserId = getCookie('sessionUserId')
  const uuid = localStorage.getItem('uuid')
  const { userInfo } = useSelector(userSelector)
  const { isPremium } = userInfo
  const { storage } = useSelector(storageSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo.isAuth === false && userInfo.type === USER_TYPE.LOGIN) return
    dispatch(getStorage())
  }, [userInfo])

  let userType = ''
  const gigaStorage = 1024 * 1024 * 1024
  let userStorage = 10
  let userStorageTextPre = 'total_storage_pre_10'
  let userStorageTextSur = 'total_storage_sur_10'
  if (
    uuid === 'f62ff766-1bb3-463f-b671-72f3974c07ACWORKS' &&
    sessionUserId === '5290a4f7-842e-477d-974b-0b59944113e0'
  ) {
    userType = 'premium'
    userStorage = 500
    userStorageTextPre = 'total_storage_pre_500'
    userStorageTextSur = 'total_storage_sur_500'
  }
  // okamoto@acworks.co.jp
  else if (
    uuid === '57877f6f-31b9-4e83-a9cd-036b3d24b09e' &&
    sessionUserId === 'b0589839-dc61-421d-8841-48b84b05b6ce'
  ) {
    userType = 'premium'
    userStorage = 100
    userStorageTextPre = 'total_storage_pre_100'
    userStorageTextSur = 'total_storage_sur_100'
  }
  // acworks.outsourcer1@gmail.com
  else if (
    uuid === '406483f2-511a-4148-aa31-80c8597060a0' &&
    sessionUserId === '40345421-3c77-4686-90cb-42c7f362c4ce'
  ) {
    userType = 'premium'
    userStorage = 200
    userStorageTextPre = 'total_storage_pre_100'
    userStorageTextSur = 'total_storage_sur_200'
  } else if (uuid === '508cbaba-2f3c-40a6-8a84-76bcefa435f3') {
    userType = ''
    userStorage = 50
    userStorageTextPre = 'total_storage_pre_50'
    userStorageTextSur = 'total_storage_sur_50'
  } else if (!isPremium) {
    userType = ''
    userStorage = 10
    userStorageTextPre = 'total_storage_pre_10'
    userStorageTextSur = 'total_storage_sur_10'
  } else if (!isPremium) {
    userType = 'free'
    userStorage = 10
    userStorageTextPre = 'total_storage_pre_10'
    userStorageTextSur = 'total_storage_sur_10'
  } else {
    userType = 'premium'
    userStorage = 30
    userStorageTextPre = 'total_storage_pre_30'
    userStorageTextSur = 'total_storage_sur_30'
  }
  const finalStorage = storage >= gigaStorage * userStorage ? gigaStorage * userStorage : storage

  return (
    <>
      <span className="mt-3 ml-3 title-bold">{t('storage_title')}</span>
      <Progress
        className="ml-3"
        percent={(finalStorage / (gigaStorage * userStorage)) * 100}
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
        status="active"
        format={percent => ''}
      />
      <span className="ml-3" style={{ fontSize: 13, marginBottom: 10 }}>
        {`${humanFileSize(finalStorage, true).replace(/\s/g, '')}${t(userStorageTextPre)}${+(
          (finalStorage / (gigaStorage * userStorage)) *
          100
        ).toFixed(2)}${t(userStorageTextSur)}`}
      </span>
    </>
  )
}

export default UserStorage
