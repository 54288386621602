import config from 'config'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from 'redux/selectors'
import { useTranslation } from 'react-i18next'

const GoogleOneTap = () => {
  const { userInfo } = useSelector(userSelector)
  const { t } = useTranslation()
  const btnRef = useRef()
  const [count, setCount] = useState(0)
  // Key production
  // 54469120861-21v1fa7aqbs06q3tki08rd0pvg6g70p4.apps.googleusercontent.com
  // Key test
  // 193735811185-q2ak1ilfrern60itgst4il0gar4i5spm.apps.googleusercontent.com
  const onGoogleOneTapCredential = useCallback(response => {
    const lang = localStorage.getItem('lang') || 'ja'
    const redirectURL = `${config.sso.serverUrl}/auth/googleonetap`
    window.location.href = `${redirectURL}?idToken=${response.credential}&serviceURL=${config.sso.redirect}&lang=${lang}`
  }, [])

  const initializeGSI = useCallback(() => {
    if (userInfo.isAuth) return

    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id: '54469120861-21v1fa7aqbs06q3tki08rd0pvg6g70p4.apps.googleusercontent.com',
      callback: onGoogleOneTapCredential,
      cancel_on_tap_outside: false,
      context: 'signin',
      itp_support: true,
      ux_mode: 'popup',
    })
  }, [onGoogleOneTapCredential, userInfo.isAuth])

  useEffect(() => {
    if (userInfo.isAuth) return

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = initializeGSI
    script.async = true
    document.querySelector('body').appendChild(script)
  }, [initializeGSI, userInfo, userInfo.isAuth])

  useEffect(() => {
    let interval = null
    if (userInfo.isAuth && btnRef.current) {
      interval = setInterval(() => {
        if (count < 30) {
          btnRef.current.click()
          setCount(count + 1)
        } else {
          clearInterval(interval)
        }
      }, 100)
    }

    return () => clearInterval(interval)
  }, [count, userInfo.isAuth])

  const onCancel = () => {
    // eslint-disable-next-line no-undef
    google.accounts.id.cancel()
  }

  return (
    <div id="g_id_onload">
      <button onClick={onCancel} type="button" ref={btnRef} style={{ display: 'none' }}>
        {t('text_cancel')}
      </button>
      {/* change text */}
    </div>
  )
}

export default GoogleOneTap
