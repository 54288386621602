import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

function TemplateSectionAbove() {
  const { t } = useTranslation()
  return (
    <section id="why-us" className="section-bg" style={{ visibility: 'visible' }}>
      <div className="container">
        <header className="section-header">
          <h1 className="text-center mt-lg-5">{t('section_above_title')}</h1>
        </header>
        <div className="row justify-content-center text-left mt-lg-5 text-center mb-lg-5">
          <div className="col-lg-12">
            <div className="paragraph-lh">
              <p className="pb-2 m-0">{t('section_above_content_1')}</p>
              <p className="pb-2 m-0">{t('section_above_content_2')}</p>
              <p className="pb-2 m-0">{t('section_above_content_3')}</p>
              <p className="pb-2 m-0">{t('section_above_content_4')}</p>
              <p className="pb-2 m-0">{t('section_above_content_5')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TemplateSectionAbove
