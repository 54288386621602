import { Button, Input, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'
import { checkDuplicateFolder } from 'services/upload'
import { getSourceByName, truncate } from 'utils'
import { sharedIcon } from 'utils/icon.util'

function FolderItem({ item }) {
  return (
    <div className="table-row-small">
      <div className="vertically-center mr-2" style={{ width: 30 }}>
        <img className="icon-32" src={getSourceByName('folder')} alt="" />
      </div>
      <div className="vertically-center">
        <Tooltip
          mouseLeaveDelay={0}
          placement="bottomLeft"
          title={item.fileName}
          arrowPointAtCenter
        >
          <span> {truncate(item.fileName, 40)}</span>
          <span>{item.shared ? sharedIcon : <></>}</span>
        </Tooltip>
      </div>
    </div>
  )
}

export default FolderItem
