// export const handleDeleteFiles = async (data, files) => {
//   const { folder } = this.state
//   const { t } = this.props

import { checkDuplicateFile, checkDuplicateFolder, renameFile, renameFolder } from '../upload'

export const handle = () => {}

export const handleRename = async data => {
  let duplicate = false
  if (data.type === 'file') {
    duplicate = await checkDuplicateFile(data)
  } else {
    duplicate = await checkDuplicateFolder(data)
  }
  if (duplicate.status === 'ok') {
    if (data.type === 'file') await renameFile(data)
    if (data.type === 'folder') await renameFolder(data)
    return 'success'
  }
  if (duplicate.status === 'existed') {
    return 'existed'
  }
  return 'error'
}
