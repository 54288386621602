import React, { Component, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import { getInfoUserByEmail, validateSSOId } from 'services/upload'
import { isUgly, openNotificationWithIcon } from 'utils'
import { notification } from 'antd'
import { toast } from 'react-toastify'

const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

function TagInput({ onChange, props }) {
  const [input, setInput] = useState('')
  const [tags, setTags] = useState([])
  const { t } = useTranslation()

  const handleChange = (value, action) => {
    setTags(value)
    onChange(value)
  }

  const handleInputChange = inputValue => {
    const receivers = inputValue.split(/[\s,]+/)
    if (receivers.length > 1) {
      receivers.forEach(receiver => onSubmitValue(receiver))
    } else setInput(inputValue)
  }

  const onSubmitValue = async inputValue => {
    if (!inputValue || !emailRegexp.test(inputValue)) {
      setInput(inputValue)
      return
    }

    let list = tags || []
    const option = {
      label: inputValue,
      value: inputValue,
    }
    let receivers = JSON.parse(localStorage.getItem('receivers')) || []
    receivers = [inputValue].concat(receivers)
    receivers = receivers.filter((item, index) => receivers.indexOf(item) === index)
    localStorage.setItem('receivers', JSON.stringify(receivers))

    list.push(option)
    list = list.filter(
      (item, index, self) => self.findIndex(tag => tag.label === item.label) === index,
    )
    setInput('')
    setTags(list)
    onChange(list)
  }

  const getOptions = () => {
    let receivers = JSON.parse(localStorage.getItem('receivers')) || []
    receivers = receivers.map(receiver => ({ value: receiver, label: receiver }))
  }

  // const handleBlur = () => {
  // onSubmitValue(value)
  // }

  const handleKeyDown = event => {
    if (!input) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        onSubmitValue(input)
        event.preventDefault()
        break
      default:
        break
    }
  }

  const customStyles = {
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: '#fbfbfb',
      }
    },
    multiValueLabel: styles => ({
      ...styles,
      color: '#253858',
      fontSize: 'inherit',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#253858',
      ':hover': {
        backgroundColor: '#253858',
        color: 'white',
      },
    }),
  }
  const receivers = getOptions()
  return (
    <CreatableSelect
      {...props}
      inputValue={input}
      isClearable
      isMulti
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={t('sharing_form_receivers_placeholder')}
      value={tags}
      // onBlur={handleBlur}
      styles={customStyles}
      noOptionsMessage={() => null}
      formatCreateLabel={userInput => `${userInput}`}
      options={receivers}
      createOptionPosition="first"
    />
  )
}
export default withTranslation()(TagInput)
