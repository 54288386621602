import { ACTION, MODAL_TYPES } from 'config/constant'
import { subscribe, unsubscribe } from 'events/event'
import React, { useEffect, useState } from 'react'
import Modal from './components/ModalTypes'

function ModalContainer() {
  const [type, setType] = useState(null)
  const [data, setData] = useState(null)
  const [expiry, setExpiry] = useState('3')
  useEffect(() => {
    subscribe(ACTION.SHOW_MODAL, result => {
      setType(result.detail.type)
      if (result.detail.data) setData(result.detail?.data)
      if (result.detail.expiry) setExpiry(result.detail.expiry)
    })
    return () => {
      unsubscribe(ACTION.SHOW_MODAL)
    }
  }, [])

  const onCancel = () => {
    setType(null)
  }

  if (type === null) {
    return null
  }

  const ModalType = Modal[type]
  return <ModalType onCancel={onCancel} data={data} expiry={expiry} />
}

export default ModalContainer
