import React from 'react'
import Img from 'react-image'
import { getSourceByName } from 'utils'
import { getUUID } from 'services/upload'
import { VIEW } from 'config/constant'
import { config } from 'config'

export function CoverImage({ fileItem, viewSize }) {
  const sizeClass = viewSize === VIEW.SIZE.SMALL ? ' card-image-small' : ' card-image-large'
  const sizeAltClass =
    viewSize === VIEW.SIZE.SMALL ? ' card-image-alt-small' : ' card-image-alt-large'

  let thumbnailPath = `${fileItem.share_uuid ? fileItem.share_uuid : getUUID()}/${
    fileItem.path === '/' ? '' : fileItem.path
  }${fileItem.name}`

  if (fileItem.origin) {
    thumbnailPath = fileItem.origin
  }

  if (fileItem.type === 'file')
    return (
      <Img
        src={[`${config.s3.thumbnail}/${encodeURIComponent(thumbnailPath).replace(/%2F/g, '/')}`]}
        className={sizeClass}
        unloader={
          <Img
            src={[
              `https://s3-ap-northeast-1.amazonaws.com/thumbnail.ac-data.info/${encodeURIComponent(
                thumbnailPath,
              ).replace(/%2F/g, '/')}`,
            ]}
            className={sizeClass}
            unloader={
              <img src={getSourceByName(fileItem.name)} alt="cover" className={sizeAltClass} />
            }
          />
        }
      />
    )
  if (fileItem.type === 'folder')
    return <img className={sizeAltClass} src={getSourceByName('folder')} alt="card_image" />
}

export default CoverImage
