export const setCookie = (name, value, timestime = 1668022212, path = '/') => {
  const expires = new Date(timestime * 1000).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`
}
export const setCookies = ({ name, value, timestime, path = '/' }) => {
  let expires = 0
  if (timestime === null || timestime === undefined) {
    expires = new Date(Date.now() + 1000 * 60).toUTCString()
  }
  expires = new Date(timestime * 1000).toUTCString()
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`
}

export const getCookie = name => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}

export const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path)
}
