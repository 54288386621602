import { truncateString } from './common.util'
import { isUgly } from './path.util'

export const genDisplayName = ({ userEmail = 'email', userName = 'user' }) => {
  let displayUser = ''
  if (userEmail !== undefined && userEmail != null) {
    if (userEmail.length > 15) displayUser = userEmail[0].concat('****************').concat(' ...')
    else displayUser = userEmail[0].concat('****************').concat(userEmail[0])
  }
  if (userName !== undefined && userName != null && userName !== '')
    if (userName.length > 20) {
      displayUser = userName.slice(0, 20).concat(' ...')
    } else {
      displayUser = userName
    }
  return displayUser
}

export const getNickName = ({ userEmail, userName }) => {
  let displayUser = ''
  if (userEmail !== undefined && userEmail != null) {
    displayUser = truncateString(userEmail)
    if (isUgly(userName)) return displayUser
  }
  if (userName !== undefined && userName != null && userName !== '')
    displayUser = truncateString(userName)

  return displayUser
}

export const validateStorage = isPremium => {
  const uuid = localStorage.getItem('uuid')
  let userStorage = 10
  let overStorageAlert = 'upload_over_storage'
  if (uuid === 'f62ff766-1bb3-463f-b671-72f3974c07ACWORKS') {
    userStorage = 500
    overStorageAlert = 'upload_over_storage_premium'
  } else if (uuid === '57877f6f-31b9-4e83-a9cd-036b3d24b09e') {
    userStorage = 100
    overStorageAlert = 'upload_over_storage_premium'
  } else if (uuid === '406483f2-511a-4148-aa31-80c8597060a0') {
    userStorage = 200
    overStorageAlert = 'upload_over_storage_premium'
  } else if (!isPremium) {
    userStorage = 10
    overStorageAlert = 'upload_over_storage'
  } else if (!isPremium) {
    userStorage = 10
    overStorageAlert = 'upload_over_storage'
  } else if (uuid === '508cbaba-2f3c-40a6-8a84-76bcefa435f3') {
    userStorage = 50
    overStorageAlert = 'upload_over_storage_premium'
  } else {
    userStorage = 30
    overStorageAlert = 'upload_over_storage_premium'
  }
  return { userStorage, overStorageAlert }
}

export default {
  genDisplayName,
}
