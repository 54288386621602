import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Card, List, Tooltip } from 'antd'
import { truncate, getSourceByName } from 'utils/common.util'
import { setIsDrag } from 'features/manager/managerSlice'
import { useDispatch, useSelector } from 'react-redux'

import { TypeDrag, VIEW } from 'config/constant'
import { useHandleAction } from 'hooks'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { managerSelector, selectedFileSelector } from 'redux/selectors'
import CoverImage from 'components/CoverImage'
import EditableName from 'components/EditableName'

const { Meta } = Card

// class SharedFolderCard extends Component {
//   render() {
//     const { id, key, loadSharedFolders, folderItem } = this.props

//     return (
//       <div>
//         <Card
//           bordered={false}
//           style={{
//             cursor: 'pointer',
//             height: 170,
//             width: 150,
//             padding: '5px 0',
//             marginBottom: '10px',
//             zIndex: 999,
//           }}
//           bodyStyle={{ padding: '5px !important' }}
//           onDoubleClick={() => loadSharedFolders(false, folderItem.path)}
//           onClick={e => {
//             if (e.currentTarget.classList.contains('card-selected')) {
//               e.currentTarget.classList.remove('card-selected')
//             } else {
//               e.currentTarget.classList.add('card-selected')
//             }
//           }}
//           cover={
//             <img
//               src={getSourceByName('folder')}
//               alt=""
//               style={{ width: 'fit-content', marginTop: 59, userSelect: 'none' }}
//             />
//           }
//         >
//           <Tooltip placement="bottomLeft" title={folderItem.name} arrowPointAtCenter>
//             <Meta description={truncate(folderItem.name, 15)} style={{ width: 150 }} />
//           </Tooltip>
//         </Card>
//       </div>
//     )
//   }
// }

function SharedFolderCard({ loadSharedFolders, folderItem }) {
  const viewSize = 'small'
  const sizeClass = ' card-small'

  const className = `file-card ${sizeClass}`

  return (
    <List.Item
      style={{ display: 'flex', flexDirection: 'column' }}
      className="item-ref"
      data-row-key={folderItem.key}
    >
      <div
        aria-hidden
        className={className}
        onDoubleClick={() => loadSharedFolders(false, folderItem.path)}
      >
        <CoverImage viewSize={viewSize} fileItem={folderItem} />
      </div>
      <EditableName fileItem={folderItem} />
    </List.Item>
  )
}
export default SharedFolderCard
