// import { createStore } from 'redux';
// import rootReducer from './reducer';
// import { composeWithDevTools } from 'redux-devtools-extension';

// const composedEnhancers = composeWithDevTools();

// const store = createStore(rootReducer, composedEnhancers);

// export default store;
// import thunk from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import userSlice from 'features/user/userSlice'
import storageSlice from 'features/storage/storageSlice'
import fileSlice from 'features/manager/file/fileSlice'
import folderSlice from 'features/manager/folder/folderSlice'
import viewTypeSlice from 'features/view-by/type/viewTypeSlice'
import filterSlice from 'features/view-by/filter/filterSlice'
import sortSlice from 'features/view-by/sort/sortSlice'
import routerSlice from 'features/router/routerSlice'
import managerSlice from 'features/manager/managerSlice'
import uploaderSlice from 'features/uploader/uploaderSlice'
import loadUserMiddleware from './userMiddleware'

console.log('check code review ne')

const store = configureStore({
  // middleware: [loadUserMiddleware],
  reducer: {
    user: userSlice,
    storage: storageSlice,
    file: fileSlice,
    folder: folderSlice,
    viewType: viewTypeSlice,
    router: routerSlice,
    filter: filterSlice,
    manager: managerSlice,
    uploader: uploaderSlice,
    sort: sortSlice,
    // filters: filtersSlice.reducer,
    // todoList: todosSlice.reducer,
  },
})

export default store
