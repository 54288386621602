import React, { useState } from 'react'
import { Button } from 'antd'
import { MinusOutlined, PlusOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import Img from 'react-image'

export function Image({ URL }) {
  const [scale, setScale] = useState(1)
  const initScaleImage = 0.6
  return (
    <div className="bodyOfImage">
      <TransformWrapper
        initialScale={initScaleImage}
        // initialPositionX={0}
        // initialPositionY={0}
        wheel={{ step: 0.05 }}
        minScale={initScaleImage}
        maxScale={2}
        onInit={(ref, event) => {
          setScale(Number(ref.state.scale))
        }}
        onClick={(ref, event) => {
          setScale(Number(ref.state.scale))
        }}
        onZoom={(ref, event) => {
          setScale(Number(ref.state.scale))
        }}
        // centerZoomedOut
        centerOnInit
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div
              style={{
                textAlign: 'center',
                width: '147px',
                position: 'absolute',
                borderRadius: '3px',
                bottom: '30px',
                left: 'calc(50% - 73px)',
                height: '36px',
                zIndex: '1000',
                backgroundColor: 'rgba(0,0,0,0.85)',
              }}
            >
              {scale > initScaleImage ? (
                <Button
                  ghost
                  onClick={e => {
                    e.preventDefault()
                    zoomOut()
                  }}
                  style={{
                    border: 0,
                    fontSize: 19,
                    verticalAlign: 'middle',
                    height: 'fit-content',
                  }}
                >
                  <MinusOutlined
                    style={{
                      fontSize: 19,
                    }}
                  />
                </Button>
              ) : (
                <Button
                  ghost
                  onClick={e => {
                    e.preventDefault()
                    zoomOut()
                  }}
                  style={{
                    border: 0,
                    fontSize: 19,
                    verticalAlign: 'middle',
                    height: 'fit-content',
                  }}
                >
                  <MinusOutlined
                    style={{
                      fontSize: 19,
                      color: 'grey',
                    }}
                  />
                </Button>
              )}
              {scale > initScaleImage ? (
                <Button
                  ghost
                  onClick={e => {
                    e.preventDefault()
                    resetTransform()
                  }}
                  style={{
                    border: 0,
                    fontSize: 19,
                    verticalAlign: 'middle',
                    height: 'fit-content',
                  }}
                >
                  <ZoomOutOutlined
                    style={{
                      fontSize: 19,
                    }}
                  />
                </Button>
              ) : (
                <Button
                  ghost
                  style={{
                    border: 0,
                    fontSize: 19,
                    verticalAlign: 'middle',
                    height: 'fit-content',
                  }}
                >
                  <ZoomOutOutlined
                    style={{
                      color: 'grey',
                      fontSize: 19,
                    }}
                  />
                </Button>
              )}
              <Button
                ghost
                onClick={() => zoomIn()}
                style={{
                  border: 0,
                  fontSize: 19,
                  verticalAlign: 'middle',
                  height: 'fit-content',
                }}
              >
                <PlusOutlined
                  style={{
                    fontSize: 19,
                  }}
                />
              </Button>
            </div>
            <TransformComponent>
              {/* contentStyle={{ height: `100vh` }}
              // wrapperStyle={{ width: '100%', height: '100%' }}
            > */}
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                }}
              >
                <Img
                  id="bodyPreview"
                  src={URL}
                  alt=""
                  style={{
                    height: '100%',
                  }}
                />
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  )
}

export default Image
