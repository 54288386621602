import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import _path from 'path'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Modal } from 'antd'
import { useRouter } from 'hooks'
import { toast } from 'react-toastify'
import { handleRename } from 'services/files/rename.service'
import { badPath } from 'utils'

const RenameForm = ({ onCancel, data }) => {
  return (
    <div>
      {/* <>
        <Button
          style={{ width: 32, padding: 0, marginRight: 5 }}
          onClick={() => {
            setNewFolderName('')
            onCancel()
          }}
        >
          <i style={{ fontSize: '14px' }} className="fa fa-close" />
        </Button>
        <Button
          type="primary"
          style={{ width: 32, padding: 0 }}
          onClick={handleClick}
          disabled={!newFolderName}
        >
          <i style={{ fontSize: '14px' }} className="fa fa-check" />
        </Button>
      </> */}
    </div>
  )
}

function RenameModal({ onCancel, data }) {
  const [newFolderName, setNewFolderName] = useState(data?.name)
  const { t } = useTranslation()
  const { reloadAll, reload } = useRouter()

  const handleClick = async () => {
    if (!newFolderName || data?.name === newFolderName) {
      return
    }
    if (badPath(newFolderName)) {
      toast.error(t('rename_folder_invalid_name'), {
        containerId: 'alert',
      })
      return
    }
    onCancel()
    await toast.promise(
      onSubmitRename(),
      {
        pending: t('notification:rename_loading'),
        success: t('notification:rename_success'),
        // error: t('create_folder_fail'),
        error: `${t('duplicate_alert_content_1')}${newFolderName}${t('duplicate_alert_content_2')}`,
      },
      {
        containerId: 'alert',
      },
    )
    if (data.type === 'folder') reloadAll()
    if (data.type === 'file') reload()
  }

  const onSubmitRename = async () => {
    const params = {
      newName: newFolderName,
      currentName: data.name,
      path: data.path,
      type: data.type,
    }
    const result = await handleRename(params)
    if (result === 'success') {
      return Promise.resolve()
      // toast(`${t('duplicate_alert_content_1')}${newFolderName}${t('duplicate_alert_content_2')}`, {
      //   containerId: 'alert',
      // })
    }
    return Promise.reject()
  }

  const handleFolderNameChange = e => {
    setNewFolderName(e.target.value)
  }
  return (
    <Modal
      title={t('modal:conform_rename_title')}
      centered
      open
      onOk={handleClick}
      onCancel={onCancel}
      okButtonProps={{ disabled: !newFolderName }}
      okText={t('modal:conform_rename_button')}
      cancelText={t('modal:cancel_button')}
    >
      <Input
        placeholder={t('add_folder')}
        value={newFolderName}
        // onPressEnter={handleClick}
        onChange={handleFolderNameChange}
        required
        allowClear
        autoFocus
        style={{ width: '420px', height: 32 }}
      />
    </Modal>
  )
}

export default RenameModal
