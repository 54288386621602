import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { MinusOutlined, PlusOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Document, Outline, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
export function Ai({ URL }) {
  const [scale, setScale] = useState(1)
  function changeScale(offset) {
    setScale(prevScaleNumber => prevScaleNumber + offset)
  }
  function zoomIn() {
    changeScale(0.1)
  }

  function zoomOut() {
    changeScale(-0.1)
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <React.Fragment>
        <div
          style={{
            textAlign: 'center',
            width: '170px',
            position: 'absolute',
            borderRadius: '3px',
            bottom: '30px',
            left: 'calc(50% - 73px)',
            height: '36px',
            zIndex: '1000',
            backgroundColor: 'rgba(0,0,0,0.85)',
          }}
        >
          <Button
            ghost
            onClick={() => zoomOut()}
            style={{
              border: 0,
              fontSize: 19,
              verticalAlign: 'middle',
              height: 'fit-content',
            }}
          >
            <MinusOutlined
              style={{
                fontSize: 19,
              }}
            />
          </Button>

          <Button
            ghost
            style={{
              border: 0,
              fontSize: 14,
              verticalAlign: 'middle',
              height: 'fit-content',
            }}
          >
            {Math.round(scale * 100)}%
          </Button>
          <Button
            ghost
            onClick={() => zoomIn()}
            style={{
              border: 0,
              fontSize: 19,
              verticalAlign: 'middle',
              height: 'fit-content',
            }}
          >
            <PlusOutlined
              style={{
                fontSize: 19,
              }}
            />
          </Button>
        </div>
        <Document file={URL}>
          {/* <Page pageNumber={3} /> */}
          {/* {renderPage()} */}
          <Page scale={scale} pageNumber={1} />
          {/* <Page pageNumber={pageNumber} scale={scaleNumber} /> */}
        </Document>
      </React.Fragment>
    </div>
  )
}

export default Ai
