import { createSlice } from '@reduxjs/toolkit'
import { getFilesThunk, getFreebiesThunk, getSharedFolder, getTrashBin } from './fileActions'

const initialState = {
  loading: false,
  error: null,
  success: false,
  files: [],
  folders: [],
  empty: false,
}

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    // logout: state => {
    //   // localStorage.removeItem('userToken') // delete token from storage
    //   state.loading = false
    //   state.userInfo = null
    //   state.userToken = null
    //   state.error = null
    // },
  },
  extraReducers: {
    // get files
    [getFilesThunk.pending]: state => {
      state.loading = true
      state.error = null
    },
    [getFilesThunk.fulfilled]: (state, { payload }) => {
      const { files, folders } = payload
      state.empty = files.length === 0
      state.loading = false
      state.success = true
      state.files = files
      state.folders = folders
    },
    [getFilesThunk.rejected]: (state, { payload }) => {
      state.loading = false
      state.files = []
      state.folders = []
      state.empty = true
      state.error = payload
    },

    [getFreebiesThunk.pending]: state => {
      state.loading = true
      state.error = null
    },
    [getFreebiesThunk.fulfilled]: (state, { payload }) => {
      const { files, folders } = payload
      state.empty = files.length === 0
      state.loading = false
      state.success = true
      state.files = files
      state.folders = folders
    },
    [getFreebiesThunk.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    [getSharedFolder.pending]: state => {
      state.loading = true
      state.error = null
      state.files = []
      state.folders = []
    },
    [getSharedFolder.fulfilled]: (state, { payload }) => {
      const { files, folders } = payload
      state.loading = false
      state.empty = files.length === 0
      state.files = files
      state.folders = folders
    },
    [getSharedFolder.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    [getTrashBin.pending]: state => {
      state.loading = true
      state.error = null
      state.files = []
      state.folders = []
    },
    [getTrashBin.fulfilled]: (state, { payload }) => {
      const { files, folders } = payload
      state.loading = false
      state.empty = files.length === 0
      state.files = files
      state.folders = folders
    },
    [getTrashBin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = fileSlice
// export const { logout } = actions
export default reducer
