/* eslint-disable dot-notation */
import React, { useState, useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Radio, Switch } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { shareFiles, zipFiles } from 'services/upload'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DEFAULT_FOLDER_PATH } from 'config/constant'
import { getCookie } from 'utils'
import TagInput from './TagInput'

const { TextArea } = Input

function ShareFormDropzone({ onCancel, selectedFiles, expiry }) {
  const { t } = useTranslation()

  const shareSchema = yup.object().shape({
    subject: yup.string(),
    password: yup.string(),
    sender: yup
      .string()
      .email(t('unable_to_check_email'))
      .required(t('required_sender_email')),
    receivers: yup
      .array(
        yup
          .string()
          .email(t('unable_to_check_email'))
          .required(t('required_recipient_email')),
      )
      .required(t('required_recipient_email')),
    notification: yup.bool().required(),
    message: yup.string(),
  })

  const [formLayout, setFormLayout] = useState('horizontal')
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [subject, setSubject] = useState('')
  const [path, setPath] = useState(localStorage.getItem('current_folder') || DEFAULT_FOLDER_PATH)
  const [sender, setSender] = useState('')
  const [receivers, setReceivers] = useState([])
  const [notification, setNotification] = useState(true)
  const [loading, setLoading] = useState(false)
  const [validation, setValidation] = useState(shareSchema.default())

  useEffect(() => {
    setSender(getCookie('email_usr'))
  }, [])

  // resetForm = () => {
  //   this.setState({
  //     subject: '',
  //     message: '',
  //     password: '',
  //     sender: '',
  //     receivers: [],
  //     notification: true,
  //     path: localStorage.getItem('current_folder') || DEFAULT_FOLDER_PATH,
  //   })
  // }

  const handleFormLayoutChange = e => {
    setFormLayout(e.target.value)
  }

  const onChangeSwitch = checked => {
    setNotification(checked)
  }

  const onChangeNote = ({ target: { value } }) => {
    setMessage(value)
  }

  const onChangePassword = ({ target: { value } }) => {
    setPassword(value)
  }

  const onChangeReceivers = list => {
    setReceivers(list ? list.map(item => item.value) : [])
  }

  const onChangeSender = ({ target: { value } }) => {
    setSender(value)
  }

  const onChangeSubject = ({ target: { value } }) => {
    setSubject(value)
  }

  const validateSchema = () => {
    const ShareSchema = yup.object().shape({
      subject: yup.string(),
      password: yup.string(),
      sender: yup
        .string()
        .email(t('sharing_form_verify_fail'))
        .required(t('sharing_form_sender_required')),
      receivers: yup
        .array(
          yup
            .string()
            .email(t('sharing_form_verify_fail'))
            .required(t('sharing_form_receivers_required')),
        )
        .required(t('sharing_form_receivers_required')),
      notification: yup.bool().required(),
      message: yup.string(),
    })

    return ShareSchema.validate(
      {
        subject,
        password,
        sender,
        receivers,
        message,
        notification,
        path,
      },
      { abortEarly: false },
    )
      .then(res => res)
      .catch(e => {
        const { inner } = e
        let result = validation
        Object.keys(result).map((item, key) => {
          const error = inner.find(i => i.path === item)
          if (error) {
            result = {
              ...result,
              [item]: error.message,
            }
          }
          return result
        })
        setValidation(result)
      })
  }

  const handleCancel = () => {
    onCancel()
    // this.resetForm()
  }

  const handleSubmitShare = async () => {
    console.log(selectedFiles)
    const validate = await validateSchema(t)
    if (validate) {
      setLoading(true)

      let params = null
      // const filenames = selectedFiles.map(item => item.fileName)
      if (selectedFiles && selectedFiles.length === 1) {
        params = {
          // password,
          subject,
          sender,
          filesShare: [{ name: selectedFiles[0], password }],
          zipped: '',
          receivers,
          message,
          notification,
          path: '/',
          expiry: expiry || '3',
        }
      } else {
        const date = new Date().toJSON()
        const zipName = `ACデータ-ダウンロード-${date}`
        const data = {
          zipName,
          zipExt: '.zip',
          path: DEFAULT_FOLDER_PATH,
          filenames: selectedFiles,
        }
        const response = await zipFiles(data)
        const dataZip = response
        params = {
          subject,
          sender,
          // filenames: selectedFiles.map(item => item.fileName),
          // zipped: zippedFile || '',
          receivers,
          message,
          notification,
          filesShare: [{ name: dataZip.zipFileName, password }],
          zipped: { name: dataZip.zipFileName, password, url: '' },
          path: '_/data/archives/',
          expiry: expiry || '3',
        }
      }
      if (params === null) {
        toast.error(t('share_files_fail'), { containerId: 'alert' })
        return
      }
      shareFiles(params)
        .then(res => {
          // resetForm()
          onCancel()
          toast.success(t('share_files_success'), { containerId: 'alert' })
        })
        .catch(e => {
          toast.error(t('share_files_fail'), { containerId: 'alert' })
        })
        .finally(() => {
          setLoading(false)
          setValidation(shareSchema.default())
        })

      // setLoading(false)
    }
  }

  const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20, offset: 1 },
    labelAlign: 'right',
    style: {
      marginBottom: 4,
      width: '620px',
    },
  }
  return (
    <div>
      <Form layout={formLayout}>
        {/* <Form.Item label="Folder Name" {...formItemLayout}>
            <Input placeholder="Folder Name" />
          </Form.Item> */}
        <Form.Item
          label={t('sharing_form_subject')}
          {...formItemLayout}
          validateStatus={validation['subject'] ? 'error' : null}
          help={validation['subject']}
        >
          <Input
            placeholder={t('sharing_form_subject_placeholder')}
            onChange={onChangeSubject}
            value={subject}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              {t('sharing_form_sender')}
              <span style={{ color: 'red', fontSize: 16 }}>*</span>
            </span>
          }
          {...formItemLayout}
          validateStatus={validation['sender'] ? 'error' : null}
          help={validation['sender']}
        >
          <Input
            placeholder={t('sharing_form_sender_placeholder')}
            onChange={onChangeSender}
            value={sender}
          />
        </Form.Item>

        <>
          <Form.Item
            label={
              <span>
                {t('sharing_form_receivers')}
                <span style={{ color: 'red', fontSize: 16 }}>*</span>
              </span>
            }
            {...formItemLayout}
            validateStatus={validation['receivers'] ? 'error' : null}
            help={validation['receivers']}
          >
            <div className="tag-input">
              <TagInput
                placeholder="d.co"
                classNamePrefix="ac-data"
                className={validation['receivers'] ? 'is-invalid' : null}
                onChange={onChangeReceivers}
                value={receivers}
              />
            </div>
          </Form.Item>
          {/* {isFoldersSharing && ( */}
          <Form.Item
            label={t('sharing_form_password')}
            {...formItemLayout}
            validateStatus={validation['password'] ? 'error' : null}
            help={validation['password']}
          >
            <Input.Password
              placeholder={t('sharing_form_password_placeholder')}
              onChange={onChangePassword}
              value={password}
            />
          </Form.Item>
          {/* )} */}
          <Form.Item label={t('sharing_form_notification')} {...formItemLayout}>
            <Switch defaultChecked onChange={onChangeSwitch} />
          </Form.Item>
          <Form.Item
            label={t('sharing_form_message')}
            validateStatus={validation['message'] ? 'error' : null}
            help={validation['message']}
            {...formItemLayout}
          >
            <TextArea
              value={message}
              onChange={onChangeNote}
              placeholder={t('sharing_form_message_placeholder')}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
            {/* {t('sharing_form_note')} */}
          </Form.Item>
        </>
        <Form.Item
          className="justify-content-center d-flex"
          style={{ textAlign: 'center', marginBottom: 6, marginTop: 6 }}
        >
          <Button type="default" className="mr-3" onClick={handleCancel}>
            {t('cancel_button')}
          </Button>
          <Button
            type="primary"
            // onClick={!isFoldersSharing ? this.handleSubmitShare : this.handleFoldersSharing}
            onClick={handleSubmitShare}
            loading={
              loading ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : false
            }
          >
            {t('share_button')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default withTranslation()(ShareFormDropzone)
