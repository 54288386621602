import { Avatar, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import Img from 'react-image'
import Ripples from 'react-ripples'
import { genDisplayName, getNickName, isUgly } from 'utils'
import _path from 'path'
import config from 'config'
import { DEFAULT_ICON_PROFILE, DEFAULT_ICON_PROFILE_URL } from 'config/constant'
import { getInfoUserBySessionUserId, getSSOIdByUUID } from 'services/upload'
import { LoadingOutlined } from '@ant-design/icons'

export const getAvatar = avatar => {
  if (isUgly(avatar)) return DEFAULT_ICON_PROFILE_URL
  if (avatar.includes('http')) {
    return avatar
  }
  const path = isUgly(avatar) ? DEFAULT_ICON_PROFILE : avatar
  return _path.join(config.sso.resourceUrl, path)
}
export function SharedAvatar({ shareUUID }) {
  const [shareUser, setShareUser] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getSSOIdByUUID(shareUUID).then(value => {
      if (value)
        getInfoUserBySessionUserId(value.ssoId).then(user => {
          if (user) setShareUser(user)
          setLoading(false)
        })
    })
  }, [])

  if (loading) return <LoadingOutlined style={{ fontSize: 12 }} spin />
  // if (isUgly(shareUser)) return <span>-</span>
  const displayName = getNickName({
    userEmail: shareUser?.email,
    userName: shareUser?.nick_name,
  })
  const avatarPath = shareUser?.avatar?.path
  if (isUgly(avatarPath) && isUgly(displayName)) return <span>-</span>
  return (
    <div
      className="share-avatar"
      style={{
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ height: 25, width: 25, marginRight: 5 }}>
        <Img
          src={getAvatar(avatarPath)}
          style={{
            borderRadius: '50%',
            height: 25,
            width: 25,
          }}
          unloader={
            <Avatar
              shape="circle"
              size={50}
              style={{
                borderRadius: '50%',
                height: 25,
                width: 25,
              }}
              src={DEFAULT_ICON_PROFILE_URL}
            />
          }
        />
      </div>
      <span style={{ fontSize: 12 }}>{displayName}</span>
    </div>
  )
}

export default { SharedAvatar }
