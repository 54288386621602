const { MB_BYTE, GB_BYTE } = require('config/constant')

export const calChunk = ({ fileSize }) => {
  let FILE_CHUNK_SIZE = 5 * MB_BYTE
  switch (true) {
    case fileSize >= 10 * GB_BYTE:
      FILE_CHUNK_SIZE = 10 * GB_BYTE
      break
    default:
      FILE_CHUNK_SIZE = 5 * MB_BYTE
      break
  }
  const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1
  return { FILE_CHUNK_SIZE, NUM_CHUNKS }
}

export const updateChunks = ({ chunks, allFilePercent }) => {
  const { file, NUM_CHUNKS } = chunks
  const newState = allFilePercent.map(el => {
    if (el.name === file.name && el.percent < 100) {
      return Object.assign({}, el, {
        chunks: [...Array(NUM_CHUNKS)].map((_, i) => 0),
      })
    }
    return el
  })
  return newState
}

export const calPercentUpload = ({ allFilePercent, status }) => {
  const { name, partNumber, totalPart, percent } = status
  const newState = allFilePercent.map(el => {
    if (el.name === name && el.percent < 100) {
      const newChunks = [...el.chunks]
      newChunks[partNumber - 1] = percent * (1 / totalPart)
      const newPercent = Math.round(newChunks.reduce((a, b) => a + b, 0))
      return Object.assign({}, el, { percent: newPercent, chunks: newChunks })
    }
    return el
  })
  return newState
}

export default calChunk
