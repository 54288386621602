import { Tooltip } from 'antd'
import React, { useState } from 'react'
import Img from 'react-image'
import { getUUID } from 'services/upload'
import { getSourceByName, truncate } from 'utils'
import { sharedIcon } from 'utils/icon.util'
import { config } from 'config'

function FileItemLarge({ item }) {
  const uuid = item.share_uuid ? item.share_uuid : getUUID()
  let thumbnailPath = `${uuid}/${item.path === '/' ? '' : item.path}${item.name}`
  if (item.origin) {
    thumbnailPath = item.origin
  }
  return (
    <div className="table-row-large">
      <div className="vertically-center mr-2" style={{ width: 50 }}>
        <Img
          src={[`${config.s3.thumbnail}/${encodeURIComponent(thumbnailPath).replace(/%2F/g, '/')}`]}
          className="icon-32"
          unloader={
            <Img
              src={[
                `https://s3-ap-northeast-1.amazonaws.com/thumbnail.ac-data.info/${encodeURIComponent(
                  thumbnailPath,
                ).replace(/%2F/g, '/')}`,
              ]}
              className="icon-32"
              unloader={<img src={getSourceByName(item.name)} alt="" className="icon-32" />}
            />
          }
        />
      </div>
      <div className="vertically-center">
        <Tooltip mouseLeaveDelay={0} placement="bottomLeft" title={item.name} arrowPointAtCenter>
          <span>{truncate(item.name, 40)}</span>
          {item.shared ? sharedIcon : <></>}
        </Tooltip>
        <span style={{ fontSize: '12px' }}>
          <i>{item.size}</i>
        </span>
      </div>
    </div>
  )
}

export default FileItemLarge
