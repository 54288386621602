import React from 'react'

export const sharedIcon = (
  <span style={{ marginLeft: 4, verticalAlign: 'text-top' }}>
    <svg width="18" height="18" focusable="false" viewBox="0 0 18 18" fill="gray">
      <path d="M5,7 C6.11,7 7,6.1 7,5 C7,3.9 6.11,3 5,3 C3.9,3 3,3.9 3,5 C3,6.1 3.9,7 5,7 L5,7 Z M11,7 C12.11,7 13,6.1 13,5 C13,3.9 12.11,3 11,3 C9.89,3 9,3.9 9,5 C9,6.1 9.9,7 11,7 L11,7 Z M5,8.2 C3.33,8.2 0,9.03 0,10.7 L0,12 L10,12 L10,10.7 C10,9.03 6.67,8.2 5,8.2 L5,8.2 Z M11,8.2 C10.75,8.2 10.46,8.22 10.16,8.26 C10.95,8.86 11.5,9.66 11.5,10.7 L11.5,12 L16,12 L16,10.7 C16,9.03 12.67,8.2 11,8.2 L11,8.2 Z" />
    </svg>
  </span>
)

export const sharedIcon2 = (
  <span style={{ marginLeft: 4, verticalAlign: 'text-top' }}>
    <svg width="18" height="18" focusable="false" viewBox="0 0 18 18" fill="gray">
      <path d="M5,7 C6.11,7 7,6.1 7,5 C7,3.9 6.11,3 5,3 C3.9,3 3,3.9 3,5 C3,6.1 3.9,7 5,7 L5,7 Z M11,7 C12.11,7 13,6.1 13,5 C13,3.9 12.11,3 11,3 C9.89,3 9,3.9 9,5 C9,6.1 9.9,7 11,7 L11,7 Z M5,8.2 C3.33,8.2 0,9.03 0,10.7 L0,12 L10,12 L10,10.7 C10,9.03 6.67,8.2 5,8.2 L5,8.2 Z M11,8.2 C10.75,8.2 10.46,8.22 10.16,8.26 C10.95,8.86 11.5,9.66 11.5,10.7 L11.5,12 L16,12 L16,10.7 C16,9.03 12.67,8.2 11,8.2 L11,8.2 Z" />
    </svg>
  </span>
)

export const CloseIcon = ({ color = '#108ee9' }) => (
  <svg viewBox="0 0 24 24" width="15px" height="15px" fill={color}>
    <path
      d="M 60.875 45.791667 L 128 112.916667 L 195.125 45.791667 C 197.041667 43.791667 199.666667 42.625 202.458333 42.541667 C 206.875 42.416667 210.875 45 212.583333 49.083333 C 214.291667 53.125 213.375 57.791667 210.208333 60.875 L 143.083333 128 L 210.208333 195.125 C 213 197.791667 214.125 201.791667 213.125 205.5 C 212.166667 209.25 209.25 212.166667 205.5 213.125 C 201.791667 214.125 197.791667 213 195.125 210.208333 L 128 143.083333 L 60.875 210.208333 C 58.208333 213 54.208333 214.125 50.5 213.125 C 46.75 212.166667 43.833333 209.25 42.875 205.5 C 41.875 201.791667 43 197.791667 45.791667 195.125 L 112.916667 128 L 45.791667 60.875 C 42.666667 57.833333 41.708333 53.25 43.333333 49.208333 C 45 45.208333 48.875 42.583333 53.208333 42.583333 C 56.125 42.583333 58.875 43.708333 60.875 45.791667 Z M 60.875 45.791667 "
      transform="matrix(0.09375,0,0,0.09375,0,0)"
    />
  </svg>
)
export const FreebieIcon = ({ color = '#108ee9' }) => (
  <span style={{ marginLeft: 24, marginRight: 4,  verticalAlign: 'sub' }}>
    <svg width="15px" height="15px" viewBox="0 0 32.000000 32.000000">
      <g
        transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M144 312 c-6 -4 -42 -67 -79 -139 -57 -111 -66 -135 -55 -148 24 -30 41 -14 92 90 29 58 55 105 58 105 3 0 29 -47 58 -105 39 -78 57 -105 71 -105 40 0 33 33 -35 165 -61 117 -78 145 -94 145 -3 0 -10 -4 -16 -8z" />
      </g>
    </svg>
  </span>
)
