import { createSlice } from '@reduxjs/toolkit'
import { VIEW } from 'config/constant'

const initialState = {
  viewType: VIEW.TYPE.LIST,
  viewSize: VIEW.SIZE.SMALL,
  inGridView: true,
  inListView: false,
}

const viewTypeSlice = createSlice({
  name: 'view_by/type',
  initialState,
  reducers: {
    setView: (state, action) => {
      const { type, size } = action.payload
      state.viewType = type
      state.viewSize = size
      state.inGridView = type === VIEW.TYPE.GRID
      state.inListView = type === VIEW.TYPE.LIST
    },
    setViewType: (state, action) => {
      state.viewType = action.payload
      state.inGridView = action.payload === VIEW.TYPE.GRID
      state.inListView = action.payload === VIEW.TYPE.LIST
    },
    setViewSize: (state, action) => {
      state.viewSize = action.payload
      state.inGridView = action.payload === VIEW.TYPE.GRID
      state.inListView = action.payload === VIEW.TYPE.LIST
    },
    toGridView: state => {
      state.viewType = VIEW.TYPE.GRID
      state.inGridView = true
      state.inListView = false
    },
    toListView: state => {
      state.viewType = VIEW.TYPE.LIST
      state.inGridView = false
      state.inListView = true
    },
  },
  // extraReducers: {
  //   [getStorage.pending]: state => {
  //     state.loading = true
  //     state.error = null
  //     state.storage = 0
  //   },
  //   [getStorage.fulfilled]: (state, { payload }) => {
  //     state.loading = false
  //     state.storage = payload.currentStorage
  //   },
  //   [getStorage.rejected]: (state, { payload }) => {
  //     state.loading = false
  //     state.error = payload
  //     state.storage = 0
  //   },
  // },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = viewTypeSlice
export const { setView, toGridView, toListView } = actions
export default reducer
