import React from 'react'
import { Button } from 'antd'
import { UsergroupAddOutlined } from '@ant-design/icons'
import { useRouter } from 'hooks'
import { managerSelector } from 'redux/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const SharedFolders = () => {
  const { router, navigate } = useRouter()
  const { t, i18n } = useTranslation()
  // const { isShared } = useSelector(managerSelector)
  const { inSharedFolder } = router

  const handleClick = () => {
    navigate('/shared-folder')
  }
  // if (!isShared) return null
  return (
    <div>
      <Button
        onClick={handleClick}
        type="link"
        className={`button-trash-bin ${inSharedFolder ? 'active' : ''}`}
      >
        <UsergroupAddOutlined className="ml-4" />
        <span>{t('shared_items')}</span>
      </Button>
    </div>
  )
}

export default SharedFolders
