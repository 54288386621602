import { createSlice } from '@reduxjs/toolkit'
import { SORT_BY_ORDER, SORT_BY_TYPE } from 'config/constant'

const initialState = {
  type: SORT_BY_TYPE.NAME,
  order: SORT_BY_ORDER.DESC,
}

const sortSlice = createSlice({
  name: 'view_by/sort',
  initialState,
  reducers: {
    sortBy: (state, action) => {
      state.type = action.payload.type
      state.order = action.payload.order
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setOrder: (state, action) => {
      state.order = action.payload
    },
    // logout: state => {
    //   state.loading = false
    //   state.userInfo = null
    //   state.error = null
    // },
  },
  // extraReducers: {
  //   [getStorage.pending]: state => {
  //     state.loading = true
  //     state.error = null
  //     state.storage = 0
  //   },
  //   [getStorage.fulfilled]: (state, { payload }) => {
  //     state.loading = false
  //     state.storage = payload.currentStorage
  //   },
  //   [getStorage.rejected]: (state, { payload }) => {
  //     state.loading = false
  //     state.error = payload
  //     state.storage = 0
  //   },
  // },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = sortSlice
export const { sortBy, setType, setOrder } = actions
export default reducer
