import { useEffect, useState } from 'react'

export function useResize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [widthMainView, setWidthMainView] = useState(0)
  const [previewSize, setPreviewSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    top: 0,
    left: 0
  })

  useEffect(() => {
    const onResize = () => {
      const mainView = document.getElementById('main-view')
      const previewBody = document.getElementById('preview-body')
      if (previewBody) {
        const { width, height, top, left } = previewBody.getBoundingClientRect()
        setPreviewSize({ height, width, top, left })
      }
      if (mainView) {
        const { width } = mainView.getBoundingClientRect()
        setWidthMainView(width)
      }

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return { windowSize, widthMainView, previewSize }
}

export default useResize
