import { useContextMenu, useRouter } from 'hooks'
import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'
import { useAnimationControls, motion } from 'framer-motion/dist/framer-motion'
import {SharedFolderMenuContext} from './SharedFolderMenuContext'
import { FileMenuContext } from './FileMenuContext'
import { TrashContext } from './TrashContext'
import {FreebieContext} from './FreebieContext'

const ContextMenu = () => {
  const controls = useAnimationControls()
  const { xPos, yPos, showMenu } = useContextMenu()
  const { inTrashBin, inFreebieFolder, inMyFolder, inSharedFolder } = useRouter().router
  //   const { selected } = useSelector(managerSelector)
  useEffect(() => {
    controls.set({ opacity: 0, height: 0 })
    controls.start(i => ({
      opacity: 1,
      height: 45,
      // transition: { delay: i * 0.3 },
    }))
  }, [xPos])

  if (showMenu) {
    return (
      <div
        className="menu-container"
        style={{
          top: yPos,
          left: xPos,
          position: 'fixed',
          zIndex: 12000,
        }}
      >
        {inTrashBin && <TrashContext controls={controls} />}
        {inFreebieFolder && <FreebieContext controls={controls} />}
        {inMyFolder && <FileMenuContext controls={controls} />}
        {inSharedFolder && <SharedFolderMenuContext controls={controls} />}
      </div>
    )
  }

  return <></>
}

export default ContextMenu
