import { createSlice } from '@reduxjs/toolkit'
import { ACTION, CLOUD, MODE, TAB } from 'config/constant'
import { stat } from 'fs'
import { downloadFileThunk, renameThunk } from './managerActions'

const initialState = {
  selected: [],
  notSelected: true,
  isDrag: false,
  canEdit: false,
  canShare: false,
  isShared: false,
  tab: TAB.CLOUD,
}

const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    setPermissionEdit: (state, action) => {
      state.canEdit = action.payload
    },
    setTab: (state, action) => {
      state.tab = action.payload
    },
    setCanShare: (state, action) => {
      state.canShare = action.payload
    },
    setIsShared: (state, action) => {
      state.isShared = action.payload
    },
    clearSelect: state => {
      state.selected = []
      state.notSelected = true
    },
    onSelect: (state, action) => {
      const key = action.payload
      state.selected = [key]
      state.notSelected = state.selected.length === 0
    },
    onSelectContext: (state, action) => {
      const key = action.payload
      if (state.selected.length < 2) {
        state.selected = [key]
      }
      state.notSelected = state.selected.length === 0
    },

    onSelectMore: (state, action) => {
      const key = action.payload
      if (state.selected.includes(key)) {
        state.selected = state.selected.filter(e => e !== key)
      } else {
        state.selected.push(key)
      }
      state.notSelected = state.selected.length === 0
    },

    onDrag: (state, action) => {
      state.selected = action.payload
      state.notSelected = state.selected.length === 0
    },

    onDragMore: (state, action) => {
      state.selected = [...state.selected, ...action.payload]
      state.notSelected = state.selected.length === 0
    },

    setMode: (state, action) => {
      state.mode = action.payload
    },
    setIsDrag: (state, action) => {
      state.isDrag = action.payload
    },
  },

  extraReducers: {
    [downloadFileThunk.pending]: state => {
      state.error = null
    },
    [downloadFileThunk.fulfilled]: (state, { payload }) => {
      state.response = { action: state.action, result: payload }
      state.action = ACTION.SUCCESS
    },
    [downloadFileThunk.rejected]: (state, { payload }) => {
      state.error = payload
      state.action = ACTION.ERROR
    },

    [renameThunk.pending]: state => {
      state.error = null
    },
    [renameThunk.fulfilled]: (state, { payload }) => {
      state.response = { action: state.action, result: payload }
      state.action = ACTION.SUCCESS
    },
    [renameThunk.rejected]: (state, { payload }) => {
      state.error = payload
      state.action = ACTION.ERROR
    },
  },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = managerSlice
export const {
  onSelect,
  onSelectContext,
  setTab,
  onSelectMore,
  onDrag,
  onDragMore,
  publishEvent,
  clearSelect,
  setMode,
  setIsDragging,
  setIsDrag,
  setPermissionEdit,
  setCanShare,
  setIsShared,
} = actions
export default reducer
