import { config } from 'config'
import { encryptId } from 'utils'

const {
  shareFolder,
  getSharedDownloadURL,
  genURL,
  downloadFile,
  shareFiles,
  zipData,
  share1File,
} = require('../upload')

export const getLinkDownloadFolder = async folders => {
  const share = await shareFolder({
    sender: 'aaa@aaa.aaa',
    receivers: ['aaa@aaa.aaa'],
    notification: false,
    selected: folders,
    message: '',
  })
  if (share.data) {
    const url = await getSharedDownloadURL({ shared: `${share.data.shared[0]}=` })
    console.log({ url })
    return url
  }
  return 'error'
}

export const getLinkShareFolder = async folders => {
  const share = await shareFolder({
    sender: 'aaa@aaa.aaa',
    receivers: ['aaa@aaa.aaa'],
    notification: false,
    selected: folders,
    message: '',
  })
  if (share.data) {
    return `${config.endpoint.home}/s/${share.data.shared[0]}`
  }
  return 'error'
}

export const createLinkShare = async ({ selectedFiles }) => {
  if (selectedFiles.length === 1 && selectedFiles[0].type === 'file') {
    await createLinkShareFile({ selectedFile: selectedFiles[0] })
  } else {
    await createLinkShareFolder({ selectedFiles })
  }
}

export const createLinkShareFile = async ({ selectedFile }) => {
  const params = {
    fileName: selectedFile.name,
    path: selectedFile.path,
    typeShare: 'file',
  }
  const result = await share1File(params)
  const shareEncrypt = encryptId(result.data.sharedId)
  console.log(shareEncrypt)
  localStorage.setItem('share-id', `d/${shareEncrypt}`)
}

export const getLinkShareFile = async ({ selectedFile }) => {
  const params = {
    fileName: selectedFile.name,
    path: selectedFile.path,
    typeShare: 'file',
  }
  const result = await share1File(params)
  const shareEncrypt = encryptId(result.data.sharedId)
  return shareEncrypt
  // copy(`${config.endpoint.home}/d/${shareEncrypt}`)
}

export const createLinkShareFolder = async ({ selectedFiles }) => {
  const params = {
    selected: selectedFiles,
    typeShare: 'folder',
  }
  const result = await share1File(params)
  const shareEncrypt = encryptId(result.data.sharedId)
  // copy(`${config.endpoint.home}/s/${shareEncrypt}`)
  localStorage.setItem('share-id', `s/${shareEncrypt}`)
}

export const downloadMultiFiles = async (
  { selectedFiles, currentFolder },
  handlePercent,
  onSuccess,
  handleError,
) => {
  const selectedFile = selectedFiles[0]
  let downloadInfo = null
  if (selectedFiles.length === 1 && selectedFile.type === 'file') {
    downloadInfo = {
      url: await genURL(selectedFile),
      name: selectedFiles[0].name,
    }
  } else if (selectedFiles.length === 1 && selectedFile.type === 'folder') {
    const zipName = `${selectedFile.name}.zip`
    const params = { selectedFiles, zipName, currentFolder }
    const result = await zipData(params)
    downloadInfo = {
      url: result.data.url,
      name: zipName,
    }
  } else {
    const date = new Date().toJSON()
    const zipName = `ACデータ-ダウンロード-${date}.zip`
    const params = { selectedFiles, zipName, currentFolder }
    const result = await zipData(params)
    downloadInfo = {
      url: result.data.url,
      name: zipName,
    }
  }
  await downloadFile(downloadInfo, handlePercent, onSuccess, handleError)
}
