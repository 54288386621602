import React, { useEffect } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import ContextMenu from 'components/context-menu/ContextMenu'
import { USER_TYPE } from 'config/constant'
import 'react-toastify/dist/ReactToastify.css'
import { userSelector } from 'redux/selectors'
import LeftPane from './components/left-pane/LeftPane'
import ToolBar from './components/main-view/components/toolbar'
import MainView from './components/main-view/MainView'
import { getFolders } from './folder/folderActions'

export function FileManager() {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(userSelector)
  // useBeforeunload(() => 'Are you sure to close this tab?')

  useEffect(() => {
    if (userInfo.isAuth === false && userInfo.type === USER_TYPE.LOGIN) return
      dispatch(getFolders())
  }, [userInfo])

  return (
    <div id="selectable-area">
      <div className="row no-gutters">
        <DndProvider backend={HTML5Backend}>
          <LeftPane />
          <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-18 ant-col-lg-18 ant-col-xl-19 ant-col-xxl-20">
            <ToolBar />
            <MainView />
          </div>
        </DndProvider>
      </div>
    </div>
  )
}

export default FileManager
