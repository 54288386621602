import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Modal, Button } from 'antd'
import ShareForm from './ShareForm'

class ShareModal extends React.Component {
  render() {
    const { t } = this.props
    const { onCancel, data } = this.props
    return (
      <Modal
        {...this.props}
        open
        title={t('sharing_form_tittle')}
        footer={null}
        centered
        destroyOnClose
      >
        <ShareForm onCancel={onCancel} selectedFiles={data} />
      </Modal>
    )
  }
}

export default withTranslation()(ShareModal)
