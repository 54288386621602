import { Tree } from 'antd'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { treeFolderSelector } from 'redux/selectors'
import _path from 'path'
import { removeTrailingSlash } from 'utils'
import { useRouter } from 'hooks'
import DragableNode from './DragableNode'

const { TreeNode } = Tree
const { DirectoryTree } = Tree

function FileTree() {
  const { t } = useTranslation()
  const { trees, error } = useSelector(treeFolderSelector)
  const { router, location, navigate } = useRouter()
  // const defaultSelected = useRef([])
  const { currentFolder, inMyFolder } = router
  const [defaultExpanded, setDefaultExpanded] = useState([])
  const [defaultSelected, setDefaultSelected] = useState([])

  const ref = useRef()

  useEffect(() => {
    // defaultSelected.current = []
    setDefaultSelected([])
    if (inMyFolder) {
      let arr = currentFolder.split('/')
      arr = arr.filter(el => el !== '')
      setDefaultExpanded([''].concat(arr))
      // defaultSelected.current = [removeTrailingSlash(currentFolder)]
      setDefaultSelected([removeTrailingSlash(currentFolder)])
      // if (defaultSelected.current.length === 1 && defaultSelected.current[0] === '')
      //   defaultSelected.current = ['root']
    }
  }, [location])

  const onSelect = (keys, info) => {
    const path = encodeURIComponent(keys[0])
    // if (path === 'root') path = '/'
    const url = _path.join('/folder', path)
    navigate(url)
  }

  const treeData = [
    {
      title: t('my_folder'),
      path: '',
      name: '',
      key: '',
      children: error ? [] : trees,
    },
  ]

  const onExpand = expanded => {
    setDefaultExpanded(expanded)
  }

  const TreeNodes = getTreeNode(treeData, ref)
  return (
    <DirectoryTree
      defaultExpandParent
      height={510}
      selectedKeys={defaultSelected}
      expandedKeys={defaultExpanded}
      onSelect={onSelect}
      onExpand={onExpand}
    >
      {TreeNodes}
    </DirectoryTree>
  )
}

export default FileTree

const getTreeNode = (treeContent, ref) => {
  if (!treeContent || treeContent.length === 0) {
    return null
  }
  const treeNode = treeContent.map(value => {
    // if (value.id === 'all') {
    //   return (
    //     <TreeNode title={<DragableNode value={value} />} key={value.key}>
    //       {getTreeNode(value.children)}
    //     </TreeNode>
    //   )
    // }
    return (
      <TreeNode domRef={ref} title={<DragableNode value={value} />} key={value.key}>
        {getTreeNode(value.children)}
      </TreeNode>
    )
  })
  return treeNode
}
