import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

function DuplicateAlertModal({ open, onOk, onCancel }) {
  const { t } = useTranslation()
  return (
    <Modal
      centered
      // visible={isOpenDuplicateAlert}
      open
      onOk={onOk}
      // setNewUpdateName('')
      // setOpenDuplicateAlert(false)
      // }}
      onCancel={onCancel}
      // setNewUpdateName('')
      // setOpenDuplicateAlert(false)
      // }}
      okText={t('close_button')}
      cancelButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <p>
        {t('duplicate_alert_content_1')}
        {/* {newUpdatedName} */}
        {t('duplicate_alert_content_2')}
      </p>
    </Modal>
  )
}

export default DuplicateAlertModal
