import axios from 'axios'
import { ACTION, DEFAULT_FOLDER_PATH, GB_BYTE, MODAL_TYPES, USER_TYPE } from 'config/constant'
import { OverlayUpload, UploadProgress } from 'features/uploader'
import { uploadFileThunk } from 'features/uploader/uploadActions'
import { setAllFilePercents, setLists } from 'features/uploader/uploaderSlice'
import _path from 'path'
import React, { Fragment, useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  fileSelector,
  managerSelector,
  routerSelector,
  storageSelector,
  userSelector,
} from 'redux/selectors'
import { uploadBigSizeNotify } from 'services/upload'
import { checkDuplicatedFile, createFolder } from 'services/upload/upload.service'
import { validateStorage } from 'utils'
import uuidV4 from 'uuid/v4'
import useRouter from 'hooks/useRouter'
import { useHandleAction, useHandlePermission } from 'hooks'
import { publish } from 'events/event'
import { getFolders } from '../features/manager/folder/folderActions'

const { CancelToken } = axios
let source = CancelToken.source()

export function useUploader() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selected, isDrag } = useSelector(managerSelector)
  const { storage } = useSelector(storageSelector)
  const { userInfo } = useSelector(userSelector)
  const [canceled, setCanceled] = useState([])
  const { router } = useRouter()
  const { currentFolder, inTrashBin, inSharedFolder, inFreebieFolder } = router
  const { preventDrag, preventUpload } = useHandlePermission()
  const { files, folders } = useSelector(fileSelector)
  // const { handleConfirmDuplicateOptions } = useHandleAction()
  const toastId = useRef(null)
  const setList = value => {
    dispatch(setLists(value))
  }

  const onCancel = () => {
    source.cancel('Operation canceled by the user.')
    source = CancelToken.source()
  }

  const notify = () => {
    if (!toast.isActive('uploader')) {
      toastId.current = toast(<UploadProgress />, {
        containerId: 'upload',
        toastId: 'uploader',
        closeButton: false,
      })
    }
  }

  const onDrop = useCallback(async acceptedFiles => {
    if (inFreebieFolder) {
      toast.warning(t('drop_upload_warning_freebie'), { containerId: 'alert' })
      return
    }
    if (inTrashBin) {
      toast.warning(t('drop_upload_warning_trash'), { containerId: 'alert' })
      return
    }
    if (inSharedFolder && currentFolder === DEFAULT_FOLDER_PATH) {
      toast.warning(t('drop_upload_warning_shared'), { containerId: 'alert' })
      return
    }
    if (userInfo.type === USER_TYPE.FREE_NOT_LOGIN)
      publish(ACTION.SHOW_MODAL, {
        type: MODAL_TYPES.trial,
        data: {},
      })
    if (isDrag) return
    const totalSizes = acceptedFiles.reduce((tot, item) => {
      return tot + item.size
    }, 0)
    const dataSource = acceptedFiles.map((item, key) => ({
      key: _path.join(currentFolder, item.path),
      name: item.name,
      fileSize: item.size,
      path: _path.join(currentFolder, item.path.replace(item.name, '')),
      type: 'file',
      currentFolder,
    }))

    const checkDuplicated = checkDuplicatedFile({
      acceptedFiles,
      files,
      folders,
    })
    // handleConfirmDuplicateOptions()
    if (checkDuplicated.isDuplicatedName) {
      // handleConfirmDuplicateOptions()
      toast(
        t(checkDuplicated.type === 'file' ? 'duplicated_file_name' : 'duplicated_folder_name'),
        { containerId: 'alert' },
      )
      return
    }
    const { userStorage, overStorageAlert } = validateStorage(userInfo.isPremium)
    if (totalSizes + storage <= userStorage * GB_BYTE) {
      const allUploadFolders = acceptedFiles.map(item => item.path)
      const uploadFolders = []
      const level = { uploadFolders }
      allUploadFolders.map(key => {
        let folderKey = _path.dirname(key)
        if (folderKey === '.') return null
        if (folderKey.slice(0, 1) === '/') folderKey = folderKey.slice(1)
        const foldersList = folderKey.split('/')
        return foldersList.reduce((r, name, i, a) => {
          if (!r[name]) {
            r[name] = { uploadFolders: [] }
            let folderPath = [...foldersList]
            if (folderPath[folderPath.length - 1] !== name) {
              folderPath.length = folderPath.lastIndexOf(name) + 1
            }
            folderPath = folderPath.join('/')
            r.uploadFolders.push({
              name,
              children: r[name].uploadFolders,
              path: `${folderPath}/`,
            })
          }
          return r[name]
        }, level)
      })

      setList(dataSource)
      notify()
      await createFolder(uploadFolders, currentFolder)

      // if (acceptedFiles.length > 500) {
      //   uploadBigSizeNotify({ sources: dataSource })
      // }
      dispatch(uploadFileThunk({ acceptedFiles, currentFolder }))
      dispatch(getFolders())
    } else {
      toast.warning(t(overStorageAlert), {
        containerId: 'alert',
      })
    }
  })

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    onDrop,
    noClick: true,
  })

  const uploadHolder = (
    <OverlayUpload
      inputProps={getInputProps}
      isDragActive={
        isDragActive &&
        !isDrag &&
        !inFreebieFolder &&
        !inTrashBin &&
        !(inSharedFolder && currentFolder === DEFAULT_FOLDER_PATH)
      }
    />
  )

  return { getRootProps, uploadHolder, onDrop }
}
export default useUploader
