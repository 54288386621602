import { useEffect, useState } from 'react'
import { useRouter } from 'hooks'
import { DEFAULT_FOLDER_PATH } from 'config/constant'

export function useHandlePermission() {
  const { router } = useRouter()
  const { inSharedFolder, inFreebieFolder, inTrashBin, inMyFolder, currentFolder } = router

  const [canDrag, setCanDrag] = useState(true)
  const [canUpload, setCanUpload] = useState(true)

  useEffect(() => {
    if (inFreebieFolder) {
      setCanDrag(false)
      setCanUpload(false)
    } else if (inSharedFolder && currentFolder === DEFAULT_FOLDER_PATH) {
      setCanDrag(false)
      setCanUpload(false)
    } else if (inTrashBin) {
      setCanDrag(false)
      setCanUpload(false)
    }
  }, [])

  return { canDrag, canUpload, preventDrag : !canDrag, preventUpload: !canUpload }
}

export default useHandlePermission
