import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Modal } from 'antd'

function RestoreAlertModal({ open, onOk, onCancel }) {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('restore_alert_title')}
      centered
      open
      onOk={() => onOk()}
      onCancel={() => onCancel()}
      okText={t('close_button')}
      cancelButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <p>{t('restore_alert_content')}</p>
    </Modal>
  )
}

export default RestoreAlertModal
