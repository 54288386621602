import React from 'react'

const { default: confirm } = require('antd/lib/modal/confirm')

export const confirmModal = ({ title, content, handle, okText, cancelText }) => {
  return confirm({
    title: <span style={{ fontWeight: 'bold' }}>{title}</span>,
    content,
    centered: true,
    // width: "520",
    onOk() {
      handle()
    },
    okText,
    cancelText,
  })
}


export default confirmModal
