import React, { useEffect, useRef } from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHandleAction, useRouter } from 'hooks'
import { TypeDrag } from 'config/constant'
import { useDrop } from 'react-dnd'

const TrashBin = () => {
  const { t } = useTranslation()
  const { router, navigate } = useRouter()
  const { inTrashBin } = router
  const { handleConfirmDelete } = useHandleAction()
  const [{ isOver }, dropRef] = useDrop({
    accept: [TypeDrag.ROW, TypeDrag.CARD],
    drop: item => {
      handleConfirmDelete()
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const handleClickTrashBin = () => {
    navigate('/trash')
  }
  const className = `button-trash-bin 
                     ${inTrashBin ? ' active' : ''}
                     ${isOver ? ' node-hover' : ''}`
  return (
    <div>
      <Button ref={dropRef} onClick={handleClickTrashBin} type="link" className={className}>
        <DeleteOutlined className="ml-4" />
        <span>{t('trash_bin')}</span>
      </Button>
    </div>
  )
}

export default TrashBin
