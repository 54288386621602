import React from 'react'
import DashboardFiles from 'pages/dashboard'
import SharedFolder from 'pages/share/SharedFolder'
import Error404 from 'pages/system/Errors/404'
import DownloadFile from 'pages/download/DownloadFile'
import FilePreviewModal from 'features/modal/components/preview-modal/FilePreview'
import { FREEBIE_FOLDER_URL } from 'config/constant'
// import MailMagazine from 'pages/mail-magazine/MailMagazine'
// import MailMagazineLogin from 'pages/mail-magazine/MailMagazineLogin'

function MainScreen() {
  // localStorage.setItem('sharedids', '[]')
  // localStorage.setItem('isSharedFolder', false)
  // localStorage.setItem('customFolder', '/')
  localStorage.setItem('current_folder', '/')
  localStorage.setItem('cloud', '1')
  localStorage.setItem('tabIndex', '2')
  return <DashboardFiles />
}
function TransferScreen() {
  localStorage.setItem('tabIndex', '1')
  return <DashboardFiles />
}
function DownloadScreen() {
  return <DownloadFile />
}
function ShareScreen() {
  return <SharedFolder />
}
// function MailMagazineScreen() {
//   return <MailMagazine />
// }
// function MailMagazineLoginScreen() {
//   return <MailMagazineLogin />
// }
function MaterialsDownload() {
  return <MaterialsDownload />
}
function NotFoundScreen() {
  return <Error404 />
}
function ExamplePDFViewer() {
  // return (
  // )
}

export default [
  {
    path: '/preview',
    component: ExamplePDFViewer,
    exact: true,
  },
  {
    path: '/',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/cloud',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/transfer',
    component: TransferScreen,
    exact: true,
  },
  {
    path: '/folder',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/dashboard/files',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/folder/*',
    component: MainScreen,
    exact: false,
  },
  {
    path: '/shared-folder',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/shared-folder/*',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/shared-folder/:path',
    component: MainScreen,
    exact: false,
  },
  {
    path: '/trash',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/trash/:path',
    component: MainScreen,
    exact: true,
  },

  {
    path: '/d/:metadata',
    component: DownloadScreen,
    exact: true,
  },
  {
    path: '/s/:metadata',
    component: ShareScreen,
    exact: true,
  },
  {
    path: '/m/:metadata/:expired',
    component: MainScreen,
    exact: true,
  },
  // {
  //   path: '/materials/:metadata/:expired',
  //   component: MailMagazineScreen,
  //   exact: true,
  // },
  {
    path: '/photo/:metadata/:expired',
    component: MaterialsDownload,
    exact: true,
  },
  {
    path: '/illust/:metadata/:expired',
    component: MaterialsDownload,
    exact: true,
  },
  {
    path: '/silhouette/:metadata/:expired',
    component: MaterialsDownload,
    exact: true,
  },
  {
    path: '/freebie',
    component: MainScreen,
    exact: true,
  },
  {
    path: `/freebie/*`,
    component: MainScreen,
    exact: true,
  },
  {
    path: `/freebie/:path`,
    component: MainScreen,
    exact: true,
  },
  {
    path: '*',
    component: NotFoundScreen,
    exact: false,
  },
]
