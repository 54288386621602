import React, { useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Layout } from 'antd'
import Header from 'layouts/elements/header'
import TopBar from 'layouts/elements/top-bar'
import Footer from 'layouts/elements/footer'
import { isMacOS } from 'utils/common.util'
import { userSelector } from 'redux/selectors'
import { useSelector } from 'react-redux'

function DownloadLayout({ children }) {
  const { t, i18n } = useTranslation()
  const { userInfo } = useSelector(userSelector)

  useEffect(() => {
    if (!userInfo.isPremium && !window.adsbygoogle) {
      const script = document.createElement('script')
      script.setAttribute('data-ad-client', 'ca-pub-6219550375267396')
      script.setAttribute('async', '')
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }, [])

  return (
    <Layout>
      <Header lang={i18n.language} />
      <Layout.Header>
        <TopBar i18n={i18n} t={t} />
      </Layout.Header>
      <Layout.Content
        style={{ position: 'relative', width: '68vw', marginTop: '2%' }}
        className="mr-auto ml-auto"
      >
        <div>{children}</div>
        <div
          id="div-gpt-ad-1604031951387-0"
          style={{
            textAlign: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <script
            dangerouslySetInnerHTML={{
              __html: `
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1604031951387-0'); });
              `,
            }}
          />
        </div>
      </Layout.Content>
      <div
        className="row bookmark-description"
        style={{ padding: '0 0 50px 0', justifyContent: 'center' }}
      >
        {t('bookmark_if')}
        <div className="bookmark-key">{isMacOS() ? 'Cmd ⌘' : 'Ctrl'}</div> +{' '}
        <div className="bookmark-key">D</div>
        {t('bookmark_then')}
      </div>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  )
}

export default withTranslation()(DownloadLayout)
