/* eslint-disable no-plusplus */
import { ACTION, DEFAULT_FOLDER_PATH, MODAL_TYPES, TypeDrag } from 'config/constant'
import { publish } from 'events/event'
import { setIsDrag } from 'features/manager/managerSlice'
import { useHandleAction, useHandlePermission, useRouter } from 'hooks'
import React, { useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch } from 'react-redux'

export function Row({ ...props }) {
  const { record, active, className, isdrag, style } = props
  const dispatch = useDispatch()
  const ref = useRef()
  const { onDropToMove, onDropTreeToFolder } = useHandleAction()
  const { preventDrag, preventUpload } = useHandlePermission()

  const [{ isOver }, dropRef] = useDrop({
    accept: TypeDrag.CARD,
    drop: (item, monitor) => {
      if (preventDrag || preventUpload) return
      const typeItem = monitor.getItemType()
      if (typeItem === TypeDrag.ROW) onDropTreeToFolder(item, record.path)
      if (typeItem === TypeDrag.CARD) onDropToMove(record.path)
    },
    canDrop: item => {
      if (record.type === 'folder') return true
      return false
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: TypeDrag.CARD,
    item: record,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  dropRef(ref)
  dragRef(ref)

  useEffect(() => {
    dispatch(setIsDrag(isDragging))
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const customClassName = ` item-ref file-row
  ${isOver && record.type === 'folder' ? 'row-drag-over' : ''} 
  ${active && isdrag ? 'item-dragging' : ''}
  ${active ? 'row-selected' : ''}
  `
  // ${hover ? 'row-hover' : ''}

  return (
    <tr
      ref={ref}
      key={record?.key}
      id={record?.key}
      {...props}
      className={className.concat(customClassName)}
    />
  )
}

export default {
  Row,
}
