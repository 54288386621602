import { AlertAutoDelete } from 'components'
import { SortComponent } from 'features/view-by'
import { useRouter } from 'hooks'
import React from 'react'
import Breadcrumbs from './components/Breadcrumbs'
import FreebieToolBar from './components/FreebieToolBar'
import MainToolBar from './components/MainToolBar'
import SharedFolderToolBar from './components/SharedFolderToolBar'
import TrashBinToolBar from './components/TrashBinToolBar'

function ToolBar() {
  const { inTrashBin, inFreebieFolder, inMyFolder, inSharedFolder } = useRouter().router

  return (
    <div id="tool-bar">
      <Breadcrumbs />
      {inMyFolder && <MainToolBar />}
      {inSharedFolder && <SharedFolderToolBar />}
      {inFreebieFolder && <FreebieToolBar />}
      {inTrashBin && <TrashBinToolBar />}
      {inTrashBin && <AlertAutoDelete />}
      <SortComponent />
    </div>
  )
}

export default ToolBar
