import React, { useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { getInfoUserByEmail, validateSSOId } from 'services/upload'
import { isEmpty, isUgly, last } from 'utils'

const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

function TagInput({ onSubmit, props }) {
  const { t } = useTranslation()
  const [input, setInput] = useState('')
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)

  const handleTagChange = async (value, actionMeta) => {
    const { action } = actionMeta
    let newOption = null
    let listTags = []
    setTags(value)
    if (value === null) {
      setTags([])
      onSubmit([])
      return
    }
    switch (action) {
      case 'create-option':
        newOption = await handleCreateOption(value)
        break
      case 'remove-value':
        return
      case 'select-option':
        newOption = await handleCreateOption(value)
        break
      case 'clear':
        return
      default:
        break
    }
    if (newOption) {
      listTags = tags.concat([newOption])
      // onSubmit(validEmails)
    } else {
      listTags = tags.splice(-1)
    }
    setTags(listTags)
    onSubmit(listTags)
  }

  const validateEmail = async email => {
    setLoading(true)

    const sharedUser = await getInfoUserByEmail(email)
    if (isUgly(sharedUser)) {
      setLoading(false)
      toast.error(t('account_not_found'), {
        containerId: 'alert',
      })
      console.log('account not found')
      return null
    }

    const uuid = await validateSSOId(sharedUser?.user_id)
    if (isUgly(uuid)) {
      console.log('uuid not found')
      setLoading(false)
      toast.error(t('account_require_login'), {
        containerId: 'alert',
      })
      return null
    }
    setLoading(false)

    const options = {
      label: email,
      value: email,
      uuid,
      user: sharedUser,
    }

    return options
  }

  const handleCreateOption = value => {
    if (isEmpty(value)) return null
    const newOption = last(value)
    const emailReg = emailRegexp.test(newOption.label)
    if (emailReg) {
      const validEmail = validateEmail(newOption.label)
      return validEmail
    }
    return null
  }

  const rememberEmail = email => {
    let receivers = JSON.parse(localStorage.getItem('receivers')) || []
    if (!receivers.includes(email)) {
      receivers = [email].concat(receivers)
      localStorage.setItem('receivers', JSON.stringify(receivers))
    }
    // receivers = receivers.filter((item, index) => receivers.indexOf(item) === index)
  }

  const getOptions = () => {
    const receivers = JSON.parse(localStorage.getItem('receivers')) || []
    return receivers.map(receiver => ({ value: receiver, label: receiver }))
  }

  const customStyles = {
    multiValue: styles => {
      return {
        ...styles,
        backgroundColor: '#fbfbfb',
      }
    },
    multiValueLabel: styles => ({
      ...styles,
      color: '#253858',
      fontSize: 'inherit',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#253858',
      ':hover': {
        backgroundColor: '#253858',
        color: 'white',
      },
    }),
  }

  const receivers = getOptions()
  return (
    <CreatableSelect
      {...props}
      // inputValue={input}
      isClearable
      isMulti
      onChange={handleTagChange}
      // onInputChange={handleInputChange}
      // onKeyDown={handleKeyDown}
      // placeholder={t('sharing_form_receivers_placeholder')}
      placeholder={<span style={{ fontSize: 12 }}>{t('sharing_form_receivers_placeholder')}</span>}
      value={tags}
      // onBlur={handleBlur}
      styles={customStyles}
      noOptionsMessage={() => null}
      formatCreateLabel={userInput => `${userInput}`}
      options={receivers}
      isLoading={loading}
      createOptionPosition="first"
    />
  )
}
export default withTranslation()(TagInput)
