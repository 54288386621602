import { Button, Empty, List } from 'antd'
import { useResize } from 'hooks'
import { VIEW } from 'config/constant'
import React from 'react'
import { EmptyComponent } from 'components'

import { useSelector } from 'react-redux'
import { fileSelectorWithFilter, viewSelector } from 'redux/selectors'
import FolderCard from './FolderCard'
import FileCard from './FileCard'

function GridView() {
  const { windowSize, widthMainView } = useResize()
  const { filterResult, loading } = useSelector(fileSelectorWithFilter)
  const { viewSize } = useSelector(viewSelector)
  const size = viewSize === VIEW.SIZE.SMALL ? VIEW.SIZE_PX.SMALL : VIEW.SIZE_PX.LARGE
  const column = Math.floor(widthMainView / size)
  return (
    <List
      id="grid-view"
      grid={{ gutter: 15, column }}
      dataSource={filterResult}
      pagination={{
        showSizeChanger: false,
        pageSize: 15,
      }}
      locale={{
        emptyText: <EmptyComponent />,
      }}
      loading={loading}
      renderItem={item => {
        return item.type === 'file' ? (
          <FileCard fileItem={item} viewSize={viewSize} />
        ) : (
          <FolderCard folderItem={item} viewSize={viewSize} />
        )
      }}
    />
  )
}

export default GridView
