/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, useEffect, useState } from 'react'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import { DownloadOutlined, LoadingOutlined, RollbackOutlined } from '@ant-design/icons'
import { Button, Input, Alert, Result, Breadcrumb, Menu } from 'antd'
// import FilePreviewModal from 'components/FilePreview/FilePreviewModal'

import {
  getSharedFolders,
  verifySharedPassword,
  verifySharedFolders,
  getFileUrlForView,
} from 'services/upload'
import _path from 'path'
import { truncate, getSourceByName } from 'utils/common.util'
import ModalContainer from 'features/modal'
import SharedList from './SharedList'

const SharedFolder = () => {
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState(false)
  const { metadata } = useParams()
  const [shared, setShared] = useState(`${metadata.replace(/\&/g, '/')}=`)
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')
  const [password, setPassword] = useState('')
  const [sharedDetails, setSharedDetails] = useState({})
  const [files, setFiles] = useState([])
  const [folders, setFolders] = useState([])
  const [loading, setLoading] = useState(false)
  const [fileExisted, setFileExisted] = useState(true)
  const [fileProtected, setFileProtected] = useState(false)
  const [openFilePreviewModal, setOpenFilePreviewModal] = useState(false)
  const [URL, setURL] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [fileLoading, setFileLoading] = useState(false)
  const [currentFolder, setCurrentFolder] = useState('/')
  const [previousFolder, setPreviousFolder] = useState([])

  useEffect(() => {
    async function fetch() {
      if (metadata) {
        setLoading(true)
        const data = {
          shared,
        }
        const verifyShared = await verifySharedFolders(data)

        if (!verifyShared.fileExisted) {
          setFileExisted(false)
          setLoading(false)
          setTimeout(() => setRedirect(true), 5000)
          return false
        }

        if (!verifyShared.fileProtected) {
          await loadSharedFolders(false, '/')
        }
        setFileExisted(true)
        setFileProtected(verifyShared.fileProtected)
        setLoading(false)
      }
      return true
    }
    fetch()
  }, [])

  useEffect(() => {
    setFiles(sharedDetails.selectedFiles)
    setFolders(sharedDetails.selectedFolders)
  }, [sharedDetails])

  const genURL = async item => {
    const url = await getFileUrlForView(sharedDetails.id, {
      filename: item.fileName,
      path: item.path === '' ? '/' : item.path,
    })
    return url
  }

  const loadSharedFolders = async (protect = true, path = '/', back = false) => {
    if (protect) {
      setSharedDetails({
        selectedFiles: [],
        selectedFolders: [],
        sender_email: '',
        title: '',
        message: '',
        expiredDate: '',
      })
    } else {
      const sharedDetail = await getSharedFolders({ shared, path })
      setSharedDetails(sharedDetail)
    }
    if (back) {
      setPreviousFolder([...previousFolder.slice(0, -1)])
    } else {
      setPreviousFolder([...previousFolder.concat(currentFolder)])
    }
    setCurrentFolder(path)
  }

  const handleVerifySharedPassword = async () => {
    setLoading(true)
    const result = await verifySharedPassword({
      shared,
      password,
    })
    if (result.checkPassword) {
      setFileProtected(false)
      await loadSharedFolders(false, '/')
    }
    setError(t('invalid_password'))
    setLoading(false)
    return true
  }

  let dataSource = []
  if (files && files.length > 0) {
    dataSource = files.map((item, index) => ({
      key: item,
      fileName: _path.basename(item),
      name: _path.basename(item),
      path: _path.join('/', _path.dirname(item), '/').slice(1, _path.dirname(item).length + 1),
      type: 'file',
      id: index + 1,
    }))
  }

  if (folders && folders.length > 0) {
    const temp = folders.map((item, index) => ({
      key: item,
      fileName: _path.basename(item),
      name: _path.basename(item),
      path: item,
      type: 'folder',
      id: files.length + index + 1,
    }))
    dataSource = temp.concat(...dataSource)
  }
  if (loading)
    return (
      <div className="container">
        <div className="row justify-content-md-center mt-5">
          <div className="col" style={{ textAlign: 'center' }}>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        </div>
      </div>
    )
  return (
    <div className="container">
      {/* <Helmet title={t('download_files_tittle')} /> */}
      <div className="row justify-content-md-center mt-5">
        <div className="col">
          {fileExisted && !fileProtected && !loading && dataSource.length > 0 && (
            <>
              <Button
                style={{ border: 0 }}
                disabled={previousFolder.length <= 1}
                onClick={() => {
                  if (previousFolder[previousFolder.length - 1] !== currentFolder) {
                    loadSharedFolders(false, previousFolder[previousFolder.length - 1], true)
                  }
                }}
              >
                <RollbackOutlined />
                {t('back_button')}
              </Button>
              <SharedList
                t={t}
                dataSource={dataSource}
                loadSharedFolders={loadSharedFolders}
                title={sharedDetails.title}
                sender={sharedDetails.sender_email}
                message={sharedDetails.message}
                expired={sharedDetails.expiredDate}
                _i={sharedDetails.id}
                shared={shared}
                genURL={genURL}
                setURL={setURL}
                setOpenFilePreviewModal={setOpenFilePreviewModal}
                setSelectedItem={setSelectedItem}
                fileLoading={fileLoading}
                setFileLoading={setFileLoading}
              />
            </>
          )}
          {fileExisted && fileProtected && (
            <div
              style={{
                // minHeight: '40vh',
                verticalAlign: 'middle',
                marginTop: '10vh',
                marginBottom: '10vh',
                textAlign: 'center',
              }}
            >
              <>
                {status !== 'success' && (
                  <>
                    <span style={{ fontWeight: 'bolder', fontSize: 20 }}>
                      {t('shared_folder_password')}
                    </span>
                    <br />
                    <br />
                    {error && (
                      <Alert
                        message={error}
                        type="error"
                        closable
                        style={{ width: 300, margin: 'auto' }}
                      />
                    )}
                    <Input.Password
                      id="password"
                      style={{ width: 300 }}
                      value={password}
                      placeholder={t('sharing_form_password')}
                      className="mb-3 mt-2"
                      onChange={e => {
                        setPassword(e.target.value)
                        setError('')
                      }}
                    />
                  </>
                )}
              </>
              <br />
              <br />
              <Button
                type="primary"
                shape="round"
                // icon={<DownloadOutlined />}
                size="large"
                onClick={handleVerifySharedPassword}
                disabled={loading}
              >
                {t('shared_folder_enter')}
              </Button>
            </div>
          )}
          {(!fileExisted || dataSource.length === 0) && !fileProtected && (
            <Result status="404" title={t('file_deleted')} />
          )}
          {redirect ? <Navigate to="/" /> : ''}
        </div>
      </div>
      {/* <ModalContainer /> */}
    </div>
  )
}

export default SharedFolder
