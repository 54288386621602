import { useTranslation } from 'react-i18next'
import { Breadcrumb, Button, Menu, Tooltip } from 'antd'
import _path from 'path'
import React from 'react'
import { getSourceByName, truncateString } from 'utils'

import { useDispatch, useSelector } from 'react-redux'
import { routerSelector } from 'redux/selectors'
import { setCurrentFolder } from 'features/router/routerSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRouter } from 'hooks'
import { MY_FOLDER, MY_FOLDER_URL, SHARED_FOLDER, SHARED_FOLDER_URL } from 'config/constant'

function Breadcrumbs() {
  const { t } = useTranslation()
  const { router, navigateTo } = useRouter()
  const { currentFolder, inTrashBin, inSharedFolder, inFreebieFolder } = router
  let folders = currentFolder ? currentFolder.split('/') : []
  folders = folders.filter(el => el !== '')

  const rootBreadcrumb = () => {
    if (inTrashBin) return RootBreadcrumb(t('trash_bin'))
    if (inSharedFolder) return RootBreadcrumb(t('shared_items'))
    if (inFreebieFolder) return RootBreadcrumb(t('newsletter_material'))
    return RootBreadcrumb(t('my_folder'))
  }
  const handleSelectBreadcrumb = value => {
    navigateTo(encodeURIComponent(value))
  }

  const RootBreadcrumb = name => {
    const isRoot = currentFolder === '/' || currentFolder === ''
    return (
      <>
        <Breadcrumb.Item>
          <Button
            className={`breadcrumb-custom ${isRoot && 'breadcrumb-custom-active'}`}
            type="link"
            onClick={() => handleSelectBreadcrumb('')}
          >
            <span style={{ fontSize: 14 }}>{name}</span>
          </Button>
        </Breadcrumb.Item>
      </>
    )
  }
  const listBreadcrumb4 = () => {
    return folders.map((name, index) => (
      <Breadcrumb.Item key={name}>
        <Tooltip placement="topLeft" title={truncateString(name)} arrowPointAtCenter>
          <Button
            type="link"
            className={`breadcrumb-custom ${index === folders.length - 1 &&
              'breadcrumb-custom-active'}`}
            onClick={() => handleSelectBreadcrumb(folders.slice(0, index + 1).join('/'))}
          >
            <span style={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.45)' }}>&gt;</span>
            <span style={{ fontSize: 14 }}>{truncateString(name)}</span>
          </Button>
        </Tooltip>
      </Breadcrumb.Item>
    ))
  }
  const listBreadcrumbMin = () => {
    return (
      <>
        <Breadcrumb.Item
          overlay={
            <Menu>
              {folders.map((name, index) =>
                index < folders.length - 1 ? (
                  <Menu.Item key={name}>
                    <Tooltip placement="topLeft" title={truncateString(name)} arrowPointAtCenter>
                      <Button
                        style={{ padding: 0, color: '#495057', height: 18 }}
                        type="link"
                        onClick={() =>
                          handleSelectBreadcrumb(folders.slice(0, index + 1).join('/'))
                        }
                      >
                        <span
                          title={truncateString(name)}
                          className="ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal"
                        >
                          <span className="ant-tree-iconEle ant-tree-icon__customize">
                            <span role="img" aria-label="folder" className="anticon anticon-folder">
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="folder"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32zM840 768H184V256h188.5l119.6 114.4H840V768z" />
                              </svg>
                            </span>
                          </span>
                          <span className="ant-tree-title"> {truncateString(name)}</span>
                        </span>

                        {/* <span style={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.45)' }}>&gt;</span> */}
                      </Button>
                    </Tooltip>
                  </Menu.Item>
                ) : null,
              )}
            </Menu>
          }
        >
          <span style={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.45)' }}>&gt;</span>
          <span
            style={{
              fontSize: 25,
              lineHeight: '24px',
            }}
          >
            ...
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Tooltip placement="topLeft" title={folders[folders.length - 1]} arrowPointAtCenter>
            <Button
              style={{ padding: '0px 4px' }}
              type="link"
              onClick={() => handleSelectBreadcrumb(folders.slice(0, folders.length - 1).join('/'))}
            >
              <span style={{ margin: '0 8px', color: 'rgba(0, 0, 0, 0.45)' }}>&gt;</span>
              <span className="breadcrumb-custom-active"> {folders[folders.length - 1]}</span>
            </Button>
          </Tooltip>
        </Breadcrumb.Item>
      </>
    )
  }

  const listBreadcrumb = () => {
    if (folders.length > 0) return folders.length < 4 ? listBreadcrumb4() : listBreadcrumbMin()
    return <></>
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <Breadcrumb separator="">
          {rootBreadcrumb()}
          {listBreadcrumb()}
        </Breadcrumb>
      </div>
    </>
  )
}

export default Breadcrumbs
