import { Table } from 'antd'
import { EmptyComponent } from 'components'
import React from 'react'
import { useHandleColumns, useHandleMouse } from 'hooks'
import { useSelector } from 'react-redux'
import { fileSelectorWithFilter, managerSelector } from 'redux/selectors'
import { Row } from './Row'

function ListView() {
  const { filterResult, loading } = useSelector(fileSelectorWithFilter)
  const { selected, isDrag } = useSelector(managerSelector)
  const { columns } = useHandleColumns()
  const { handleClick, handleContextMenu, handleDoubleClick, handleDragStart } = useHandleMouse()

  return (
    <div>
      <Table
        expandable={{
          expandIcon: () => null,
        }}
        loading={loading}
        dataSource={filterResult}
        columns={columns}
        showSorterTooltip={false}
        locale={{
          // triggerDesc: t('grid_view_sort_desc'),
          // triggerAsc: t('grid_view_sort_asc'),
          // cancelSort: t('grid_view_cancel_sort'),
          emptyText: <EmptyComponent />,
        }}
        pagination={{
          showSizeChanger: false,
          pageSize: 15,
        }}
        components={{
          body: {
            row: Row,
          },
        }}
        onRow={(record, index) => {
          const active = selected.includes(record.key) ? 'true' : undefined
          const isdrag = isDrag ? 'true' : undefined
          return {
            record,
            active,
            isdrag,
            onClick: () => {
              handleClick(record)
            },
            onContextMenu: () => {
              handleContextMenu(record)
            },
            onDoubleClick: () => {
              handleDoubleClick(record)
            },
            onDragStart: () => handleDragStart(record),
          }
        }}
      />
    </div>
  )
}

export default ListView
