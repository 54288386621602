import React from 'react'
import { DeleteOutlined, FileSyncOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import ViewType from 'features/view-by/type/ViewType'
import { useSelector } from 'react-redux'
import { managerSelector, selectedFileSelector } from 'redux/selectors'
import { useHandleAction } from 'hooks'

const TrashBinToolBar = () => {
  const { t } = useTranslation()
  const { notSelected } = useSelector(managerSelector)
  const { handleConfirmRestores, handleConfirmDeleteForever } = useHandleAction()

  return (
    <div
      className="d-flex justify-content-between px-3"
      style={{ borderBottom: 'rgb(221, 221, 221) 1px solid', padding: '16px 0px' }}
    >
      <div style={{ alignSelf: 'center' }}>
        <Button
          className="ant-btn mr-3"
          onClick={handleConfirmDeleteForever}
          disabled={notSelected}
          style={{ fontWeight: 'bold', fontSize: 14 }}
        >
          <DeleteOutlined style={{ fontSize: 19, verticalAlign: 'middle' }} />
          {t('delete_forever')}
        </Button>
        <Button
          onClick={handleConfirmRestores}
          className="ant-btn mr-3"
          disabled={notSelected}
          style={{ fontWeight: 'bold', fontSize: 14 }}
        >
          <FileSyncOutlined style={{ fontSize: 19, verticalAlign: 'middle' }} />
          {t('restore_file')}
        </Button>
      </div>
      <ViewType />
    </div>
  )
}

export default TrashBinToolBar
