import React from 'react'
import { Card } from 'antd'
import { truncate, getSourceByName } from 'utils/common.util'

const { Meta } = Card

const CardsDragPreview = ({ selectedFiles }) => {
  return selectedFiles.length <= 3 ? (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 100px)',
        gridGap: '10px',
        textAlign: 'center',
        verticalAlign: 'bottom',
      }}
    >
      {selectedFiles.map((card, i) => {
        return (
          <center key={card.key}>
            <div
              key={card.key}
              style={{
                border: 0,
              }}
            >
              <Card
                bordered={false}
                hoverable
                style={{
                  // height: 170,
                  backgroundColor: 'transparent',
                  marginTop: card.type === 'folder' ? 25 : 0,
                }}
                cover={
                  <img
                    src={
                      card.type === 'folder'
                        ? getSourceByName('folder')
                        : getSourceByName(card.name)
                    }
                    alt=""
                    style={{ width: 50 }}
                  />
                }
              >
                <Meta
                  description={<span style={{ color: '#ffffff' }}>{truncate(card.name, 15)}</span>}
                  style={{ width: 100, padding: 5, borderRadius: 3, background: '#a65a5c' }}
                />
              </Card>
            </div>
            {i % 3 === 0 && <></>}
          </center>
        )
      })}
    </div>
  ) : (
    <div>
      {selectedFiles.slice(0, 3).map((card, i) => {
        return (
          <div
            key={card.key}
            style={{
              position: 'absolute',
              border: 0,
              zIndex: selectedFiles.length - i,
              transform: `rotateZ(${-i * 6}deg) translateX(${i * 2}px) translateY(${i * 2}px)`,
            }}
          >
            <Card
              bordered={false}
              hoverable
              style={{
                // height: 100,
                backgroundColor: 'transparent',
                marginTop: card.type === 'folder' ? 20 : 0,
              }}
              cover={
                <img
                  src={
                    card.type === 'folder' ? getSourceByName('folder') : getSourceByName(card.name)
                  }
                  alt=""
                  style={{ width: 50 }}
                />
              }
            />
          </div>
        )
      })}
      <div
        style={{
          position: 'absolute',
          zIndex: 10000,
          textAlign: 'center',
          width: 20,
          height: 20,
          borderRadius: '50%',
          color: '#fff',
          background: '#200080',
          left: 40,
        }}
      >
        {selectedFiles.length}
      </div>
    </div>
  )
}

export default CardsDragPreview
