import { LoadingOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function LoadingPreview({ open, onCancel }) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        textAlign: 'center',
        height: '140px',
        width: '400px',
        borderRadius: 12,
        color: 'white',
        padding: '20px',
        position: 'absolute',
        top: '40%',
        left: 'calc(50vw - 200px)',
      }}
    >
      <div style={{ height: '100vh', textAlign: 'center' }}>
        <LoadingOutlined style={{ fontSize: 32, color: 'white' }} spin />
      </div>
    </div>
  )
}

export default LoadingPreview
