/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Button, Modal, Row, Col, Checkbox } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { getCookie } from 'utils'

class ModalNoti extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      isSmallScreen: window.innerWidth <= 820,
      screenWidth: window.innerWidth <= 820 ? window.innerWidth - 20 : 650,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    console.log(window.innerWidth)
    this.setState({
      isSmallScreen: window.innerWidth <= 820,
      screenWidth: window.innerWidth <= 820 ? window.innerWidth - 20 : 650,
    })
  }

  render() {
    const { isVisible, toggle } = this.props
    const { checked, isSmallScreen, screenWidth } = this.state

    const handleGotoLink = e => {
      window.open(
        'https://www.design-ac.net/nenga?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_nenga_20241227',
        '_blank',
        'noopener,noreferrer',
      )
      e.stopPropagation()
    }

    const onChange = e => {
      if (e.target.checked) {
        this.setState({ checked: true })
      }
    }

    const onClose = () => {
      if (checked) {
        localStorage.setItem(
          'neverShowNoti',
          `${getCookie('sessionUserId')}-${localStorage.getItem('uuid')}`,
        )
      }
      toggle()
    }

    const handleAnchorClick = e => {
      e.stopPropagation()
    }

    return (
      <Modal
        open={isVisible}
        title=""
        width={screenWidth}
        className="transparent-modal"
        onOk={() => toggle()}
        onCancel={onClose}
        style={{
          paddingTop: '2%',
          borderRadius: '10px',
          cursor: 'pointer',
          backgroundColor: 'transparent',
        }}
        footer={null}
        bodyStyle={{ padding: 0, borderRadius: '10px', backgroundColor: 'transparent' }}
        closeIcon={null}
      >
        <Row
          style={{
            width: `${screenWidth}px`,
            position: 'relative',
            borderRadius: '10px',
            backgroundColor: 'transparent',
          }}
          onClick={() => handleGotoLink()}
        >
          <img
            alt="survey"
            src="/resources/images/designac-newyear-template-banner.png"
            style={{
              width: isSmallScreen ? `${screenWidth}px` : '650px',
            }}
          />
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '0',
              padding: '0',
            }}
          >
            <Checkbox
              style={{
                color: '#444',
                fontSize: isSmallScreen ? '10px' : '14px',
                marginTop: isSmallScreen ? '-8%' : '-45px',
              }}
              onChange={onChange}
              onClick={handleAnchorClick}
            >
              この案内を次回から表示しない
            </Checkbox>
          </Col>
          {/* <Col span={24}>
            <Row>
              <img
                alt="survey"
                src="/resources/images/bgModalNoti.svg"
                style={{
                  width: '100%',
                  height: '22px',
                  marginTop: isSmallScreen ? '-7px' : '-5px',
                }}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Row style={{ padding: '10px 0 10px 20px' }}>
              <img
                alt="survey"
                src="/resources/images/designAC_logo.svg"
                style={{ height: isSmallScreen ? '25px' : '50px' }}
              />
              <span
                style={{
                  fontSize: isSmallScreen ? '12px' : '24px',
                  fontWeight: 700,
                  marginTop: isSmallScreen ? '5px' : '11px',
                  color: '#44444',
                }}
              >
                は
              </span>
              <span
                style={{
                  marginLeft: isSmallScreen ? '5px' : '10px',
                  fontSize: isSmallScreen ? '16px' : '26px',
                  fontWeight: 700,
                  marginTop: isSmallScreen ? '0' : '7px',
                  color: '#fd5920',
                }}
              >
                ビジネス向け
              </span>
              <span
                style={{
                  fontSize: isSmallScreen ? '12px' : '24px',
                  fontWeight: 700,
                  marginTop: isSmallScreen ? '5px' : '12px',
                  color: '#44444',
                }}
              >
                テンプレートも豊富
              </span>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <img
                alt="survey"
                src="/resources/images/designac-newyear-template-banner.png"
                style={{
                  width: isSmallScreen ? `${screenWidth * 0.7}px` : '468px',
                  marginLeft: '20px',
                }}
              />
            </Row>
          </Col>
          <Col span={24} style={{ padding: '20px 10px' }}>
            <Button
              style={{
                padding: isSmallScreen ? '6px' : '12px',
                fontSize: isSmallScreen ? '12px' : '16px',
                verticalAlign: 'center',
                height: isSmallScreen ? '40px' : '56px',
                width: isSmallScreen ? `${screenWidth * 0.6}px` : '335px',
                color: 'white',
                backgroundColor: '#00868B',
                borderColor: '#00868B',
                marginLeft: isSmallScreen ? '30px' : '70px',
                borderRadius: '8px',
              }}
              type="primary"
              className="ant-btn-link-modal"
              onClick={handleGotoLink}
            >
              テンプレートを探しに行く
              <img
                alt="survey"
                src="/resources/images/vectorButn.svg"
                style={{
                  height: isSmallScreen ? '20px' : '30px',
                  width: isSmallScreen ? '20px' : '30px',
                  marginLeft: '16px',
                }}
              />
            </Button>
          </Col>
          <Col
            span={24}
            style={{
              backgroundColor: '#404040',
              color: '#fff',
              padding: isSmallScreen ? '10px 10px' : '0px 10px',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              height: isSmallScreen ? '183px' : '144px',
            }}
          >
            {!isSmallScreen && (
              <Row>
                <Col span={8} style={{ textAlign: 'left !important' }}>
                  <ul>
                    <li style={{ marginTop: '16px' }}>
                      <a
                        href="https://www.design-ac.net/free-template/invoice?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-invoice_20241204"
                        target="_blank"
                        className="link-style"
                        onClick={handleAnchorClick}
                      >
                        請求書
                      </a>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <a
                        href="https://www.design-ac.net/free-template/receipt?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-receipt_20241204"
                        target="_blank"
                        className="link-style"
                        onClick={handleAnchorClick}
                      >
                        領収書
                      </a>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <a
                        href="https://www.design-ac.net/free-template/delivery-slip?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-delivery-slip_20241204"
                        target="_blank"
                        className="link-style"
                        onClick={handleAnchorClick}
                      >
                        納品書
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col span={8} style={{ textAlign: 'left !important' }}>
                  <ul>
                    <li style={{ marginTop: '16px' }}>
                      <a
                        href="https://www.design-ac.net/free-template/new-year-card?page=1&keyword=%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9&utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-new-year-card_20241204"
                        target="_blank"
                        className="link-style"
                        onClick={handleAnchorClick}
                      >
                        ビジネス向け年賀状
                      </a>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <a
                        href="https://www.design-ac.net/free-template/a4?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-a4_20241204"
                        target="_blank"
                        className="link-style"
                        onClick={handleAnchorClick}
                      >
                        A4文書
                      </a>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <a
                        href="https://www.design-ac.net/free-template/business-card?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-business-card_20241204"
                        target="_blank"
                        className="link-style"
                        onClick={handleAnchorClick}
                      >
                        名刺
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            )}
            {isSmallScreen && (
              <Row>
                <Col span={12} style={{ padding: '5px' }}>
                  <a
                    href="https://www.design-ac.net/free-template/invoice?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-invoice_20241204"
                    target="_blank"
                    className="link-style-button"
                    onClick={handleAnchorClick}
                  >
                    請求書
                  </a>
                </Col>
                <Col span={12} style={{ padding: '5px' }}>
                  <a
                    href="https://www.design-ac.net/free-template/new-year-card?page=1&keyword=%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9&utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-new-year-card_20241204"
                    target="_blank"
                    className="link-style-button"
                    onClick={handleAnchorClick}
                  >
                    ビジネス向け年賀状
                  </a>
                </Col>
                <Col span={12} style={{ padding: '5px' }}>
                  <a
                    href="https://www.design-ac.net/free-template/receipt?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-receipt_20241204"
                    target="_blank"
                    className="link-style-button"
                    onClick={handleAnchorClick}
                  >
                    領収書
                  </a>
                </Col>
                <Col span={12} style={{ padding: '5px' }}>
                  <a
                    href="https://www.design-ac.net/free-template/a4?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-a4_20241204"
                    target="_blank"
                    className="link-style-button"
                    onClick={handleAnchorClick}
                  >
                    A4文書
                  </a>
                </Col>
                <Col span={12} style={{ padding: '5px' }}>
                  <a
                    href="https://www.design-ac.net/free-template/delivery-slip?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-delivery-slip_20241204"
                    target="_blank"
                    className="link-style-button"
                    onClick={handleAnchorClick}
                  >
                    納品書
                  </a>
                </Col>
                <Col span={12} style={{ padding: '5px' }}>
                  <a
                    href="https://www.design-ac.net/free-template/business-card?utm_source=ac_sites&utm_medium=referral&utm_campaign=design_ac_quest&utm_id=acsite_popup_data-business-card_20241204"
                    target="_blank"
                    className="link-style-button"
                    onClick={handleAnchorClick}
                  >
                    名刺
                  </a>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: isSmallScreen ? 'center' : 'flex-end',
                  marginTop: isSmallScreen ? '10px' : '0',
                  padding: '0',
                }}
              >
                <Checkbox
                  style={{
                    color: '#fff',
                    fontSize: '12px',
                    marginTop: isSmallScreen ? '6px' : '-10px',
                  }}
                  onChange={onChange}
                  onClick={handleAnchorClick}
                >
                  この案内を次回から表示しない
                </Checkbox>
              </Col>
            </Row>
          </Col>
          <div
            style={{
              zIndex: 9999,
              position: 'absolute',
              bottom: isSmallScreen ? '182px' : '100px',
              right: isSmallScreen ? '0px' : '20px',
            }}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <img
                alt="survey"
                src={
                  isSmallScreen
                    ? '/resources/images/mobile_ava.svg'
                    : '/resources/images/beekun.webp'
                }
                style={{
                  width: isSmallScreen ? screenWidth * 0.25 : 200,
                }}
              />
              {!isSmallScreen && (
                <p
                  style={{
                    zIndex: 9999,
                    position: 'absolute',
                    bottom: isSmallScreen ? '135px' : '27px',
                    right: isSmallScreen ? '45px' : '65px',
                    fontSize: '12px',
                    fontWeight: 700,
                    textAlign: 'center',
                    width: '142px',
                  }}
                >
                  テンプレート総数 <br />約
                  <span
                    style={{
                      color: '#ff711d',
                      fontSize: isSmallScreen ? '22px' : '24px',
                    }}
                  >
                    17,000
                  </span>
                  点
                </p>
              )}
            </div>
          </div> */}
        </Row>
        <style jsx="true" global="true">
          {`
            .ant-btn-link-modal {
              color: white !important;
            }
            .ant-btn-link-modal:hover {
              background-color: #1caeb4 !important;
              border-color: #1caeb4 !important;
              color: white !important;
            }
            .link-style {
              color: #fff;
              text-decoration: none;
              font-size: 16px;
              margin-top: 5px;
            }
            a.link-style:hover {
              text-decoration: underline;
              color: #fff;
            }
            a:active,
            a:focus {
              text-decoration: underline;
              color: #000;
            }
            .modalNoti {
              padding: 0px !important;
            }
            .link-style-button {
              display: block;
              width: 100%;
              background-color: #fff;
              color: #000;
              text-decoration: none;
              padding: 5px 0px;
              border: 1px solid #000;
              border-radius: 10px;
              text-align: center;
              font-size: 12px;
              cursor: pointer;
              transition: all 0.3s ease;
            }
            .ant-modal-content {
              border-radius: 10px !important;
            }
            .ant-modal-close {
              top: 55px !important;
              right: 30px !important;
            }
            .transparent-modal .ant-modal-content {
              background-color: transparent; /* Làm nền trong suốt */
              box-shadow: none; /* Loại bỏ bóng đổ (nếu cần) */
              border: none; /* Loại bỏ viền (nếu cần) */
            }

            .transparent-modal .ant-modal-header {
              background-color: transparent; /* Làm nền tiêu đề trong suốt */
              color: #000; /* Đặt màu chữ nếu cần */
            }

            .transparent-modal .ant-modal-close-x {
              display: none;
            }
          `}
        </style>
      </Modal>
    )
  }
}

export default ModalNoti
