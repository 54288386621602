import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
// import PDFViewer from 'mgr-pdf-viewer-react'
import Button from 'components/Button'
import { Document, Outline, Page, pdfjs } from 'react-pdf'
import {
  LeftOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons'
import { useResize } from 'hooks'
import { isUgly, notUgly } from 'utils'
import { Input } from 'antd'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export function Pdf({ URL }) {
  const [numPages, setNumPages] = useState(null)
  const [inputValue, setInputValue] = useState(1)
  const [loading, setLoading] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [scaleNumber, setScaleNumber] = useState(1)
  const pageToLoadmore = useRef(10)
  const pageRefs = useRef(null)
  const loadMore = useRef(false)
  const { previewSize } = useResize()
  // const [inputwidth, setinputWidth] = useState(50)

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    // setLoading(true)
    setNumPages(nextNumPages)
  }

  function onRenderSuccess(a) {
    const maxPage = numPages > pageToLoadmore.current ? pageToLoadmore.current : numPages
    if (a.pageIndex === maxPage - 1) {
      setLoading(false)
    }
    // if (a.pageIndex === numPages - 1) {
    //   setLoading(false)
    // }
  }

  const isElementInViewport = el => {
    const rect = el.getBoundingClientRect()
    return rect.bottom + 10 >= previewSize.top && rect.top <= previewSize.height
  }

  const onScroll = () => {
    if (isUgly(pageRefs.current) || loadMore.current) {
      loadMore.current = false
      pageRefs.current = document.querySelectorAll('.react-pdf__Page')
    }
    const inView = Array.from(pageRefs.current).filter(ref => {
      return isElementInViewport(ref)
    })
    if (inView && inView.length > 0) {
      const currentPageStr = inView[0].getAttribute('data-page-number')
      if (notUgly(currentPageStr)) {
        // eslint-disable-next-line radix
        const currentPage = parseInt(currentPageStr)
        setPageNumber(currentPage)
        const isMore1 = (currentPage + 5) % pageToLoadmore.current === 0
        const isMore2 = (currentPage + 6) % pageToLoadmore.current === 0
        if (isMore1 || isMore2) {
          loadMore.current = true
          pageToLoadmore.current += 10
        }
      }
    }
  }

  useEffect(() => {
    document.getElementsByClassName('react-pdf__Document')[0].addEventListener('scroll', onScroll)
    // return () => {
    //   document
    //     .getElementsByClassName('react-pdf__Document')[0]
    //     .removeEventListener('scroll', onScroll)
    // }
  }, [])

  const handleChange = e => {
    const { value } = e.target.value
    if (value) setInputValue(value)
  }

  function changeScale(offset) {
    setScaleNumber(prevScaleNumber => prevScaleNumber + offset)
  }
  function zoomIn() {
    changeScale(0.1)
  }

  function zoomOut() {
    changeScale(-0.1)
  }

  const renderPage = () => {
    const maxPage = numPages > pageToLoadmore.current ? pageToLoadmore.current : numPages
    return Array.from(new Array(maxPage), (el, index) => (
      <Fragment key={`page_${index + 1}`}>
        <Page
          scale={scaleNumber}
          onRenderSuccess={onRenderSuccess}
          height={previewSize.height}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
        <div style={{ height: 10 }}> </div>
      </Fragment>
    ))
  }

  return (
    <>
      {loading && (
        <div
          className="vertically-center"
          style={{
            zIndex: 11111111,
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '100vh',
            width: '100vw',
          }}
        >
          <div
            style={{
              zIndex: 11111111,
              textAlign: 'center',
              height: '140px',
              width: '400px',
              borderRadius: 12,
              padding: '20px',
              position: 'absolute',
              top: '40%',
              left: 'calc(50vw - 200px)',
            }}
          >
            <LoadingOutlined style={{ fontSize: 28, color: 'black' }} spin />
          </div>
        </div>
      )}
      <div>
        <Document file={URL} onLoadSuccess={onDocumentLoadSuccess}>
          {/* <Page pageNumber={3} /> */}
          {renderPage()}
          {/* <Page pageNumber={pageNumber} scale={scaleNumber} /> */}
        </Document>

        {/* <div style={{ color: 'white' }}>
        <Button onClick={previousPage}>Previous</Button>
        <Button onClick={nextPage}>Next</Button>
      </div> */}
      </div>
      <div className="react-pdf__Navigation">
        <div className="react-pdf__Navigation_Content">
          <div className="react-pdf__Navigation_Paging">
            <div style={{ marginRight: 10 }} className="vertically-center">
              ページ
            </div>
            <div className="vertically-center">{pageNumber}</div>
            {/* <Input
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ width: inputwidth }}
              className="react-pdf__Navigation_Paging_Input vertically-center"
              value={inputValue}
            /> */}

            <div style={{ width: 20, textAlign: 'center' }} className="vertically-center">
              /
            </div>
            <div className="vertically-center">{numPages}</div>
          </div>
          <div
            style={{ borderRight: '1px solid lightgrey', marginLeft: 10 }}
            className="vertically-center"
          >
            {' '}
          </div>
          <div className="react-pdf__Navigation_Action">
            <a className="_action vertically-center" aria-hidden onClick={zoomOut}>
              <MinusOutlined className="_icon" />
            </a>
            <div className="vertically-center">{Math.round(scaleNumber * 100)}%</div>
            <a className="_action vertically-center" aria-hidden onClick={zoomIn}>
              <PlusOutlined className="_icon" />
            </a>
          </div>
        </div>
      </div>
    </>
  )
  // return (
  //   <PDFViewer
  //     document={{
  //       url: URL,
  //     }}
  //     loader={
  //       <div
  //         style={{
  //           textAlign: 'center',
  //           height: '140px',
  //           width: '400px',
  //           borderRadius: 12,
  //           color: 'white',
  //           padding: '20px',
  //           position: 'absolute',
  //           top: '40%',
  //           left: 'calc(50vw - 200px)',
  //         }}
  //       >
  //         <div style={{ height: '100vh', textAlign: 'center' }}>
  //           <LoadingOutlined style={{ fontSize: 32, color: 'white' }} spin />
  //         </div>
  //       </div>
  //     }
  //     // loader={
  //     //   <div style={{ height: '100vh', textAlign: 'center' }}>
  //     //     <LoadingOutlined style={{ fontSize: 32, color: 'white' }} spin />
  //     //   </div>
  //     // }
  //     scale={scale}
  //     css="customViewer"
  //     navigation={{
  //       elements: {
  //         previousPageBtn: CustomPrevButton,
  //         nextPageBtn: CustomNextButton,
  //         pages: CustomPages,
  //       },
  //     }}
  //   />
  // )
}
// function FakePage() {
//   return (
//     <div style={{ display: 'none' }}>
//       {Array.from(new Array(this.props.pages), (el, index) => (
//         <Page
//           key={`page_${index + 1}`}
//           width={this.props.width}
//           className="pdfPage"
//           renderMode="svg"
//           pageNumber={index + 1}
//         />
//       ))}
//     </div>
//   )
// }
export default Pdf
