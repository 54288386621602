import { MODAL_TYPES } from 'config/constant'

import DuplicateAlertModal from './alert-modal/DuplicateAlertModal'
import MailMagaAlertModal from './alert-modal/MailMagaAlertModal'
import RestoreAlertModal from './alert-modal/RestoreAlertModal'
import TrialAlertModal from './alert-modal/TrialAlertModal'
import FilePreviewModal from './preview-modal/FilePreview'
import AddFolderModal from './form-modal/AddFolderModal'
import ShareModal from './share-modal/ShareModal'
import RenameModal from './form-modal/RenameModal'
import ShareToAccountModal from './share-modal/ShareToAccountModal'
import ShareModalDropzone from './share-modal/ShareModalDropzone'

const Modal = {
  [MODAL_TYPES.duplicate]: DuplicateAlertModal,
  [MODAL_TYPES.mailMaga]: MailMagaAlertModal,
  [MODAL_TYPES.restore]: RestoreAlertModal,
  [MODAL_TYPES.trial]: TrialAlertModal,
  [MODAL_TYPES.filePreview]: FilePreviewModal,
  [MODAL_TYPES.addFolder]: AddFolderModal,
  [MODAL_TYPES.rename]: RenameModal,

  [MODAL_TYPES.addFolder]: AddFolderModal,
  [MODAL_TYPES.shareToMailDropzone]: ShareModalDropzone,
  [MODAL_TYPES.shareToMail]: ShareModal,
  [MODAL_TYPES.shareToUser]: ShareToAccountModal,
}

export default Modal
