/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation, withTranslation } from 'react-i18next'
import { DownloadOutlined, FileOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Alert, Result, Progress } from 'antd'
import { addHours, parse, format } from 'date-fns'

import {
  checkPasswordShareFile,
  downloadFile,
  updateDownloadTime,
  getFileInformation,
  getFileUrl,
} from 'services/upload'
import { openNotificationWithIcon, truncateString } from 'utils/common.util'

function DownloadFile() {
  const { t } = useTranslation()
  const { metadata } = useParams()
  const [redirect, setRedirect] = useState(false)
  const [shared, setShared] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')
  const [percent, setPercent] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [fileExisted, setFileExisted] = useState(true)
  const [fileDetails, setFIleDetails] = useState({
    LastModified: format(addHours(new Date(), 24), 'yyyy-MM-dd hh:mm:ss'),
    Size: '',
    Name: '',
    Path: null,
    Downloaded: 0,
    Protected: false,
    ExpiredDate: format(addHours(new Date(), 24), 'yyyy-MM-dd hh:mm:ss'),
  })

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (metadata) {
      setLoading(true)
      setShared(metadata.length > 30 ? metadata : `${metadata.replace(/\&/g, '/')}=`)
      const data = {
        shared: metadata.length > 30 ? metadata : `${metadata.replace(/\&/g, '/')}=`,
      }
      getFileInformation(data).then(fetchFilesDetails => {
        setLoading(false)
        if (!fetchFilesDetails.fileExisted) {
          setFileExisted(false)
          setTimeout(() => setRedirect(true), 5000)
          return false
        }
        setFileExisted(true)
        console.log(fetchFilesDetails.fileDetails[0])
        setFIleDetails(fetchFilesDetails.fileDetails[0])
        return true
      })
    }
  }, [])

  const handlePercent = currentPercent => {
    setPercent(currentPercent)
  }

  const handleError = () => {
    openNotificationWithIcon('error', t('download_files_fail'))
  }

  const handleSuccess = () => {
    openNotificationWithIcon('success', t('file_downloaded'))
  }

  const processDownloadFile = async () => {
    const data = {
      url: await getFileUrl({ shared }),
      name: fileDetails.Name,
    }
    await downloadFile(data, handlePercent, handleSuccess, handleError)
      .then(() => {
        setStatus('success')
        // openNotificationWithIcon('info', t('download_files_success'))
        processUpdateDownloadTime()
        setLoadingDownload(false)
      })
      .catch(() => {
        openNotificationWithIcon('error', t('download_files_fail'))
      })
      .finally(() => {
        setPassword('')
      })
  }

  const processUpdateDownloadTime = () => {
    updateDownloadTime({ shared, type: 'file' })
  }

  const handleDownloadFile = async () => {
    if (loadingDownload || (status === 'success' && percent < 100)) {
      return
    }
    setLoadingDownload(true)
    setPercent(0)
    if (+fileDetails.Downloaded >= 20) {
      openNotificationWithIcon('error', t('maximum_downloaded'))
      setLoadingDownload(false)
      return
    }
    if (fileDetails.Protected) {
      const data = {
        shared,
        password,
      }
      const result = await checkPasswordShareFile(data)
      if (result.checkPassword) {
        processDownloadFile()
        return
      }
      setError(t('invalid_password'))
      setLoadingDownload(false)
      return
    }
    processDownloadFile()
  }

  const getButtonText = () => {
    if (status === 'success' && percent < 100) {
      return t('downloading_button')
    }
    if (percent === 100) {
      return t('downloaded_button')
    }
    return t('download_button')
  }

  // render() {
  //   const {
  //     password,
  //     loading,
  //     error,
  //     status,
  //     fileExisted,
  //     fileDetails,
  //     percent,
  //     redirect,
  //   } = this.state

  const expiryTime = addHours(parse(fileDetails.ExpiredDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 9)
  const currentDate = new Date()

  if (loading)
    return (
      <div className="container">
        <div className="row justify-content-md-center mt-5">
          <div className="col" style={{ textAlign: 'center' }}>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        </div>
      </div>
    )
  return (
    <div className="container">
      <Helmet title={t('download_files_tittle')} />
      <div className="row justify-content-md-center mt-5">
        <div className="col">
          {fileExisted && currentDate < expiryTime ? (
            <div className="card">
              {/* <div className="card-header">
                  {t('ac_data')} {t('download_files_tittle')}
                </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <div className="card-text">
                      <div className="d-flex align-items-center mb-4">
                        <FileOutlined style={{ fontSize: 47, marginRight: 5 }} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                            {truncateString(fileDetails.Name, 40)}
                          </div>
                          <div>{fileDetails.Size}</div>
                        </div>
                      </div>
                      {/* <p>
                        <strong>{t('file_size')}:</strong> {fileDetails.Size}
                      </p> */}
                      <p>
                        <strong>{t('list_file_expired')}:</strong>{' '}
                        {format(expiryTime, 'yyyy-MM-dd HH:mm')}
                      </p>
                      <p>
                        <strong>{t('file_downloaded_times')}:</strong> {t('text_remain')}
                        {20 - +fileDetails.Downloaded} {t('text_times')}
                      </p>
                      {/* change text */}
                    </div>
                  </div>
                  <div className="col-md-4 d-flex flex-column  mx-auto justify-content-center">
                    {fileDetails.Protected && (
                      <React.Fragment>
                        {error && <Alert message={error} type="error" closable />}
                        {/* {status !== 'success' && ( */}
                        <React.Fragment>
                          <label htmlFor="password" />
                          {t('file_download_password')}
                          <Input.Password
                            id="password"
                            size="large"
                            value={password}
                            placeholder=""
                            className="mb-3 mt-2"
                            onChange={e => {
                              setPassword(e.target.value)
                              setError('')
                            }}
                          />
                        </React.Fragment>
                      </React.Fragment>
                    )}
                    {fileDetails.Path && (
                      <Button
                        style={{
                          margin: '10px 30px',
                          borderRadius: '0.25rem',
                          height: 55,
                          cursor: `${
                            loadingDownload || (status === 'success' && percent < 100)
                              ? 'default'
                              : 'pointer'
                          }`,
                        }}
                        type="primary"
                        // shape="round"
                        icon={<DownloadOutlined />}
                        // icon={percent === -1 ? 'download' : ''}
                        size="large"
                        loading={loadingDownload}
                        onClick={handleDownloadFile}
                        // disabled={loadingDownload}
                        // disabled={(file.isProtected && !password) || status === 'success'}
                      >
                        <span>{getButtonText()}</span>
                        {percent >= 0 && (
                          <Progress
                            type="circle"
                            className={percent < 100 ? 'white-text' : ''}
                            percent={percent}
                            width={30}
                            style={{ paddingLeft: '10px' }}
                            strokeColor={{
                              '0%': '#87d068',
                              '100%': '#108ee9',
                            }}
                          />
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="card-footer text-muted">
                  {formatDistance(addHours(expiryTime, 9), new Date(), { locale: ja })}
                </div> */}
            </div>
          ) : (
            <Result status="404" title={t('file_deleted')} />
          )}
          {redirect ? <Navigate to="/" /> : ''}
        </div>
      </div>
    </div>
  )
}

export default DownloadFile
