import { createAsyncThunk } from '@reduxjs/toolkit'
import { DEFAULT_FOLDER_PATH, DEFAULT_FREEBIE_PATH } from 'config/constant'
import _path from 'path'
import {
  getDeletedFiles,
  getFilesAndFolders,
  getFilesAndFoldersFreebies,
  getSharedFiles,
  validateShareUUID,
} from 'services/upload'
import { removeSlash } from 'utils'
import { setPermissionEdit } from '../managerSlice'

export const getFilesThunk = createAsyncThunk(
  'files/saved',
  async ({ currentFolder }, { rejectWithValue }) => {
    try {
      const params = {
        dirName: currentFolder,
        compress: 'gzip',
      }
      const result = await getFilesAndFolders(params)
      return result
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)
export const getFreebiesThunk = createAsyncThunk(
  'files/freebie',
  async ({ currentFolder }, { rejectWithValue }) => {
    try {
      if (currentFolder === DEFAULT_FOLDER_PATH) {
        localStorage.removeItem('origin_uuid')
      }
      const params = {
        dirName: currentFolder,
        // inFreebie: true,
      }
      const result = await getFilesAndFoldersFreebies(params)
      return result
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)

export const getTrashBin = createAsyncThunk('files/deleted', async (args, { rejectWithValue }) => {
  try {
    const result = await getDeletedFiles()
    let data = []
    if (result.files && result.files.length > 0) {
      data = result.files.map((item, index) => ({
        key: item.Path + item.Name,
        lastModified: item.LastModified,
        fileName: item.Name,
        name: item.Name,
        url: item.Name,
        fileSize: item.Size,
        size: item.Size,
        path: item.Path,
        origin: item.Origin,
        type: 'file',
        id: index + 1,
      }))
    }
    let newFolders = []
    if (result.folders && result.folders.length > 0) {
      newFolders = result.folders.map((item, index) => {
        const el = removeSlash(item)
        const arrs = el.split('/')
        const name = arrs[arrs.length - 1]
        return {
          key: name,
          name,
          path: item,
          fileName: name,
          type: 'folder',
          origin: '',
        }
      })
    }

    return { files: data, folders: newFolders }
  } catch (error) {
    return rejectWithValue('error')
  }
})

export const getSharedFolder = createAsyncThunk(
  'shared/get',
  async ({ currentFolder }, { rejectWithValue, dispatch }) => {
    try {
      if (currentFolder === DEFAULT_FOLDER_PATH) {
        dispatch(setPermissionEdit(false))
        localStorage.removeItem('share_uuid')
        const result = await getSharedFiles()
        let files = []
        let folders = []
        if (result.files && result.files.length > 0) {
          files = result.files.map((item, index) => ({
            key: item.Path + item.Name,
            lastModified: item.lastModified,
            updated_at: item.updated_at,
            fileName: item.Name,
            name: item.Name,
            url: item.Name,
            fileSize: item.Size,
            path: item.Path,
            origin: item.Origin,
            type: 'file',
            share_uuid: item.share_uuid,
            id: index + 1,
          }))
        }
        if (result.folders && result.folders.length > 0) {
          folders = result.folders.map((item, index) => {
            item.key = item.path
            return item
          })
        }
        return { files, folders }
      }
      const params = {
        dirName: currentFolder,
        compress: 'gzip',
      }
      const { shareUUID, canEdit } = await validateShareUUID(currentFolder)
      const result = await getFilesAndFolders(params)
      dispatch(setPermissionEdit(canEdit))
      const files = result.files.map(el => {
        el.share_uuid = shareUUID
        return el
      })

      return { files, folders: result.folders }
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)
