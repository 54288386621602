import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BYTE, GB_BYTE, MB_BYTE } from 'config/constant'
import calChunk from 'utils/upload.util'
import _path from 'path'

import {
  completeUpload,
  getPartUrl,
  postSaveFiles,
  startUpload,
  startUploadFolder,
  updateUserSSOId,
} from 'services/upload'
import { setUploadedOk, updateAllChunks, updateAllPercents } from './uploaderSlice'

const { CancelToken } = axios
const source = CancelToken.source()

export const uploadFileThunk = createAsyncThunk(
  'upload/file',
  async ({ acceptedFiles, currentFolder }, { rejectWithValue, dispatch }) => {
    try {
      if (acceptedFiles.length) {
        let uploadData = acceptedFiles
          .map(item => ({
            folder: _path.dirname(item.path),
            name: _path.basename(item.path),
          }))
          .reduce((total, currentValue) => {
            total[currentValue.folder] = total[currentValue.folder] || []
            total[currentValue.folder].push(currentValue.name)
            return total
          }, {})
        uploadData = Object.keys(uploadData).map(key => ({
          path:
            key === '.'
              ? currentFolder
              : `${currentFolder === '/' ? '' : currentFolder}${key.slice(
                  key.slice(0, 1) === '/' ? 1 : 0,
                )}/`,
          filenames: uploadData[key],
          totalFiles: uploadData[key].length,
        }))
        await Promise.all(
          uploadData.map(async data => {
            let results = null
            const uuid = localStorage.getItem('uuid')
            if (uuid && uuid !== 'undefined' && uuid !== '' && uuid !== undefined) {
              results = await startUploadFolder(data, source.token)
            } else {
              results = await startUpload(data, source.token)
              const { id } = results
              localStorage.setItem('uuid', id)
            }
            const { uploadIds } = results
            if (localStorage.getItem('sessionUserId') !== null) {
              updateUserSSOId({ sso: localStorage.getItem('sessionUserId') })
            }
            // let itemsProcessed = 0
            const uploadedFilenames = []

            const acceptedFilesByFolders = acceptedFiles.filter(item => {
              const folderPath = _path.dirname(item.path)
              return (
                `${currentFolder === '/' ? '' : currentFolder}${folderPath.slice(
                  folderPath.slice(0, 1) === '/' || folderPath.slice(0, 1) === '.' ? 1 : 0,
                )}/`.replace('//', '/') === data.path
              )
            })
            await Promise.all(
              acceptedFilesByFolders.map(
                async (file, index, array) => {
                  const fileSize = file.size
                  // if (fileSize > 5 * GB_BYTE) {
                  //   uploadBigSizeNotify({ sources: dataSource })
                  // }
                  const { FILE_CHUNK_SIZE, NUM_CHUNKS } = calChunk({ fileSize })

                  const promisesArray = []
                  let start
                  let end
                  let blob
                  let uploadedSize = 0
                  dispatch(updateAllChunks({ name: file.name, percent: 0 }))
                  for (let i = 1; i < NUM_CHUNKS + 1; i += 1) {
                    start = (i - 1) * FILE_CHUNK_SIZE
                    end = i * FILE_CHUNK_SIZE
                    blob = i < NUM_CHUNKS ? file.slice(start, end) : file.slice(start)
                    // (1) Generate presigned URL for each part
                    // eslint-disable-next-line no-await-in-loop
                    const getUploadUrlResp = await getPartUrl(
                      {
                        fileName: file.name,
                        partNumber: i,
                        uploadId: uploadIds[index],
                        path: data.path,
                      },
                      source.token,
                    )
                    const { url } = getUploadUrlResp
                    // (2) Puts each file part into the storage server
                    const uploadResp = axios
                      .put(url, blob, {
                        headers: { 'Content-Type': file.type },
                        cancelToken: source.token,
                        // eslint-disable-next-line no-loop-func
                        onUploadProgress: progressEvent => {
                          const { loaded, total } = progressEvent
                          if (loaded === total) {
                            uploadedSize += loaded
                          }
                          const newPercent = (uploadedSize / fileSize) * 100
                          dispatch(
                            updateAllChunks({
                              name: file.name,
                              percent: newPercent,
                            }),
                          )
                        },
                      })
                      .catch(e => {
                        console.log(e)
                        // if (axios.isCancel(e)) {
                        //   setCanceled(canceleds => [...canceleds, allFileKeys[index]])
                        //   throw e
                        // }
                      })
                    promisesArray.push(uploadResp)
                  }

                  const resolvedArray = await Promise.all(promisesArray)
                  const uploadPartsArray = []
                  resolvedArray.forEach((resolvedPromise, i) => {
                    uploadPartsArray.push({
                      ETag: resolvedPromise.headers.etag.replace(/^"(.*)"$/, '$1'),
                      PartNumber: i + 1,
                    })
                  })

                  // (3) Calls the CompleteMultipartUpload endpoint in the backend server
                  const uploadStatus = false
                  const completeUploadResp = await completeUpload(
                    {
                      fileName: file.name,
                      path: data.path,
                      parts: uploadPartsArray,
                      uploadId: uploadIds[index],
                    },
                    source.token,
                  )
                  if (completeUploadResp.status === 200) {
                    // itemsProcessed += 1
                    // uploadStatus = true
                    uploadedFilenames.push(file.name)
                  } else if (completeUploadResp.status === 555) {
                    // setCanceled(canceleds => [...canceleds, allFileKeys[index]])
                    // uploadStatus = false
                  }
                  if (uploadStatus) {
                    // dataSource[index] = {
                    // ...dataSource[index],
                    // url: uploadIds[index],
                  }
                  // setList(dataSource)
                },
                // if (itemsProcessed === array.length) {
                //   setIsLoading(false)
                // }
              ),
            )
            const saveData = {
              filenames: uploadedFilenames,
              path: data.path,
            }
            await postSaveFiles(saveData)
            dispatch(setUploadedOk(uploadedFilenames))
          }),
        )
        console.log('upload ok')
      }
      return rejectWithValue('ok')
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)

export const uploadFile = files => dispatch => {}
