import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  DEFAULT_FOLDER_PATH,
  FREEBIE_FOLDER,
  MY_FOLDER,
  SHARED_FOLDER,
  TRASH_BIN,
  USER_TYPE,
  VIEW,
} from 'config/constant'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector, viewSelector } from 'redux/selectors'
import { clearSelect, setPermissionEdit } from 'features/manager/managerSlice'
import _path from 'path'
import useRouter from 'hooks/useRouter'
import FileCardDragLayer from 'components/drags/FileCardDragLayer'
import { CustomDragLayer } from 'components/drags/CustomDragLayer'
import ContextMenu from 'components/context-menu/ContextMenu'
import {
  getFilesThunk,
  getFreebiesThunk,
  getSharedFolder,
  getTrashBin,
} from 'features/manager/file/fileActions'
import { MouseSelection, useDragToSelect, useUploader } from 'hooks'
import GridView from './components/grid-view'
import ListView from './components/list-view'

function MainView() {
  const dispatch = useDispatch()
  const { viewType } = useSelector(viewSelector)
  const { router, location } = useRouter()
  const { getRootProps, uploadHolder } = useUploader()
  const { userInfo } = useSelector(userSelector)
  const { dragContainer } = useDragToSelect()

  useEffect(() => {
    const { modeFolder, currentFolder } = router

    dispatch(clearSelect())
    if (userInfo.isAuth === false && userInfo.type === USER_TYPE.LOGIN) return
    switch (modeFolder) {
      case MY_FOLDER:
        dispatch(getFilesThunk({ currentFolder }))
        break
      case FREEBIE_FOLDER:
        dispatch(getFreebiesThunk({ currentFolder }))
        break
      case SHARED_FOLDER:
        dispatch(getSharedFolder({ currentFolder }))
        break
      case TRASH_BIN:
        dispatch(getTrashBin())
        break
      default:
    }
  }, [location, userInfo])

  return (
    // <div {...getRootProps()}  style={{ minHeight: '60vh' }}>
    <div id="main-view" className="main-view" {...getRootProps()}>
      <CustomDragLayer />
      {viewType === VIEW.TYPE.GRID && <GridView />}
      {viewType === VIEW.TYPE.LIST && <ListView />}
      <ContextMenu />
      {uploadHolder}
      {dragContainer}
    </div>
  )
}

export default MainView
