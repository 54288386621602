import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Avatar, Collapse, List, Progress } from 'antd'
import { uploaderSelector } from 'redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { FileIcon, defaultStyles } from 'react-file-icon'
import _path from 'path'

import {
  CheckCircleTwoTone,
  EyeTwoTone,
  FolderOpenTwoTone,
  LoadingOutlined,
} from '@ant-design/icons'
import { getSourceByName, truncateString } from 'utils'
import { CloseIcon } from 'utils/icon.util'
import { ButtonIcon } from 'components/Button'
import { toast } from 'react-toastify'
import { useHandleAction, useHandleMouse, useRouter } from 'hooks'
import { getFilesThunk } from 'features/manager/file/fileActions'

const { Panel } = Collapse

export function UploadProgress() {
  const { allFilePercent, list, uploadedOK } = useSelector(uploaderSelector)
  const { t } = useTranslation()
  const [hover, setHover] = useState(null)
  const [totalUploaded, setTotalUploaded] = useState(0)
  const { currentFolder } = useRouter()
  const dispatch = useDispatch()
  const { handlePreview } = useHandleAction()
  const { handleClick } = useHandleMouse()

  const calPercent = name => {
    const currentUpload = allFilePercent.filter(el => el.name === name)
    if (currentUpload.length > 0) {
      const { percent } = currentUpload[0]
      return percent
    }
    return 0
  }

  useEffect(() => {
    if (uploadedOK.length > 0) {
      console.log('upload ok', uploadedOK)
      dispatch(getFilesThunk({ currentFolder }))
    }
  }, [uploadedOK.length])

  useEffect(() => {
    const uploaded = allFilePercent.filter(el => el.percent === 100)
    setTotalUploaded(uploaded.length)
    // var test = document.getElementsByClassName('adsbygoogle')
    // var dataCheck = []
    // for (var i = 0; i <= test.length; i++) {
    //   try {
    //     var anchorStatus = test[i].getAttribute('data-anchor-status')
    //     dataCheck.push(anchorStatus)
    //     var uploader = document.getElementById('uploader');
    //     if (dataCheck.includes('displayed')) {
    //       uploader.style.bottom = '110px';
    //     } else {
    //       uploader.style.bottom = '';
    //     }
    //   } catch (err) {
    //     console.log(err)
    //     if (dataCheck.includes('displayed')) {
    //       uploader.style.bottom = '110px';
    //     } else {
    //       uploader.style.bottom = '';
    //     }
    //   }
    // }
  }, [allFilePercent])

  const height = list.length > 5 ? 300 : list.length * 60
  return (
    <div style={{ width: 352 }}>
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition="start">
        <Panel
          header={
            <span style={{ fontSize: 13 }}>
              {totalUploaded}/{list.length} {t('upload_completed')}
            </span>
          }
          key="1"
          extra={
            <ButtonIcon
              icon={<CloseIcon color="black" />}
              onClick={e => {
                e.stopPropagation()
                toast.dismiss('uploader')
              }}
            />
          }
        >
          <div style={{ height: `${height}px`, overflowY: 'scroll' }}>
            <List
              itemLayout="horizontal"
              size="small"
              dataSource={list}
              renderItem={item => {
                const percent = calPercent(item.name)
                const ext = _path.extname(item.name).substring(1)
                return (
                  <div
                    aria-hidden="true"
                    onClick={() => {
                      handlePreview(item)
                    }}
                    onMouseEnter={() => setHover(item.name)}
                    onMouseLeave={() => setHover(null)}
                    className="item-status"
                    style={{ display: 'flex', height: 60, padding: '0px 16px' }}
                  >
                    <div
                      className="vertically-center mr-2 mb-2"
                      style={{ alignItems: 'start', width: 32 }}
                    >
                      <FileIcon extension={ext} {...defaultStyles[ext]} />
                      {/* <img src={getSourceByName(item.name)} alt="" className="icon-32" /> */}
                    </div>
                    <div className="vertically-center" style={{ width: '100%' }}>
                      <TitleBar
                        title={item.name}
                        hover={hover}
                        percent={percent}
                        onClick={() => handleClick(item)}
                      />
                      {percent < 100 && <ProgressBar total={percent} />}
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}
export default UploadProgress

export function ProgressBar({ total }) {
  return (
    <div>
      <Progress
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#108ee9',
        }}
        percent={total}
        showInfo={false}
      />
    </div>
  )
}
export function TitleBar({ title, percent, hover, onClick }) {
  const uploading =
    hover === title ? (
      <ButtonIcon icon={<CloseIcon twoToneColor="red" />} />
    ) : (
      <ButtonIcon icon={<LoadingOutlined />} />
    )
  const uploaded =
    hover === title ? (
      // <ButtonIcon onClick={onClick} icon={<EyeTwoTone twoToneColor="#1b55e3" />} />
      <EyeTwoTone twoToneColor="#1b55e3" />
    ) : (
      <ButtonIcon icon={<CheckCircleTwoTone twoToneColor="#52c41a" />} />
    )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 14,
        alignItems: 'center',
      }}
    >
      <span style={{ fontSize: 12 }}>{truncateString(title, 32)}</span>
      {percent < 100 ? uploading : uploaded}
    </div>
  )
}
