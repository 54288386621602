import React from 'react'
import { useTranslation } from 'react-i18next'
import UserStorage from 'features/storage/UserStorage'

import FileTree from './components/FileTree'
import SharedFolders from './components/SharedFolders'
import TrashBin from './components/TrashBin'
import AlertFreeUser from './components/AlertFreeUser'
import FreebieFolder from './components/FreebieFolder'

function LeftPane() {
  return (
    <aside
      id="left-pane"
      className="ant-col main-menu ant-col-xs-24 ant-col-sm-24 ant-col-md-6 ant-col-lg-6 ant-col-xl-5 ant-col-xxl-4 list-group"
    >
      <div>
        <FileTree />
      </div>
      <SharedFolders />
      <FreebieFolder />
      <TrashBin />
      <UserStorage />
      <AlertFreeUser />
    </aside>
  )
}

export default LeftPane
