import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  search: '',
}

const filterSlice = createSlice({
  name: 'view_by/filter',
  initialState,
  reducers: {
    filterSearch: (state, action) => {
      state.search = action.payload
    },
  },
  // extraReducers: {
  //   [getStorage.pending]: state => {
  //     state.loading = true
  //     state.error = null
  //     state.storage = 0
  //   },
  //   [getStorage.fulfilled]: (state, { payload }) => {
  //     state.loading = false
  //     state.storage = payload.currentStorage
  //   },
  //   [getStorage.rejected]: (state, { payload }) => {
  //     state.loading = false
  //     state.error = payload
  //     state.storage = 0
  //   },
  // },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = filterSlice
export const { filterSearch } = actions
export default reducer
