import { Icon } from '@ant-design/compatible'
import { Card } from 'antd'
import React from 'react'
import { getSourceByName, truncate } from 'utils'

const { Meta } = Card
const RowDragPreview = ({ item }) => {
  return (
    <span style={{ padding: '5px 10px', borderRadius: 3, color: 'white', background: '#a65a5c' }}>
      <Icon style={{ marginRight: '5px' }} type="folder-open" />
      <span>{truncate(item.name, 15)}</span>
    </span>
  )
}

export default RowDragPreview
