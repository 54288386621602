import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function NotSupport({ URL }) {
  const { t } = useTranslation()
  const downloadFile = () => {
    window.location.href = URL
  }
  return (
    <div
      style={{
        textAlign: 'center',
        height: '130px',
        width: '430px',
        backgroundColor: '#b7b7b745',
        borderRadius: 12,
        color: 'white',
        padding: '20px',
        position: 'absolute',
        top: '40%',
        left: 'calc(50vw - 200px)',
      }}
    >
      <div>{t('no_preview')}</div>
      <Button
        onClick={downloadFile}
        className="ant-btn mt-3"
        style={{ fontWeight: 'bold', fontSize: 14, height: 30 }}
      >
        <DownloadOutlined style={{ fontSize: 16, verticalAlign: 'middle' }} />
        <span style={{ fontSize: 12 }}>{t('download_button')}</span>
      </Button>
    </div>
  )
}

export default NotSupport
