import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion/dist/framer-motion'

export function ItemMenu({ icon, handle, name, display = true, controls }) {
  const { t } = useTranslation()

  return (
    <motion.div
      onMouseDown={handle}
      className="context-menu-button"
      style={{ display: display ? 'block' : 'none' }}
      animate={controls}
    >
      {/* <Button> */}
      <span className="icon-menu">{icon}</span>
      <span style={{ fontSize: 14 }}>{t(name)}</span>
      {/* </Button> */}
    </motion.div>
  )
}

export const Line = ({ display }) => {
  return display ?? <hr style={{ margin: '5px 0px' }} />
}

export default ItemMenu
