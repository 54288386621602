import { boxesIntersect, useSelectionContainer } from '@air/react-drag-to-select'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSelect, onDrag, onDragMore } from 'features/manager/managerSlice'

export const MouseSelection = memo(
  ({ shouldStartSelecting, onSelectionStart, onSelectionChange, onSelectionEnd }) => {
    const keysDown = useRef(false)
    useEffect(() => {
      const keyDownListener = e => {
        if (e.ctrlKey || e.metaKey) {
          keysDown.current = true
        }
      }
      const keyUpListener = e => {
        if (e.key === 'Meta' || e.key === 'Control') {
          keysDown.current = false
        }
      }
      document.addEventListener('keydown', keyDownListener)
      document.addEventListener('keyup', keyUpListener)
      return () => {
        document.removeEventListener('keydown', keyDownListener)
        document.removeEventListener('keyup', keyUpListener)
      }
    }, [])

    const { DragSelection } = useSelectionContainer({
      shouldStartSelecting: target => {
        return shouldStartSelecting(target, keysDown.current)
      },
      onSelectionChange: box => {
        onSelectionChange(box, keysDown.current)
      },
      onSelectionStart: () => {
        onSelectionStart()
      },
      onSelectionEnd: () => {
        onSelectionEnd()
      },
      selectionProps: {
        style: {
          marginTop: 130,
          zIndex: 15000,
        },
      },
    })

    return <DragSelection />
  },
)

export function useDragToSelect() {
  const dispatch = useDispatch()
  // const elements = useMemo(() => {
  //   const itemsRef = document.getElementsByClassName('item-ref')
  //   return itemsRef
  // }, [])
  const elements = document.getElementsByClassName('item-ref')
  const handleSelectionChange = useCallback((box, keyDown) => {
    const selectBox = { ...box, top: box.top, left: box.left }
    const selectedKeys = []
    Array.from(elements).forEach((item, index) => {
      const { left, top, width, height } = item.getBoundingClientRect()
      const itemBox = { left, top, width, height }
      if (boxesIntersect(selectBox, itemBox)) {
        const id = item.getAttribute('data-row-key')
        if (!selectedKeys.includes(id)) {
          selectedKeys.push(id)
        }
      }
    })
    if (keyDown) dispatch(onDragMore(selectedKeys))
    else dispatch(onDrag(selectedKeys))
  }, [])

  const handleSelectionEnd = useCallback((box, dragType) => {}, [])

  const handleSelectionStart = useCallback(() => {}, [])

  const shouldStartSelecting = useCallback((target, keyDown) => {
    if (target instanceof HTMLElement) {
      // filecard or filerow
      // khong keo tha nhung classname nay
      const fileCardDom = target.closest('.file-card')
      const fileRowDom = target.closest('.file-row')
      const leftPaneDom = target.closest('#left-pane')
      const tooBarDom = target.closest('#tool-bar')
      const contextMenuDom = target.closest('.context-menu')
      // selectable area
      // chon vung co the keo tha
      const areaDom = target.closest('#dashboard-files')

      const preventAreaDrag = !fileRowDom && !fileCardDom && !contextMenuDom
      if (areaDom && !leftPaneDom && !tooBarDom) {
        if (keyDown === false && keyDown !== null) {
          if (preventAreaDrag) dispatch(clearSelect())
        }
        return preventAreaDrag
      }
    }
    return false
  }, [])

  const dragContainer = (
    <MouseSelection
      onSelectionChange={handleSelectionChange}
      onSelectionEnd={handleSelectionEnd}
      onSelectionStart={handleSelectionStart}
      shouldStartSelecting={shouldStartSelecting}
    />
  )
  return { dragContainer }
}

export default useDragToSelect
