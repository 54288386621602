import React, { useRef } from 'react'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { useHandleAction, useResize, useUploader } from 'hooks'

import { Button, Popover } from 'antd'
import SearchBar from 'features/view-by/filter/SearchBar'
import ViewType from 'features/view-by/type/ViewType'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'

function FreebieToolBar() {
  const { t } = useTranslation()
  const { onDrop } = useUploader()
  const { notSelected } = useSelector(managerSelector)
  const { handleDownload, handleConfirmDelete } = useHandleAction()

  const handleFileChange = event => {
    const fileObj = event.target.files
    if (!fileObj) {
      return
    }
    const acceptedFile = Array.from(fileObj).map(file => {
      file.path = file.name
      return file
    })
    onDrop(acceptedFile)
  }

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        borderBottom: 'rgb(221, 221, 221) 1px solid',
        padding: '16px 0px',
      }}
    >
      <div style={{ alignSelf: 'center' }} />

      <div
        style={{
          alignSelf: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onClick={handleDownload} disabled={notSelected} className="ant-btn mr-3">
          <DownloadOutlined style={{ fontSize: 20, verticalAlign: 'middle', marginRight: 4 }} />
          <span style={{ fontSize: 14 }}>{t('download_button')}</span>
        </Button>
        <SearchBar />
        <ViewType />
        <Button
          className="btn-circle"
          icon={<DeleteOutlined style={{ fontSize: 18, verticalAlign: 'middle' }} />}
          onClick={handleConfirmDelete}
          disabled={notSelected}
          style={{
            width: 32,
            color: notSelected ? 'grey' : 'black',
          }}
        />
      </div>
    </div>
  )
}

export default FreebieToolBar
