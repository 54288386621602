import React from 'react'
import { Button } from 'antd'
import { GiftOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import { useRouter } from 'hooks'
import { FREEBIE_FOLDER_URL } from 'config/constant'
import { useTranslation } from 'react-i18next'

const FreebieFolder = () => {
  const { router, navigate } = useRouter()
  const { inFreebieFolder } = router
  const { t, i18n } = useTranslation()

  const handleClick = () => {
    navigate(FREEBIE_FOLDER_URL)
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        type="link"
        className={`button-trash-bin ${inFreebieFolder ? 'active' : ''}`}
      >
        <GiftOutlined className="ml-4" />
        <span>{t('newsletter_material')}</span>
      </Button>
    </div>
  )
}

export default FreebieFolder
