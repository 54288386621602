// export const handleDeleteFiles = async (data, files) => {
//   const { folder } = this.state
//   const { t } = this.props
import _path from 'path'

import {
  checkDuplicateFile,
  createFolderExistedUser,
  createFolderNewUser,
  renameFile,
  renameFolder,
  updateUserSSOId,
} from '../upload'

export const handle = () => {}

export const checkDuplicatedFile = ({ acceptedFiles, files, folders }) => {
  const checkDuplicated = {
    type: '',
    isDuplicatedName: false,
  }
  for (let i = 0; i < files.length; i += 1) {
    const filter = acceptedFiles.filter(el => el.name === files[i].name)
    if (filter.length > 0) {
      checkDuplicated.type = 'file'
      checkDuplicated.isDuplicatedName = true
      break
    }
  }
  for (let i = 0; i < folders.length; i += 1) {
    const filter = acceptedFiles.filter(el => el.name === folders[i].name)
    if (filter.length > 0) {
      checkDuplicated.type = 'folder'
      checkDuplicated.isDuplicatedName = true
      break
    }
  }
  return checkDuplicated
}

export const createFolder = async (folders, currentFolder) => {
  if (currentFolder.startsWith('/')) {
    currentFolder = currentFolder.substring(1)
  }
  await folders.reduce(async (p, item) => {
    await p
    // const data = {
    //   path: currentFolder === '/' ? item.path : _path.join(currentFolder, item.path),
    // }
    const data = {
      path: _path.join(currentFolder, item.path),
    }
    let result = []
    if (localStorage.getItem('uuid')) {
      result = await createFolderExistedUser(data)
    } else {
      result = await createFolderNewUser(data)
      const { id } = result
      localStorage.setItem('uuid', id)
    }

    if (localStorage.getItem('sessionUserId') !== null) {
      updateUserSSOId({ sso: localStorage.getItem('sessionUserId') })
    }
    if (item.children.length === 0) return null
    return createFolder(item.children, currentFolder)
  }, Promise.resolve())
}
