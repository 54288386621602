import React, { useRef } from 'react'
import {
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  MailOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { useHandleAction, useResize, useUploader } from 'hooks'

import { Button, Popover } from 'antd'
import axios from 'axios'
import SearchBar from 'features/view-by/filter/SearchBar'
import ViewType from 'features/view-by/type/ViewType'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'
import { isMobile } from 'config/constant'

const { CancelToken } = axios
// let source = CancelToken.source()

function MainToolBar() {
  const { t } = useTranslation()
  // const [isOpenMailMagaAlert, setOpenMailMagaAlert] = useState(false)
  // const [isOpenTrialAlert, setOpenTrialAlert] = useState(false)
  // const [isOpenDropzoneModal, setOpenDropzoneModal] = useState(false)
  const { onDrop } = useUploader()
  const { notSelected } = useSelector(managerSelector)
  const uploadFileRef = useRef()
  const uploadFolderRef = useRef()
  const {
    handleConfirmDelete,
    handleDownload,
    handleShareToMail,
    handleNewFolder,
  } = useHandleAction()

  const handleFileChange = event => {
    const fileObj = event.target.files
    if (!fileObj) {
      return
    }
    const acceptedFile = Array.from(fileObj).map(file => {
      file.path = file.webkitRelativePath || file.name
      return file
    })
    onDrop(acceptedFile)
  }

  const uploadPopoverContent = (
    <div>
      <Button
        onClick={() => {
          uploadFileRef.current.click()
        }}
        className="ant-btn mb-2"
        style={{ fontWeight: 'bold', fontSize: 14, height: 40 }}
      >
        <FileAddOutlined style={{ fontSize: 16, verticalAlign: 'middle', marginRight: 3 }} />
        <span style={{ fontSize: 14 }}>{t('upload_files_title')}</span>
      </Button>
      <br />
      <Button
        onClick={() => {
          uploadFolderRef.current.click()
        }}
        className="ant-btn"
        style={{ fontWeight: 'bold', fontSize: 14, height: 40 }}
      >
        <FolderAddOutlined style={{ fontSize: 16, verticalAlign: 'middle', marginRight: 3 }} />
        <span style={{ fontSize: 14 }}>{t('upload_folders_title')}</span>
      </Button>
    </div>
  )

  return (
    <div
      className="d-flex justify-content-between"
      style={{
        borderBottom: 'rgb(221, 221, 221) 1px solid',
        padding: '16px 0px',
      }}
    >
      <div style={{ alignSelf: 'center' }}>
        <Button
          className="ant-btn mr-3"
          onClick={handleShareToMail}
          disabled={notSelected}
          style={{ fontSize: 14 }}
        >
          <MailOutlined style={{ fontSize: 20, verticalAlign: 'middle' }} />
          <span style={{ fontSize: 14 }}>{t('transfer_tab')}</span>
        </Button>
        <Button onClick={handleNewFolder} className="ant-btn mr-3" style={{ fontSize: 14 }}>
          <PlusOutlined style={{ fontSize: 15, verticalAlign: 'middle' }} />
          {t('add_folder')}
        </Button>
        <Popover content={uploadPopoverContent} placement="bottom" trigger="hover">
          <Button className="ant-btn mr-3" style={{ fontSize: 14 }}>
            <UploadOutlined style={{ fontSize: 20, verticalAlign: 'middle' }} />
            <span style={{ fontSize: 14 }}>{t('upload_button')}</span>
            <input type="file" hidden ref={uploadFileRef} onChange={handleFileChange} multiple />
            <input
              type="file"
              hidden
              multiple
              ref={uploadFolderRef}
              onChange={handleFileChange}
              webkitdirectory="true"
              directory="true"
            />
          </Button>
        </Popover>
      </div>

      <div
        style={
          isMobile()
            ? { alignSelf: 'center' }
            : {
                alignSelf: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }
        }
      >
        <Button onClick={handleDownload} disabled={notSelected} className="ant-btn mr-3">
          <DownloadOutlined style={{ fontSize: 20, verticalAlign: 'middle', marginRight: 4 }} />
          <span style={{ fontSize: 14 }}>{t('download_button')}</span>
        </Button>
        <SearchBar />
        <ViewType />
        <Button
          className="btn-circle"
          icon={<DeleteOutlined style={{ fontSize: 18, verticalAlign: 'middle' }} />}
          onClick={handleConfirmDelete}
          disabled={notSelected}
          style={{
            width: 32,
            color: notSelected ? 'grey' : 'black',
          }}
        />
      </div>
    </div>
  )
}

export default MainToolBar
