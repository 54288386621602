/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Input, Alert, Result, List, Empty, Progress } from 'antd'
import { useHandleAction, useResize } from 'hooks'
import { formatDistance, addHours, parse, format } from 'date-fns'
import { ja, enUS } from 'date-fns/locale'
import _path from 'path'
import { VIEW } from 'config/constant'
import { downloadMultiFiles } from 'services/files/download.services'
import { getSharedDownloadURL, downloadFile, updateDownloadTime } from 'services/upload'
import { openNotificationWithIcon } from 'utils/common.util'
import SharedFileCard from './SharedFileCard'
import SharedFolderCard from './SharedFolderCard'

const SharedList = ({
  shared,
  dataSource,
  loadSharedFolders,
  title,
  sender,
  message,
  expired,
  setURL,
  genURL,
  setSelectedItem,
  setOpenFilePreviewModal,
  fileLoading,
  setFileLoading,
  _i,
}) => {
  const { t } = useTranslation()
  const { widthMainView } = useResize()
  const { handlePreview, handleDownload } = useHandleAction()
  const [percent, setPercent] = useState(-1)

  const size = VIEW.SIZE_PX.SMALL
  const column = Math.floor(widthMainView / size)
  let expiryTime = new Date()
  if (expired) expiryTime = parse(expired, 'yyyy-MM-dd HH:mm:ss', new Date())
  console.log({ dataSource })
  const info = {
    sender: sender === 'aaa@aaa.aaa' || sender === 0 ? '' : sender,
    message: message === 0 ? '' : message,
    title: title === 0 ? '' : title,
  }

  const commonLastPath = useMemo(() => {
    try {
      const splitPaths = dataSource.map(item => item.path.split('/').filter(Boolean))
      const common = splitPaths.reduce((acc, pathParts) => {
        return acc.filter(part => pathParts.includes(part))
      })
      return common.pop()
    } catch (err) {
      return null
    }
  }, [shared])

  const downloadFileName = dataSource?.length === 1 ? dataSource[0].name : commonLastPath

  function removeEqualsSign(str) {
    return str.replace(/=+$/, '')
  }

  const handlePercent = currentPercent => {
    setPercent(currentPercent)
  }
  const handleError = () => {
    openNotificationWithIcon('error', t('download_files_fail'))
  }

  const onSuccess = () => {
    openNotificationWithIcon('success', t('file_downloaded'))
  }

  const handleDownloadShareList = async evt => {
    if (fileLoading || (percent < 100 && percent >= 0)) {
      return
    }
    evt.stopPropagation()
    setFileLoading(true)
    const data = {
      url: await getSharedDownloadURL({ shared }),
      name: downloadFileName || removeEqualsSign(shared),
    }
    await downloadFile(data, handlePercent, onSuccess, handleError)
      .then(res => {
        updateDownloadTime({ shared, type: 'folder' })
      })
      .catch(e => {
        openNotificationWithIcon('error', t('download_files_fail'))
      })
      .finally(() => {
        setFileLoading(false)
      })
  }

  return (
    <List
      id="main-view"
      bordered
      header={
        <div>
          <span style={{ fontSize: 20 }}>
            <strong>{info.title === '' ? t('shared_folder_title') : info.title}</strong>
          </span>
          <div style={{ float: 'right', textAlign: 'center' }}>
            <Button
              style={{
                height: 40,
                cursor: `${fileLoading || (percent < 100 && percent >= 0) ? 'default' : 'pointer'}`,
              }}
              type="primary"
              onClick={handleDownloadShareList}
            >
              <DownloadOutlined />
              <span>{t('list_download')}</span>
              {percent >= 0 && (
                <Progress
                  type="circle"
                  className={percent < 100 ? 'white-text' : ''}
                  percent={percent}
                  width={30}
                  style={{ paddingLeft: '10px' }}
                  strokeColor={{
                    '0%': '#87d068',
                    '100%': '#108ee9',
                  }}
                />
              )}
            </Button>
            <br />
            <span style={{ fontSize: 12 }}>
              <i>
                {t('list_file_expired')}
                {': '}
                {format(expiryTime, 'PPP', {
                  locale: localStorage.getItem('lang') === 'en' ? enUS : ja,
                })}
              </i>
            </span>
          </div>
          <br />
          <span style={{ fontSize: 13 }}>
            <i>{info.sender}</i>
          </span>
          <br />
          {info.message !== '' && (
            <>
              <br />
              <strong>{t('shared_folder_message')}</strong>
              <br />
              {info.message}
            </>
          )}
        </div>
      }
      // style={{ paddingTop: 10, marginBottom: 50, paddingRight: 15 }}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={t('no_data')} />,
      }}
      grid={{ gutter: 5, column }}
      dataSource={dataSource}
      pagination={{
        pageSize: 18,
        simple: true,
        hideOnSinglePage: true,
      }}
      loading={
        fileLoading ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : false
      }
      renderItem={item => {
        return (
          <>
            {item.type === 'file' && (
              <SharedFileCard
                t={t}
                id={item.id}
                key={item.key}
                setFileLoading={setFileLoading}
                setURL={setURL}
                genURL={genURL}
                setOpenFilePreviewModal={setOpenFilePreviewModal}
                setSelectedItem={setSelectedItem}
                fileItem={item}
                _i={_i}
              />
            )}
            {item.type === 'folder' && (
              <SharedFolderCard
                t={t}
                id={item.id}
                key={item.key}
                loadSharedFolders={loadSharedFolders}
                folderItem={item}
              />
            )}
          </>
        )
      }}
    />
  )
}

export default SharedList
