import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { truncateString } from 'utils'
import { Modal, Button } from 'antd'
import { selectedFileSelector } from 'redux/selectors'
import { ShareToAccountForm } from './ShareToAccountForm'

function ShareToAccountModal({ onCancel, data }) {
  const { t } = useTranslation()

  if (data.length === 0) return null
  return (
    <Modal
      className="modal-share-account"
      title={`「${truncateString(data[0].name, 15)}」${t('share_title_text')}`}
      width={620}
      open
      centered
      footer={null}
      // okText='共有する'
      // cancelText={t('cancel_button')}
      onCancel={onCancel}
      destroyOnClose
    >
      <ShareToAccountForm onCancel={onCancel} data={data} />
    </Modal>
  )
}

export default withTranslation()(ShareToAccountModal)
