import React from 'react'

export const GridSmall = (
  <svg viewBox="0 0 24 24" fill="none" width="24" height="24" focusable="false">
    <path
      d="M5 5h3v3H5V5Zm8.5 0h-3v3h3V5ZM19 5h-3v3h3V5ZM5 10.5h3v3H5v-3Zm8.5 0h-3v3h3v-3Zm2.5 0h3v3h-3v-3ZM8 16H5v3h3v-3Zm2.5 0h3v3h-3v-3Zm8.5 0h-3v3h3v-3Z"
      fill="currentColor"
    />
  </svg>
)
export const GridLarge = (
  <svg viewBox="0 0 24 24" fill="none" width="24" height="24" focusable="false">
    <path
      d="M11 11H5V5h6v6ZM9.5 6.5h-3v3h3v-3ZM13 11V5h6v6h-6Zm1.5-1.5h3v-3h-3v3ZM5 19h6v-6H5v6Zm1.5-4.5h3v3h-3v-3ZM19 19h-6v-6h6v6Zm-1.5-4.5h-3v3h3v-3Z"
      fill="currentColor"
    />
  </svg>
)
export const ListSmall = (
  <svg viewBox="0 0 24 24" fill="none" width="24" height="24" focusable="false">
    <path
      d="M5.5 6h13v1.5h-13V6Zm13 3.5h-13V11h13V9.5ZM5.5 13h13v1.5h-13V13Zm0 3.5h13V18h-13v-1.5Z"
      fill="currentColor"
    />
  </svg>
)
export const ListLarge = (
  <svg viewBox="0 0 24 24" fill="none" width="24" height="24" focusable="false">
    <path
      d="M18.5 16.5h-13V18h13v-1.5Zm0-5.5h-13v1.5h13V11Zm0-5.5h-13V7h13V5.5Z"
      fill="currentColor"
    />
  </svg>
)
