import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'redux/store'
import { toast, ToastContainer } from 'react-toastify'
import { RouterApp } from './routers'
import './i18n'
import './global.scss'
import registerServiceWorker from './serviceWorker'
import appInfo from '../package.json'

const buildDate = new Date(appInfo.buildTime)
console.log(`version: ${appInfo.version} -- timeBuild: ${buildDate}`)
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <RouterApp />
  </Provider>,
)
registerServiceWorker()
