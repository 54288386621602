import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function Video({ URL }) {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '95vh',
        display: 'flex',
        backgroundColor: 'black',
      }}
    >
      <video style={{ height: '93%' }} autoPlay muted controls src={URL.toString()}>
        <track default kind="captions" src="" />
      </video>
    </div>
  )
}

export default Video
