import React, { useCallback, useState, useEffect } from 'react'
import path from 'path'
import { useDropzone } from 'react-dropzone'
import Tween from 'rc-tween-one'
import {
  postSaveFiles,
  uploadFromURL,
  uploadFromURLExistUser,
  shareFiles,
  zipFiles,
  startUpload,
  startUploadFolder,
  getPartUrl,
  completeUpload,
  uploadBigSizeNotify,
  updateUserSSOId,
  checkSSOUserHaveACdataID,
  validateSSOId,
  zipData,
} from 'services/upload'
import { encryptId, getCookie, notUgly } from 'utils'

import { useResize } from 'hooks'
import { useTranslation, withTranslation } from 'react-i18next'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Select, Button, message, Input, Spin, Popover } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { humanFileSize, openNotificationWithIcon, copy } from 'utils/common.util'
import { ACTION, DEFAULT_FOLDER_PATH, MODAL_TYPES } from 'config/constant'
import crypto from 'crypto'
import config from 'config'
import { publish } from 'events/event'
import axios from 'axios'
import uuidV4 from 'uuid/v4'
import { TableFiles } from './TableFiles'

const { CancelToken } = axios
let source = CancelToken.source()

function Dropzone({
  disableShareByURL,
  isDropzoneModal,
  toggleDropzoneModal,
  onClickShare,
  handleZippedFile,
  expiry,
  handleFilesExpiry,
  isModalClose,
}) {
  const [isDisableSave, setDisableSave] = useState(true)
  const [list, setList] = useState([])
  const [uploaded, setUploaded] = useState([])
  const [canceled, setCanceled] = useState([])
  const [fileUrl, setFileUrl] = useState('')
  const [newFileName, setNewFileName] = useState('')
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [password, setPassword] = useState('')
  const [zipName, setZipName] = useState('')
  const [zipExt, setZipExt] = useState('.zip')
  const [zipUrl, setZipUrl] = useState('')
  const [isLoadingUrl, setIsLoadingUrl] = useState(false)
  const [isZipping, setIsZipping] = useState(false)
  const [allFilePasswords, setAllFilePasswords] = useState([])
  const [allFilePercent, setAllFilePercent] = useState([])
  const [isDragged, setIsDragged] = useState(false)

  const { t, i18n } = useTranslation()

  const { windowSize } = useResize()

  useEffect(() => {
    if (list.length > 0) onClickSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalClose])

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handleZipNameChange = e => {
    setZipName(e.target.value)
  }

  const handleZipUrlChange = e => {
    setZipUrl(e.target.value)
  }

  const handleExtChange = value => {
    setZipExt(value)
  }

  const handleVisibleChange = isVisible => {
    setVisible(isVisible)
  }

  const onReset = () => {
    setList([])
    setUploaded([])
    setFileUrl('')
    setNewFileName('')
    setIsSubmiting(false)
    setIsLoading(false)
    setPassword('')
    setVisible(false)
    setAllFilePasswords([])
    setIsZipping(false)
    setIsLoadingUrl(false)
    setCanceled([])
    setAllFilePercent([])
  }

  const handleCopy = async url => {
    copy(url)
    openNotificationWithIcon('success', t('copy_file_url_success'))
  }

  const onCancel = () => {
    setIsLoading(false)
    source.cancel('Operation canceled by the user.')
    source = CancelToken.source()
  }

  const handlePercent = (name, partNumber, percent, totalPart) => {
    // console.log("percenttttttttttttt handlePercent ", name, percent, totalPart)
    setAllFilePercent(state => [
      ...state.map(el => {
        if (el.name === name && el.percent < 100) {
          const newChunks = [...el.chunks]
          newChunks[partNumber - 1] = percent * (1 / totalPart)
          const newPercent = Math.round(newChunks.reduce((a, b) => a + b, 0))
          // console.log("percenttttttttttttt handlePercent totalllll ", name, newPercent, newChunks)
          return Object.assign({}, el, { percent: newPercent, chunks: newChunks })
        }
        return el
      }),
    ])
  }

  const onDrop = useCallback(async acceptedFiles => {
    setIsLoading(true)
    setIsDragged(false)
    if (acceptedFiles.length === 0) {
      setIsLoading(false)
    }
    const totalSizes = acceptedFiles.reduce((tot, item) => {
      return tot + item.size
    }, 0)
    const dataSource = acceptedFiles.map((item, key) => ({
      key: uuidV4(),
      fileName: item.name,
      fileSize: item.size,
    }))
    setAllFilePercent(
      allFilePercent.concat(
        dataSource.map(item => ({ key: item.key, name: item.fileName, percent: 0, chunks: [] })),
      ),
    )
    const allFileKeys = dataSource.map(item => item.key)
    setList(list.concat(dataSource))
    // const currentStorage = await getCurrentStorage().then(res => res.currentStorage)
    // if (totalSizes <= 250 * 1024 * 1024 * 1024) {
    setAllFilePasswords(
      allFilePasswords.concat(
        dataSource.map(item => ({
          key: item.key,
          name: item.fileName,
          password: '',
        })),
      ),
    )
    const filenames = acceptedFiles.map(item => item.name)
    const currentFolder = localStorage.getItem('current_folder')
    const data = {
      filenames,
      path: currentFolder || DEFAULT_FOLDER_PATH,
      totalFiles: acceptedFiles.length,
    }
    let uuid = localStorage.getItem('uuid')
    console.log(uuid)
    if (notUgly(getCookie('sessionUserId'))) {
      uuid = await validateSSOId(getCookie('sessionUserId'))
      if (notUgly(uuid)) localStorage.setItem('uuid', uuid)
    }

    console.log(uuid)
    let results = null
    if (notUgly(uuid)) {
      // results = await getPreSignedUrlByFolder(data)
      results = await startUploadFolder(data, source.token)
    } else {
      // results = await getPreSignedUrls(data)
      results = await startUpload(data, source.token)
      const { id } = results
      localStorage.setItem('uuid', id)
    }
    // const { urls, id } = results
    const { uploadIds } = results
    localStorage.setItem('current_folder', data.path)
    if (notUgly(getCookie('sessionUserId'))) {
      updateUserSSOId({ sso: getCookie('sessionUserId') })
    }
    if (acceptedFiles.length > 500) {
      uploadBigSizeNotify({ sources: dataSource })
    }
    let itemsProcessed = 0
    await acceptedFiles.forEach(async (file, index, array) => {
      const fileSize = file.size
      if (fileSize > 5000000000) {
        uploadBigSizeNotify({ sources: dataSource })
      }
      let FILE_CHUNK_SIZE = 5300000 // 5MB
      switch (true) {
        case fileSize >= 10 * 1000000000: // 10GB ~
          FILE_CHUNK_SIZE = 5000000000 // 5GB
          break
        default:
          // 0 ~ 10GB
          FILE_CHUNK_SIZE = 5300000 // 5MB
          break
      }
      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1

      const promisesArray = []
      let start
      let end
      let blob

      setAllFilePercent(state => [
        ...state.map(el => {
          if (el.name === file.name && el.percent < 100) {
            return Object.assign({}, el, { chunks: [...Array(NUM_CHUNKS)].map((_, i) => 0) })
          }
          return el
        }),
      ])

      for (let i = 1; i < NUM_CHUNKS + 1; i += 1) {
        start = (i - 1) * FILE_CHUNK_SIZE
        end = i * FILE_CHUNK_SIZE
        blob = i < NUM_CHUNKS ? file.slice(start, end) : file.slice(start)
        // (1) Generate presigned URL for each part
        // eslint-disable-next-line no-await-in-loop
        const getUploadUrlResp = await getPartUrl(
          {
            fileName: file.name,
            partNumber: i,
            uploadId: uploadIds[index],
            path: data.path,
          },
          source.token,
        )

        const { url } = getUploadUrlResp
        // console.log(`Presigned URL ${i}: ${url} filetype ${file.type}`)

        // (2) Puts each file part into the storage server
        const uploadResp = axios
          .put(url, blob, {
            headers: { 'Content-Type': file.type },
            cancelToken: source.token,
            onUploadProgress: progressEvent => {
              const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              // console.log("percenttttttttttttt ", file.name, i, percent)
              handlePercent(file.name, i, percent, NUM_CHUNKS)
            },
          })
          .catch(e => {
            if (axios.isCancel(e)) {
              setCanceled(canceleds => [...canceleds, allFileKeys[index]])
              throw e
            }
          })
        promisesArray.push(uploadResp)
      }

      const resolvedArray = await Promise.all(promisesArray)
      // console.log(resolvedArray, ' resolvedAr')

      const uploadPartsArray = []
      resolvedArray.forEach((resolvedPromise, i) => {
        uploadPartsArray.push({
          ETag: resolvedPromise.headers.etag.replace(/^"(.*)"$/, '$1'),
          PartNumber: i + 1,
        })
      })

      // (3) Calls the CompleteMultipartUpload endpoint in the backend server
      let uploadStatus = false
      const completeUploadResp = await completeUpload(
        {
          fileName: file.name,
          path: data.path,
          parts: uploadPartsArray,
          uploadId: uploadIds[index],
        },
        source.token,
      )
      if (completeUploadResp.status === 200) {
        setUploaded(uploadeds => [...uploadeds, allFileKeys[index]])
        itemsProcessed += 1
        uploadStatus = true
      } else if (completeUploadResp.status === 555) {
        setCanceled(canceleds => [...canceleds, allFileKeys[index]])
        uploadStatus = false
      }
      // console.log(completeUploadResp, ' Stuff')
      if (uploadStatus) {
        dataSource[index] = {
          ...dataSource[index],
          url: uploadIds[index],
        }
        setList(list.concat(dataSource))
      }
      if (itemsProcessed === array.length) {
        setIsLoading(false)
      }
    })
  })

  const onDelete = item => {
    let newList = [...list]
    newList = newList.filter(v => v.key !== item.key)
    if (newList.length === 0) {
      onReset()
    } else setList(newList)

    let newCancel = [...canceled]
    newCancel = newCancel.filter(v => v !== item.key)
    setCanceled(newCancel)

    let newUpload = [...uploaded]
    newUpload = newUpload.filter(v => v !== item.key)
    setUploaded(newUpload)

    let newAllFilePasswords = [...allFilePasswords]
    newAllFilePasswords = newAllFilePasswords.filter(v => v.key !== item.key)
    setAllFilePasswords(newAllFilePasswords)
  }

  const onClickZip = async () => {
    setIsLoading(true)
    setIsLoadingUrl(true)
    setIsZipping(true)
    const filenames = list.filter(item => uploaded.includes(item.key)).map(item => item.fileName)
    // const currentFolder = localStorage.getItem('current_folder')
    const data = {
      zipName,
      zipExt,
      selectedFiles: list,
      currentFolder: DEFAULT_FOLDER_PATH,
      path: DEFAULT_FOLDER_PATH,
      filenames,
    }
    const response = await zipFiles(data)
    const dataZip = response
    console.log(dataZip)
    setZipName(dataZip.zipFileName)
    await shareFiles({
      // password,
      sender: 'aaa@aaa.aaa',
      filesShare: [{ name: dataZip.zipFileName, password }],
      zipped: { name: dataZip.zipFileName, password, url: '' },
      receivers: ['aaa@aaa.aaa'],
      message: "Shared by file's URL",
      notification: false,
      path: '_/data/archives/',
      expiry,
    })
      .then(ress => {
        localStorage.setItem(
          'sharedids',
          JSON.stringify(
            (JSON.parse(localStorage.getItem('sharedids')) || []).concat(ress.data.sharedIds[0]),
          ),
        )
        // const mykey = crypto.createCipher('des', 'acdata.acworks')
        // let shareEncrypt = mykey.update(`${ress.data.sharedIds[0]}`, 'utf8', 'base64')
        // shareEncrypt += mykey.final('base64')
        // shareEncrypt = shareEncrypt.replace(/\//g, '&').slice(0, -1)

        const shareEncrypt = encryptId(ress.data.sharedIds[0])
        copy(`${config.sso.redirect}/d/${shareEncrypt}`)
        setZipUrl(`${config.sso.redirect}/d/${shareEncrypt}`)
        setIsLoading(false)
        setIsLoadingUrl(false)
        setVisible(false)
        handleZippedFile({
          name: dataZip.zipFileName,
          password,
          url: `${shareEncrypt}`,
        })
        openNotificationWithIcon('success', t('copy_file_url_success'))
      })
      .catch(e => {
        setIsLoading(false)
        setVisible(false)
        openNotificationWithIcon('error', t('copy_file_url_fail'))
      })
  }

  const onClickSave = async () => {
    setIsLoading(true)
    const filenames = list.filter(item => uploaded.includes(item.key)).map(item => item.fileName)
    const currentFolder = localStorage.getItem('current_folder')
    const data = {
      filenames,
      path: currentFolder || DEFAULT_FOLDER_PATH,
    }
    postSaveFiles(data)
      .then(res => {
        setIsLoading(false)
        // toggleDropzoneModal(false)
        openNotificationWithIcon('success', t('save_files_success'))
      })
      .catch(e => {
        setIsLoading(false)
        openNotificationWithIcon('error', t('save_files_fail'))
      })
  }
  const checkExt = (url, filename) => {
    if (filename !== '') {
      return path.extname(path.basename(url)) === path.extname(filename)
    }
    return true
  }
  const onSubmitURL = ev => {
    ev.preventDefault()
    setIsSubmiting(true)
    const regex = /^(\https?:\/\/(www\.)?[\._~:/?#\[\]@!$&'()*+,;=%]*[^\\]*\.(\w+))$/
    if (fileUrl.match(regex) == null) {
      openNotificationWithIcon('error', t('invalid_url'))
      setIsSubmiting(false)
    } else if (!checkExt(fileUrl, newFileName)) {
      openNotificationWithIcon('error', t('different_file_extension'))
      setIsSubmiting(false)
    } else {
      const currentFolder = localStorage.getItem('current_folder')
      const data = {
        fileUrl,
        path: currentFolder || DEFAULT_FOLDER_PATH,
        filename: newFileName,
      }
      if (localStorage.getItem('uuid')) {
        uploadFromURLExistUser(data)
          .then(res => {
            if (res.error === 10) {
              setIsSubmiting(false)
              openNotificationWithIcon('error', t('save_files_fail'))
            } else {
              setIsSubmiting(false)
              toggleDropzoneModal(false)
              openNotificationWithIcon('success', t('save_files_success'))
            }
          })
          .catch(e => {
            setIsSubmiting(false)
            openNotificationWithIcon('error', t('save_files_fail'))
          })
      } else {
        uploadFromURL(data)
          .then(res => {
            if (res.error === 10) {
              setIsSubmiting(false)
              openNotificationWithIcon('error', t('save_files_fail'))
            } else {
              setIsSubmiting(false)
              openNotificationWithIcon('success', t('save_files_success'))
              localStorage.setItem('uuid', res.id)
            }
            // localStorage.setItem('current_folder', DEFAULT_FOLDER_PATH)
          })
          .catch(e => {
            setIsSubmiting(false)
            openNotificationWithIcon('error', t('save_files_fail'))
          })
      }
    }
  }
  const onDragEnter = event => {
    setIsDragged(true)
  }

  const onDragLeave = event => {
    setIsDragged(false)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    noClick: true,
  }) // noClick: true
  const handleFileUrlChange = e => {
    setFileUrl(e.target.value)
  }
  const handleFileNameChange = e => {
    setNewFileName(e.target.value)
  }

  const selectAfter = (
    <Select defaultValue={zipExt} onChange={handleExtChange}>
      <Select.Option value=".zip">.zip</Select.Option>
      <Select.Option value=".rar">.rar</Select.Option>
    </Select>
  )

  let tweenOrigin = {
    x: 0,
    y: 0,
  }
  if (isDropzoneModal) {
    tweenOrigin = {
      x: '-27vw',
      y: '4vh',
    }
  } else {
    let originX = 0
    let originY = 0
    if (windowSize.width > 1555) {
      originX = -685
    } else {
      originX = -(windowSize.width * 0.45) + 15
    }
    if (windowSize.height >= 1000) {
      originY = '7vh'
    } else if (windowSize.height < 1000 && windowSize.height > 750) {
      originY = '6vh'
    } else {
      originY = '4vh'
    }
    tweenOrigin = {
      x: originX,
      y: originY,
    }
  }

  return (
    <div className="air-tabs-bordered">
      <div style={{ minHeight: '500px', marginTop: 50, padding: '0' }}>
        {list.length !== 0 && (
          <div
            {...getRootProps()}
            className={`${isDragged ? 'table-drop-hover' : ''} defocus`}
            style={{ minHeight: '60vh' }}
          >
            <input {...getInputProps()} className="table-dropzone" />
            <TableFiles
              t={t}
              isDragged={isDragged}
              dataSource={list}
              uploaded={uploaded}
              canceled={canceled}
              pagination={false}
              showHeader={false}
              style={{ minHeight: '350px' }}
              onDeleteFile={onDelete}
              isLoading={
                isLoading ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : false
              }
              allFilePasswords={allFilePasswords}
              allFilePercent={allFilePercent}
              expiry={expiry}
              handleFilesExpiry={handleFilesExpiry}
              isDropzoneModal={isDropzoneModal}
            />
            <div className="justify-content-end d-flex mt-2">
              {isZipping && (
                <Form layout="inline">
                  <Form.Item
                    label={
                      <span style={{ fontWeight: 'bold', color: '#666464', fontSize: 14 }}>
                        {t('text_sumary_name')}
                      </span>
                    }
                  >
                    {/* change text */}
                    <span className="ant-form-text">{zipName}</span>
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontWeight: 'bold', color: '#666464', fontSize: 14 }}>
                        {t('text_sumary_url')}
                      </span>
                    }
                  >
                    {/* change text */}
                    <Input
                      value={zipUrl}
                      onChange={handleZipUrlChange}
                      addonAfter={
                        <Button
                          type="link"
                          onClick={() => handleCopy(zipUrl)}
                          disabled={isLoadingUrl}
                          loading={
                            isLoadingUrl
                              ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
                              : false
                          }
                        >
                          <span style={{ fontSize: 14 }}>{t('button_copy')}</span>
                        </Button>
                      }
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
            {!isLoading && (
              <div className="justify-content-end d-flex mt-2 align-content-center">
                <Button type="button" className="defocus mr-3 upload-button-small" onClick={open}>
                  <span style={{ fontSize: 14 }}>{t('add_files')}</span>
                </Button>
                <Button key="stop" className="mr-3" disabled>
                  <span style={{ fontSize: 14 }}>{t('stop_button')}</span>
                </Button>
                {list.length > 1 && (
                  <Popover
                    content={
                      <>
                        <Input
                          // addonAfter={selectAfter}
                          addonAfter=".zip"
                          placeholder={t('list_file_name')}
                          className="auto-width mb-2"
                          value={zipName}
                          onChange={handleZipNameChange}
                        />
                        <br />
                        <Input.Password
                          placeholder={t('sharing_form_password')}
                          className="auto-width"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        <Button type="link" onClick={onClickZip}>
                          <span style={{ fontSize: 14 }}>{t('button_setup')}</span>
                        </Button>
                      </>
                    }
                    trigger="click"
                    placement="bottomRight"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button className="mr-3">
                      <span style={{ fontSize: 14 }}>{t('zip_button')}</span>
                    </Button>
                  </Popover>
                )}
                <Button
                  key="share"
                  type="primary"
                  className="mr-3"
                  onClick={() => {
                    publish(ACTION.SHOW_MODAL, {
                      type: MODAL_TYPES.shareToMailDropzone,
                      data: list
                        .filter(item => uploaded.includes(item.key))
                        .map(item => item.fileName),
                      expiry,

                      // data: allFilePasswords.filter(item => {
                      //   return !canceled.includes(item.key)
                      // }),
                    })
                  }}
                >
                  <span style={{ fontSize: 14 }}>{t('share_button')}</span>
                </Button>
                <Button key="submit" type="primary" onClick={onClickSave}>
                  <span style={{ fontSize: 14 }}>{t('save_button')}</span>
                </Button>
              </div>
            )}
            {isLoading && (
              <div className="justify-content-end d-flex mt-4">
                <Button type="button" className="defocus mr-3 upload-button-small" disabled>
                  <span style={{ fontSize: 14 }}>{t('add_files')}</span>
                </Button>
                <Button key="stop" className="mr-3" onClick={onCancel}>
                  <span style={{ fontSize: 14 }}>{t('stop_button')}</span>
                </Button>
                {list.length > 1 && (
                  <Button className="mr-3" disabled>
                    <span style={{ fontSize: 14 }}>{t('zip_button')}</span>
                  </Button>
                )}
                <Button key="share" type="primary" className="mr-3" disabled>
                  <span style={{ fontSize: 14 }}>{t('share_button')}</span>
                </Button>
                <Button type="primary" className="mr-3" disabled>
                  <span style={{ fontSize: 14 }}>{t('save_button')}</span>
                </Button>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            display: `${list.length > 0 ? 'none' : 'block'}`,
            float: 'right',
            width: 0,
            height: 0,
            pointerEvents: 'none',
          }}
        >
          <Tween
            animation={{
              x: tweenOrigin.x,
              y: tweenOrigin.y,
              repeat: -1,
              repeatDelay: 1500,
              duration: 1500,
            }}
            style={{
              zIndex: '999',
              position: 'relative',
              userSelect: 'none',
              top: '100px',
              right: '100px',
            }}
          >
            <img alt="" src="/resources/images/file.png" />
          </Tween>
        </div>
        <div
          {...getRootProps()}
          className={
            list.length !== 0
              ? 'defocus'
              : 'align-items-center d-flex flex-column drop-zone defocus'
          }
        >
          <input {...getInputProps()} className="default-dropzone" />
          {list.length === 0 && (
            <>
              {!isDropzoneModal && <h2 style={{ textAlign: 'center' }}>{t('dropzone_title')}</h2>}
              <div
                className={`${
                  isDragged ? 'drop-hover' : 'upload-zone'
                } align-items-center d-flex flex-column defocus`}
                onClick={open}
                tabIndex="0"
                role="button"
                onKeyDown={open}
              >
                <Button style={{ marginTop: 120, color: 'white', zIndex: 999 }} type="primary">
                  <span style={{ fontSize: 14 }}>{t('select_files')}</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dropzone
