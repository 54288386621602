/* eslint-disable dot-notation */
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { CaretDownOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, List, Menu, Spin } from 'antd'
import axios from 'axios'
import { UserInfoWithAvatar } from 'components/Avatar'
import { PERMISSION_SHARE } from 'config/constant'
import { useRouter } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { userSelector } from 'redux/selectors'
import {
  getSharedUser,
  removeShared,
  shareToAccount,
  updatePermissionShared,
} from 'services/upload'
import { openNotificationWithIcon, truncateString } from 'utils/common.util'
import * as yup from 'yup'
import TagInput from './TagInputValidEmail'

const { TextArea } = Input
const { VIEW, EDIT } = PERMISSION_SHARE
const source = axios.CancelToken.source()

export function ShareToAccountForm({ onCancel, data }) {
  const { t } = useTranslation()
  const { userInfo } = useSelector(userSelector)
  const { reloadAll } = useRouter()
  const selectedFiles = data

  const shareSchema = yup.object().shape({
    receivers: yup
      .array(
        yup
          .string()
          .email(t('unable_to_check_email'))
          .required(t('required_recipient_email')),
      )
      .required(t('required_recipient_email')),
    message: yup.string(),
  })

  const [loading, setLoading] = useState(false)
  const [permission, setPermission] = useState(VIEW)
  const [listPermission, setListPermission] = useState([])
  const [receivers, setReceivers] = useState([])
  const [sharedAccounts, setSharedAccounts] = useState([])
  const [message, setMessage] = useState('')
  const [loadingInit, setLoadingInit] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(true)
  const [isChanged, setIsChanged] = useState(false)
  const [shared, setShared] = useState([])
  const [validation, setValidation] = useState(shareSchema.default())

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setLoadingInit(true)
      const params = {
        name: selectedFiles[0].name,
        path: selectedFiles[0].path,
      }
      getSharedUser(params)
        .then(result => {
          if (result.selectFiles) {
            result.selectFiles.forEach(el => {
              listPermission.push(el.canEdit)
            })
            setShared(result.selectFiles)
          }
          setLoadingInit(false)
        })
        .catch(err => {
          console.log(err)
          setLoadingInit(false)
        })
    }
  }, [])

  const resetForm = () => {}

  const validateSchema = () => {
    const ShareSchema = yup.object().shape({
      receivers: yup
        .array(
          yup
            .string()
            .email(t('sharing_form_verify_fail'))
            .required(t('sharing_form_receivers_required')),
        )
        .required(t('sharing_form_receivers_required')),
      message: yup.string(),
    })

    return ShareSchema.validate(
      {
        receivers,
        message,
      },
      { abortEarly: false },
    )
      .then(res => res)
      .catch(e => {
        const { inner } = e
        let result = validation
        Object.keys(result).map((item, key) => {
          const error = inner.find(i => i.path === item)
          if (error) {
            result = {
              ...result,
              [item]: error.message,
            }
          }
          return result
        })
        setValidation(result)
      })
  }

  const handleCancel = () => {
    onCancel()
    resetForm()
  }

  const handleFoldersSharing = async () => {
    if (receivers.length === 0) {
      setLoading(true)
      if (isChanged) {
        shared.forEach((el, index) => {
          el.canEdit = listPermission[index]
        })
      }
      await toast.promise(
        updatePermissionShared({ selected: shared }),
        {
          pending: t('update_permission_share_pending'),
          success: t('share_files_success'),
          error: t('share_files_fail'),
        },
        {
          containerId: 'alert',
        },
      )
      setLoading(false)
    } else {
      setLoading(true)
      const validate = await validateSchema(t)
      if (validate) {
        if (selectedFiles.length === 0) {
          toast.error(t('share_files_fail'), {
            containerId: 'alert',
          })
          setLoading(false)
          return
        }
        const params = {
          shared_email: receivers,
          shared_accounts: sharedAccounts,
          selected: selectedFiles,
          message,
          senderName: truncateString(userInfo.nick_name) || userInfo.email,
          canEdit: permission === EDIT,
        }
        await toast.promise(
          shareToAccount(params),
          {
            pending: t('update_permission_share_pending'),
            success: t('share_files_success'),
            error: t('share_files_fail'),
          },
          {
            containerId: 'alert',
          },
        )
        onCancel()
        setLoading(false)
      }
    }
    reloadAll(false)
  }

  const handleRemoveShared = async item => {
    setLoading(true)
    await toast.promise(
      removeShared(item),
      {
        pending: t('update_permission_share_pending'),
        success: t('update_permission_share_success'),
        error: t('update_permission_share_error'),
      },
      {
        containerId: 'alert',
      },
    )
    onCancel()
    setLoading(false)
    reloadAll(false)
  }

  const formItemLayout = {
    labelAlign: 'right',
  }
  const permissionMenu = () => {
    return (
      <Menu style={{ width: '200px' }} mode="inline">
        <Menu.Item
          key="view-mode"
          onClick={() => {
            setPermission(VIEW)
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {permission === VIEW ? <CheckOutlined /> : <></>}
            </span>
            <span>{t('role_reader')}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="edit-mode"
          onClick={() => {
            setPermission(EDIT)
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {permission === EDIT ? <CheckOutlined /> : <></>}
            </span>
            <span>{t('role_editor')}</span>
          </div>
        </Menu.Item>
      </Menu>
    )
  }
  const permissionMenu2 = (item, index) => {
    return (
      <Menu style={{ width: '200px' }} mode="inline">
        <Menu.Item
          key="view-mode"
          onClick={() => {
            listPermission[index] = false
            setListPermission(listPermission)
            setIsChanged(true)
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {listPermission[index] === false ? <CheckOutlined /> : <></>}
            </span>
            <span>{t('role_reader')}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="edit-mode"
          onClick={() => {
            listPermission[index] = true
            setListPermission(listPermission)
            setIsChanged(true)
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {listPermission[index] ? <CheckOutlined /> : <></>}
            </span>
            <span>{t('role_editor')}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="remove-mode"
          onClick={() => {
            handleRemoveShared(item)
          }}
        >
          <div>
            <span style={{ width: '25px', display: 'inline-block' }}>
              {permission === 'アクセス権を削除' ? <CheckOutlined /> : <></>}
            </span>
            <span>{t('remove_access')}</span>
          </div>
        </Menu.Item>
      </Menu>
    )
  }

  const onChangeReceivers = list => {
    setSharedAccounts(list)
    setReceivers(list ? list.map(item => item.value) : [])
  }

  const onChangeNote = ({ target: { value } }) => {
    setMessage(value)
  }
  if (loadingInit)
    return (
      <div className="vertically-center" style={{ textAlign: 'center', width: 512, height: 200 }}>
        <Spin indicator={<LoadingOutlined size={24} spin />} />
      </div>
    )

  return (
    <div style={{ width: 512 }}>
      <Form layout="horizontal">
        {isEmailVerified && (
          <>
            <Form.Item
              validateStatus={validation['receivers'] ? 'error' : null}
              help={validation['receivers']}
            >
              <div className="tag-input" style={{ display: 'flex' }}>
                <div style={{ width: '85%' }}>
                  <TagInput
                    // placeholder="d.co"
                    classNamePrefix="ac-data"
                    className={validation['receivers'] ? 'is-invalid' : null}
                    onSubmit={onChangeReceivers}
                    value={receivers}
                  />
                </div>
                <div
                  style={{
                    // border: '1px solid lightgray',
                    textAlign: 'center',
                    padding: '8px',
                    width: '90px',
                    height: '37px',
                    marginLeft: '10px',
                  }}
                >
                  <Dropdown overlay={permissionMenu}>
                    <span>
                      {permission === VIEW ? t('role_reader') : t('role_editor')}{' '}
                      <CaretDownOutlined />
                    </span>
                  </Dropdown>
                </div>
              </div>
            </Form.Item>

            {receivers.length > 0 ? (
              <>
                <Form.Item
                  // label={t('sharing_form_message')}
                  validateStatus={validation['message'] ? 'error' : null}
                  help={validation['message']}
                  {...formItemLayout}
                >
                  <TextArea
                    value={message}
                    onChange={onChangeNote}
                    placeholder={t('sharing_form_message_placeholder')}
                    autoSize={{ minRows: 4, maxRows: 5 }}
                  />
                  {/* {t('sharing_form_note')} */}
                </Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        {receivers.length === 0 && userInfo ? (
          <>
            <p style={{ fontWeight: 'bold' }}>{t('users_with_access')}</p>
            <List
              itemLayout="horizontal"
              dataSource={['dataAuth']}
              renderItem={item => (
                <List.Item className="share-accout-item">
                  <UserInfoWithAvatar
                    name={truncateString(userInfo.email)}
                    email={userInfo.email}
                    avatar={userInfo.avatar}
                  />
                  <div>{t('role_owner')}</div>
                </List.Item>
              )}
            />
            {shared.length > 0 && (
              <>
                <hr style={{ margin: 0 }} />
                <List
                  itemLayout="horizontal"
                  dataSource={shared}
                  renderItem={(item, index) => (
                    <List.Item className="share-accout-item">
                      <UserInfoWithAvatar
                        name={truncateString(item.shared_userName)}
                        email={item.shared_email}
                        avatar={item.shared_avatar}
                      />
                      <div
                        style={{
                          // border: '1px solid lightgray',
                          textAlign: 'end',
                          width: '20%',
                          padding: '8px',
                          height: '37px',
                          marginLeft: '10px',
                        }}
                      >
                        <Dropdown overlay={permissionMenu2(item, index)}>
                          <span>
                            {listPermission[index] ? t('role_editor') : t('role_reader')}{' '}
                            <CaretDownOutlined />
                          </span>
                        </Dropdown>
                      </div>
                    </List.Item>
                  )}
                />
              </>
            )}
          </>
        ) : (
          <></>
        )}
        <Form.Item style={{ marginTop: 22, marginBottom: 0, textAlign: 'end' }}>
          <Button
            type="default"
            className="mr-3"
            onClick={() => {
              handleCancel()
            }}
          >
            {t('cancel_button')}
          </Button>
          {isEmailVerified && (
            <Button
              type="primary"
              onClick={handleFoldersSharing}
              loading={
                loading ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> } : false
              }
            >
              {t('share_btn')}
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}

export default ShareToAccountForm
