import { createSlice } from '@reduxjs/toolkit'
import { getStorage } from './storageActions'

const initialState = {
  loading: false,
  storage: 0,
  error: null,
  success: false,
}

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {},
  extraReducers: {
    [getStorage.pending]: state => {
      state.loading = true
      state.error = true
      state.storage = 0
    },
    [getStorage.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.error = false
      state.storage = payload.currentStorage
    },
    [getStorage.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = true
      state.storage = 0
    },
  },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = storageSlice
// export const { logout } = actions
export default reducer
