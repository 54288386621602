import { TypeDrag } from 'config/constant'
import { useDragLayer } from 'react-dnd'
import React from 'react'
import { selectedFileSelector } from 'redux/selectors'
import { useSelector } from 'react-redux'
import CardsDragPreview from './CardsDragPreview'
import RowDragPreview from './RowsDragPreview'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 111100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}
const getItemStyles = (currentOffset, clientOffset) => {
  if (!currentOffset) {
    return {
      display: 'none',
    }
  }
  const { x, y } = clientOffset
  return {
    transform: `translate(${x - 40}px, ${y - 40}px)`,
  }
}
export const CustomDragLayer = () => {
  const { selectedFiles } = useSelector(selectedFileSelector)
  const ppp = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  const { itemType, isDragging, item, clientOffset, currentOffset } = ppp
  function renderItem() {
    switch (itemType) {
      case TypeDrag.CARD:
        return <CardsDragPreview selectedFiles={selectedFiles} />
      case TypeDrag.ROW:
        return <RowDragPreview item={item} />
      default:
        return null
    }
  }
  if (!isDragging) {
    return null
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset, clientOffset)}>{renderItem(itemType, item)}</div>
    </div>
  )
}

export default { CustomDragLayer }
