import _path from 'path'
import {
  DEFAULT_FOLDER_PATH,
  FREEBIE_FOLDER,
  FREEBIE_FOLDER_URL,
  MY_FOLDER,
  MY_FOLDER_URL,
  SHARED_FOLDER,
  SHARED_FOLDER_URL,
  TRASH_BIN,
  TRASH_BIN_URL,
} from 'config/constant'

export const parseUrl = url => {
  const result = {
    modeFolder: MY_FOLDER,
    currentFolder: '/',
    inTrashBin: false,
    inMyFolder: false,
    inSharedFolder: false,
    inFreebieFolder: false,
  }
  try {
    url = decodeURIComponent(url)
    if (isUgly(url)) return result
    if (url.startsWith(MY_FOLDER_URL)) {
      result.inMyFolder = true
      result.modeFolder = MY_FOLDER
      result.currentFolder = verifyPath(removeSlash(url.replace(MY_FOLDER_URL, '')))
    } else if (url.startsWith(SHARED_FOLDER_URL)) {
      result.inSharedFolder = true
      result.modeFolder = SHARED_FOLDER
      result.currentFolder = verifyPath(removeSlash(url.replace(SHARED_FOLDER_URL, '')))
    } else if (url.startsWith(TRASH_BIN_URL)) {
      result.inTrashBin = true
      result.modeFolder = TRASH_BIN
      result.currentFolder = verifyPath(removeSlash(url.replace(TRASH_BIN_URL, '')))
    } else if (url.startsWith(FREEBIE_FOLDER_URL)) {
      result.inFreebieFolder = true
      result.modeFolder = FREEBIE_FOLDER
      result.currentFolder = verifyPath(removeSlash(url.replace(FREEBIE_FOLDER_URL, '')))
    }
    return result
  } catch (e) {
    console.log(e)
    return result
  }
}

export const badPath = path => {
  if (path.includes('/') || path.includes('\\')) return true
  return false
}

export const isUgly = value => {
  return value === undefined || value === '' || value === null
}
export const notUgly = value => {
  return value !== undefined && value !== '' && value !== null
}

export function removeTrailingSlash(str) {
  return str.replace(/\/+$/, '')
}
export function removeLeadingSlash(str) {
  return str.replace(/^\/+/g, '')
}

export function removeSlash(str) {
  str = removeLeadingSlash(str)
  str = removeTrailingSlash(str)
  return str
}
export const verifyPath = path => {
  if (isUgly(path)) return DEFAULT_FOLDER_PATH
  path = removeLeadingSlash(path)
  path = removeTrailingSlash(path)
  path = _path.join(path, '/')
  return path
}

export function equalPath(srcPath, destPath) {
  srcPath = removeLeadingSlash(srcPath)
  srcPath = removeTrailingSlash(srcPath)
  destPath = removeLeadingSlash(destPath)
  destPath = removeTrailingSlash(destPath)
  return srcPath === destPath
}

export default { isUgly, verifyPath, notUgly }
