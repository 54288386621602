import React from 'react'
import { Trans, useTranslation, withTranslation } from 'react-i18next'
import { isMacOS } from 'utils/common.util'

function TemplateSectionBelow() {
  const { t } = useTranslation()

  return (
    <div>
      <section style={{ padding: '60px 0 60px 0' }}>
        <div className="container">
          <div className="row feature-item">
            <div className="col-lg-6" style={{ visibility: 'visible' }}>
              <img src="/resources/images/data-section-1.webp" className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-2 pt-lg-0"
              style={{
                visibility: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="paragraph-lh">
                <h4 className="header-lh">{t('section_below_1_title')}</h4>
                <h4 className="header-lh">{t('section_below_1_title_1')}</h4>
                <p className="pb-2 m-0">{t('section_below_1_content_1')}</p>
                <p className="pb-2 m-0">{t('section_below_1_content_2')}</p>
                <p className="pb-2 m-0">{t('section_below_1_content_3')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-bg" style={{ padding: '60px 0 60px 0' }}>
        <div className="container">
          <div className="row feature-item">
            <div className="col-lg-6 order-1 order-lg-2" style={{ visibility: 'visible' }}>
              <picture>
                {/* <source srcSet="/resources/images/data-section-1.webp" type="image/webp" /> */}
                <img
                  decoding="async"
                  loading="lazy"
                  src="/resources/images/data-section-2.webp"
                  className="img-fluid"
                  alt=""
                />
              </picture>
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1"
              style={{
                visibility: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div className="paragraph-lh">
                <h4 className="header-lh">{t('section_below_2_title')}</h4>
                <p
                  className="pb-2 m-0"
                  dangerouslySetInnerHTML={{
                    __html: t('section_below_2_content_1'),
                  }}
                />
                <p className="pb-2 m-0">{t('section_below_2_content_2')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: '60px 0 60px 0' }}>
        <div className="container">
          <div className="row feature-item">
            <div className="col-lg-6" style={{ visibility: 'visible' }}>
              <img src="/resources/images/data-section-3.webp" className="img-fluid" alt="" />
            </div>
            <div
              className="col-lg-6 pt-2 pt-lg-0"
              style={{
                visibility: 'visible',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Trans>
                <div className="paragraph-lh">
                  <h4 className="header-lh">{t('section_below_3_title')}</h4>
                  {/* <h4 className="header-lh">{t('section_below_1_title_1')}</h4> */}
                  <p className="pb-2 m-0">{t('section_below_3_content_1')}</p>
                  <p className="pb-2 m-0">{t('section_below_3_content_2')}</p>
                  <p className="pb-2 m-0">{t('section_below_3_content_3')}</p>
                  <p className="pb-2 m-0">{t('section_below_3_content_4')}</p>
                  <p className="pb-2 m-0">{t('section_below_3_content_5')}</p>
                </div>
              </Trans>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: '30px 0 30px 0', justifyContent: 'center' }}>
        <div className="row bookmark-description">
          {t('bookmark_if')}
          <div className="bookmark-key">{isMacOS() ? 'Cmd ⌘' : 'Ctrl'}</div> +{' '}
          <div className="bookmark-key">D</div>
          {t('bookmark_then')}
        </div>
      </section>
    </div>
  )
}

export default TemplateSectionBelow
