import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  autoLoginWithSSO,
  getUsersByTokenCookies,
  isPremium,
  login,
  logout,
  validatePremiumUser,
} from 'services/users/user.services'
import { getCookie, isUgly, notUgly } from 'utils'
import { USER_TYPE } from 'config/constant'

export const userLogin = createAsyncThunk(
  'user/login',
  async ({ ssoToken, globalSessionId }, { rejectWithValue }) => {
    let user
    try {
      if (isUgly(localStorage.getItem('u_login'))) {
        localStorage.removeItem('uuid')
      }
      console.log('user/login thunk')
      if (ssoToken) {
        user = await login(ssoToken)
        console.log('user login: ', user)
      } else if (globalSessionId) {
        user = await autoLoginWithSSO(globalSessionId)
        console.log('auto sso: ', user)
      } else {
        user = await getUsersByTokenCookies(getCookie('global_session_id'))
      }

      if (user) {
        localStorage.removeItem('u_login')
        user.type = USER_TYPE.LOGIN
        user.isPremium = await isPremium(user?.user_id)
        user.isAuth = true
        return user
      }
    } catch (error) {
      console.log(error)
    }
    localStorage.setItem('u_login', USER_TYPE.FREE_NOT_LOGIN)
    const freeUser = { type: USER_TYPE.FREE_NOT_LOGIN, isPremium: false, isAuth: false }
    return freeUser
  },
)

export const userLogout = createAsyncThunk('user/logout', async (args, { rejectWithValue }) => {
  try {
    return await logout()
  } catch (error) {
    return rejectWithValue('error')
  }
})

export const getUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState()
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`,
        },
      }
      const { data } = await axios.get(`/api/user/profile`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      }
      return rejectWithValue(error.message)
    }
  },
)
