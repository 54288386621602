import React from 'react'
import { Breadcrumb, Button, Card, Menu, Progress, Tooltip } from 'antd'
import { useTranslation, withTranslation } from 'react-i18next'
import config from 'config'
import { useSelector } from 'react-redux'
import { userSelector } from 'redux/selectors'
import { USER_TYPE } from 'config/constant'

function AlertFreeUser() {
  const { userInfo } = useSelector(userSelector)
  const { t } = useTranslation()
  const userType = userInfo.type
  if (userInfo.isPremium) return null
  return (
    <div style={{ alignSelf: 'center' }}>
      <Card
        style={{
          marginTop: 15,
          padding: '12px 8px 30px 12px',
          backgroundColor: '#f1f4f8',
          maxWidth: 220,
        }}
      >
        {userType === USER_TYPE.FREE_NOT_LOGIN && (
          <>
            {t('login_notice_not_user')}
            <div
              style={{
                marginTop: 15,
                textAlign: 'center',
                paddingBottom: 0,
              }}
            >
              <Button
                type="primary"
                size="large"
                style={{ width: 120 }}
                href={config.sso.signUpUrl}
              >
                <span style={{ fontSize: 14 }}>{t('signup_button')}</span>
              </Button>
            </div>
          </>
        )}

        {userType === USER_TYPE.LOGIN && (
          <>
            <div
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              <span style={{ fontSize: 14 }}>{t('login_notice_free_user_title')}</span>
            </div>
            <div>
              {t('login_notice_free_user_content_1')}
              <a href={config.acworks.illustAC} style={{ color: '#596eee', fontSize: 14 }}>
                {t('illustAC')}
              </a>
              {t('comma_symbol')}
              <a href={config.acworks.photoAC} style={{ color: '#596eee', fontSize: 14 }}>
                {t('photoAC')}
              </a>
              {t('comma_symbol')}
              <a href={config.acworks.videoAC} style={{ color: '#596eee', fontSize: 14 }}>
                {t('videoAC')}
              </a>
              {t('comma_symbol')}
              <a href={config.acworks.silhouetteAC} style={{ color: '#596eee', fontSize: 14 }}>
                {t('silhouetteAC')}
              </a>
              <span style={{ fontSize: 14 }}>{t('login_notice_free_user_content_2')}</span>
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

export default AlertFreeUser
