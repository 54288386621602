import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import config from 'config'

function TrialAlertModal({ open, onCancel }) {
  const { t } = useTranslation()
  return (
    <Modal
      title={t('notice_title')}
      centered
      open
      onOk={() => {
        // onOk()
        // setOpenAlert(false)
        const link = document.createElement('a')
        link.href = config.sso.loginUrl
        document.body.appendChild(link)
        return link.click()
      }}
      onCancel={async () => {
        onCancel()
        // await resetAfterUpload()
        // setOpenAlert(false)
      }}
      okText={t('login_button')}
      cancelText={t('cancel_button')}
      // cancelButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{ paddingBottom: 0, width: '45vw', paddingTop: 10 }}
    >
      <p style={{ margin: 0 }}>
        {t('notice_content_1')}
        {t('notice_content_2')}
        {t('notice_content_3')}
      </p>
    </Modal>
  )
}

export default TrialAlertModal
