import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import appEN from 'locales/en/app.json'
import modalEN from 'locales/en/modal.json'
import notificationEN from 'locales/en/notification.json'

import modalJA from 'locales/ja/modal.json'
import appJA from 'locales/ja/app.json'
import notificationJA from 'locales/ja/notification.json'

const lang = localStorage.getItem('lang')
if (!lang) {
  localStorage.setItem('lang', 'ja')
}

const resources = {
  en: {
    app: appEN,
    modal: modalEN,
    notification: notificationEN,
  },
  ja: {
    app: appJA,
    modal: modalJA,
    notification: notificationJA,
  },
}

i18n.use(initReactI18next).init({
  resources,
  defaultNS: 'app',
  lng: localStorage.getItem('lang'),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  transSupportBasicHtmlNodes: true,
  transKeepBasicHtmlNodesFor: ['b', 'br'],
})

export default i18n
