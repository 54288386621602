import { Avatar, Button } from 'antd'
import React from 'react'
import Img from 'react-image'
import Ripples from 'react-ripples'
import { isUgly } from 'utils'
import _path from 'path'
import config from 'config'
import { useTranslation } from 'react-i18next'
import { DEFAULT_ICON_PROFILE, DEFAULT_ICON_PROFILE_URL } from 'config/constant'

export const getAvatar = avatar => {
  const path = isUgly(avatar) ? DEFAULT_ICON_PROFILE : avatar
  return _path.join(config.sso.resourceUrl, path)
}
export function UserInfoWithAvatar({ avatar, name, email }) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ height: 35, width: 35 }}>
        <Img
          src={getAvatar(avatar)}
          style={{
            borderRadius: '50%',
            height: 36,
            width: 35,
          }}
          unloader={
            <Avatar
              shape="circle"
              size={50}
              style={{
                borderRadius: '50%',
                height: 35,
                width: 35,
              }}
              src={DEFAULT_ICON_PROFILE_URL}
            />
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          marginLeft: 6,
        }}
      >
        {/* change text */}
        <span>{isUgly(name) ? t('user_not_login') : name}</span>
        <span
          style={{
            fontSize: 11,
            display: 'flex',
            textTransform: 'none',
            color: 'grey',
          }}
        >
          {email}
        </span>
      </div>
    </div>
  )
}

export default { UserInfoWithAvatar }
