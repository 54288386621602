import { Card, List } from 'antd'
import CoverImage from 'components/CoverImage'
import EditableName from 'components/EditableName'
import { TypeDrag, VIEW } from 'config/constant'
import { setIsDrag } from 'features/manager/managerSlice'
// import { useHandleAction, useHandleKeyboard, useHandlePermission, useRouter } from 'hooks'
import { useHandleMouse } from 'hooks'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'

const { Meta } = Card

function FileCard({ fileItem, viewSize }) {
  const dispatch = useDispatch()
  const { handleClick, handleContextMenu, handleDoubleClick, handleDragStart } = useHandleMouse()
  const { selected, isDrag } = useSelector(managerSelector)

  const isSelected = selected.includes(fileItem.key)

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: TypeDrag.CARD,
    item: fileItem,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    dispatch(setIsDrag(isDragging))
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const sizeClass = viewSize === VIEW.SIZE.SMALL ? ' card-small' : ' card-large'
  const className = `file-card ${isSelected ? 'card-selected' : ''} ${
    isSelected && isDrag ? 'item-dragging' : ''
  } ${sizeClass}`

  return (
    <List.Item className="item-ref" data-row-key={fileItem.key}>
      <div
        aria-hidden
        ref={dragRef}
        className={className}
        onDoubleClick={() => handleDoubleClick(fileItem)}
        onContextMenu={() => handleContextMenu(fileItem)}
        onClick={() => handleClick(fileItem)}
        onDragStart={() => handleDragStart(fileItem)}
      >
        <CoverImage viewSize={viewSize} fileItem={fileItem} />
      </div>
      <EditableName fileItem={fileItem} />
    </List.Item>
  )
}
export default FileCard
