/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { genURL, getFileUrlForView, getUUID } from 'services/upload'
import _path from 'path'
import { useHandleKeyboard, useHandleScroll } from 'hooks'
import './components/style.css'
import LayoutPreview from './components/layout'
import { Ai, Audio, Doc, Image, NotSupport, Txt, Video, Pdf } from './components/support-types'

import LoadingPreview from './components/Loading'

const FilePreviewModal = ({ onCancel, data }) => {
  const [URL, setURL] = useState('')
  const { escPress } = useHandleKeyboard()
  const [fileType, setFileType] = useState('unkown')
  const [blockScroll, allowScroll] = useHandleScroll()

  useEffect(() => {
    blockScroll()
  })
  useEffect(() => {
    if (escPress) onClose()
  })

  useEffect(() => {
    const selectedFile = data
    setFileType(
      _path
        .extname(selectedFile.name)
        .slice(1)
        .toLowerCase(),
    )
    genURL(selectedFile).then(url => {
      setURL(url)
    })
  }, [data])

  let bodyPreview = <LoadingPreview />

  const onClose = () => {
    onCancel()
    allowScroll()
  }
  if (URL.length > 0) {
    if (
      fileType === 'png' ||
      fileType === 'jpg' ||
      fileType === 'jpeg' ||
      fileType === 'gif' ||
      fileType === 'bmp'
    )
      bodyPreview = <Image URL={URL} />
    else if (fileType === 'pdf') {
      bodyPreview = <Pdf URL={URL} />
    } else if (fileType === 'ai') bodyPreview = <Ai URL={URL} />
    else if (fileType === 'txt' || fileType === 'csv') {
      bodyPreview = <Txt URL={URL} />
    } else if (
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'xls' ||
      fileType === 'xlsx' ||
      fileType === 'ppt' ||
      fileType === 'pptx'
    )
      bodyPreview = <Doc URL={URL} />
    else if (fileType === 'mov' || fileType === 'mp4' || fileType === 'webm' || fileType === 'avi')
      bodyPreview = <Video URL={URL} />
    else if (fileType === 'mp3' || fileType === 'm4a' || fileType === 'wav')
      bodyPreview = <Audio URL={URL} />
    else bodyPreview = <NotSupport URL={URL} />
  }

  return (
    <LayoutPreview data={data} URL={URL} onCancel={onClose}>
      {bodyPreview}
    </LayoutPreview>
  )
}

export default FilePreviewModal
