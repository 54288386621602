import React from 'react'
import { useTranslation } from 'react-i18next'

export function AlertAutoDelete() {
  const { t } = useTranslation()
  return (
    <div
      style={{
        height: '30px',
        background: 'rgb(241, 243, 244)',
        textAlign: 'center',
        padding: '3px',
      }}
    >
      {t('trash_noti')}
    </div>
  )
}

export default AlertAutoDelete
