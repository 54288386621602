import { Icon } from '@ant-design/compatible'
import { AppstoreOutlined, MenuOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { VIEW } from 'config/constant'
import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { viewSelector } from 'redux/selectors'
import { GridLarge, GridSmall, ListLarge, ListSmall } from './type_icon'
import { setView } from './viewTypeSlice'

function ViewType() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { viewType, viewSize } = useSelector(viewSelector)

  const handleViewTypeClick = value => {
    localStorage.setItem('view_type', value.type)
    localStorage.setItem('view_size', value.size)
    dispatch(setView(value))
  }

  const ItemContent = ({ icon, title }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ height: 24, width: 24 }}> {icon}</div>
        <div style={{ height: 24, padding: '2px 4px' }}>{title}</div>
      </div>
    )
  }
  const menuViewType = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            handleViewTypeClick({ type: VIEW.TYPE.GRID, size: VIEW.SIZE.SMALL })
          }}
        >
          <ItemContent title={t('grid_view')} icon={GridSmall} />
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleViewTypeClick({ type: VIEW.TYPE.GRID, size: VIEW.SIZE.LARGE })
          }}
        >
          <ItemContent title={`${t('grid_view')} ${t('view_icon_size_large')}`} icon={GridLarge} />
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleViewTypeClick({ type: VIEW.TYPE.LIST, size: VIEW.SIZE.SMALL })
          }}
        >
          <ItemContent title={t('table_view')} icon={ListSmall} />
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleViewTypeClick({ type: VIEW.TYPE.LIST, size: VIEW.SIZE.LARGE })
          }}
        >
          <ItemContent title={`${t('table_view')} ${t('view_icon_size_large')}`} icon={ListLarge} />
        </Menu.Item>
      </Menu>
    )
  }

  const CurrentIcon = () => {
    if (viewType === VIEW.TYPE.GRID) {
      if (viewSize === VIEW.SIZE.LARGE) return GridLarge
      if (viewSize === VIEW.SIZE.SMALL) return GridSmall
    }
    if (viewType === VIEW.TYPE.LIST) {
      if (viewSize === VIEW.SIZE.LARGE) return ListLarge
      if (viewSize === VIEW.SIZE.SMALL) return ListSmall
    }
    return <></>
  }
  return (
    <Dropdown overlay={menuViewType} trigger={['click']}>
      <Button
        style={{
          height: 32,
          marginRight: 8,
        }}
        disabled
        icon={
          <div style={{ height: 24 }}>
            <CurrentIcon />
          </div>
        }
        className="btn-hover-border"
      />
    </Dropdown>
  )
}

export default ViewType
