import axios from 'axios'
import { config } from 'config'

const handleSuccessRequest = response => ({
  status: response.status,
  data: response.data,
})
const handleErrorRequest = e => ({
  status: e.status,
})

export const getSessionUser = async globalSessionToken => {
  try {
    const response = await axios.get(
      `${config.sso.serverUrl}/get-session-user?globalSessionToken=${globalSessionToken}`,
      {
        timeout: 2000,
      },
    )
    return handleSuccessRequest(response)
  } catch (e) {
    return handleErrorRequest(e)
  }
}

export const logoutSession = async globalSessionToken => {
  try {
    const response = await axios.get(
      `${config.sso.serverUrl}/logout?sessionID=${globalSessionToken}&serviceURL=${config.sso.redirect}`,
      {
        timeout: 2000,
      },
    )
    return handleSuccessRequest(response)
  } catch (e) {
    return handleErrorRequest(e)
  }
}

export const verifyUser = ssoToken => {
  return axios
    .get(`${config.sso.serverUrl}/verifytoken?ssoToken=${ssoToken}`, {
      headers: {
        Authorization: `Bearer ${config.sso.appToken}`,
      },
    })
    .then(handleSuccessRequest)
    .catch(handleErrorRequest)
}

export default {
  getSessionUser,
  verifyUser,
  logoutSession,
}
