import { createSlice } from '@reduxjs/toolkit'
import { getCookie } from 'utils'
import { getFolders } from './folderActions'

const initialState = {
  loading: false,
  error: null,
  success: false,
  folders: [],
  trees: [],
}

const folderSlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [getFolders.pending]: state => {
      state.loading = true
      state.error = true
    },
    [getFolders.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.error = false
      state.trees = payload.trees
      state.folders = payload.folders
      // state.userToken = payload.userToken
    },
    [getFolders.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = true
      state.folders = []
      state.trees = []
    },
  },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = folderSlice
export const { logout } = actions
export default reducer
