import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { logout } from 'services/users/user.services'
import { getCookie, listToTree } from 'utils'
import { getFolderStructure } from 'services/upload'

export const getFolders = createAsyncThunk('folders/get', async (args, { rejectWithValue }) => {
  try {
    const data = await getFolderStructure();
    // const filters = data.folders.filter(el => !el.includes('メルマガ素材'))
    const trees = listToTree(data.folders)
    return { trees, folders: data.folders }
  } catch (error) {
    return rejectWithValue('error')
  }
})

export default {
  getFolders,
}
