import { createSelector } from '@reduxjs/toolkit'
import { SORT_BY_ORDER, SORT_BY_TYPE } from 'config/constant'

export const userSelector = state => state.user
export const viewSelector = state => state.viewType
export const storageSelector = state => state.storage
export const fileSelector = state => state.file
export const treeFolderSelector = state => state.folder
export const routerSelector = state => state.router
export const modalSelector = state => state.modal
export const managerSelector = state => state.manager
export const uploaderSelector = state => state.uploader
export const filterSelector = state => state.filter
export const sortSelector = state => state.sort

export const dataSourceSelector = state => {
  const { files, folders, loading } = state.file
  const dataSource = files.concat(folders)
  return { dataSource, loading }
}

// export const fileSelector = state => state.file

export const fileSelectorWithFilter = createSelector(
  fileSelector,
  filterSelector,
  sortSelector,
  (file, filter, sort) => {
    const { files, folders, empty, loading } = file
    const { search } = filter
    const { type, order } = sort
    let dataSource = folders.concat(files)
    if (search !== '') {
      dataSource = dataSource.filter(v => v.name?.toLowerCase().includes(search.toLowerCase()))
    }

    let filterResult = []
    if (type === SORT_BY_TYPE.NAME) {
      if (order === SORT_BY_ORDER.DESC) {
        filterResult = dataSource.sort((a, b) => {
          const x = a.name.toLowerCase()
          const y = b.name.toLowerCase()
          if (x < y) {
            return -1
          }
          if (x > y) return 1
          return 0
        })
      } else {
        filterResult = dataSource.sort((a, b) => {
          const x = a.name.toLowerCase()
          const y = b.name.toLowerCase()
          if (x < y) {
            return 1
          }
          if (x > y) return -1
          return 0
        })
      }
    } else if (type === SORT_BY_TYPE.LAST_MODIFIDED) {
      if (order === SORT_BY_ORDER.DESC)
        filterResult = dataSource.sort((a, b) => {
          if (a.type === 'file') return new Date(a.lastModified) - new Date(b.lastModified)
          return 0
        })
      else
        filterResult = dataSource.sort((b, a) => {
          if (a.type === 'file') return new Date(a.lastModified) - new Date(b.lastModified)
          return 0
        })
    }

    return {
      filterResult,
      empty,
      loading,
    }
  },
)

export const selectedFileSelector = createSelector(
  fileSelector,
  managerSelector,
  (file, manager) => {
    const { files, folders } = file
    const { selected } = manager
    const selectedFiles = files.concat(folders).filter(el => selected.includes(el.key))
    return { selectedFiles }
  },
)

export default {
  userSelector,
  storageSelector,
  fileSelector,
  routerSelector,
  filterSelector,
  uploaderSelector,
  selectedFileSelector,
}
