import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import { Menu, Dropdown, Avatar, Badge } from 'antd'
import './style.css'
import config from 'config'

function ListApp({ lang }) {
  const [headerData, setHeaderData] = useState('')

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      const response = await axios.get(`${config.sso.listApp}?language=${lang}`)
      const { data } = response
      setHeaderData(data)
    }
    fetchData()
    return () => {
      abortController.abort()
    }
  }, [lang])

  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={
          <div
            style={{ background: 'white' }}
            className="ac-dropdown-apps p-0 m-0 border border shadow-lg ac-mt-1 show"
            dangerouslySetInnerHTML={{ __html: headerData }}
          />
        }
        // trigger={['click']}
      >
        <div style={{ paddingTop: 6 }}>
          <svg width="28" height="28" focusable="false" viewBox="0 0 24 24" fill="gray">
            <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z" />
          </svg>
        </div>
      </Dropdown>
    </>
  )
}

export default ListApp
