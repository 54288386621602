import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Modal } from 'antd'

function MailMagaAlertModal({ open, onCancel, onOk }) {
  const { t } = useTranslation()

  return (
    <Modal
      onOk={onOk}
      title={t('mail_maga_alert_title')}
      centered
      open
      // visible={isOpenMailMagaAlert}
      onCancel={onCancel}
      okText={t('close_button')}
      cancelButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <p>{t('mail_maga_alert_content')}</p>
    </Modal>
  )
}

export default MailMagaAlertModal
