import React from 'react'
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom'
import { LayoutApp } from 'layouts'
import routers from './router_config'

export function RouterApp() {
  return (
    <Router>
      <Routes>
        {routers.map((route, index) => {
          const App = route.component
          const Page = (
            <LayoutApp path={route.path}>
              <App />
            </LayoutApp>
          )
          return (
            <Route
              key={'ac-router-'.concat(index)}
              path={route.path}
              exact={route.exact}
              element={Page}
            />
          )
        })}
      </Routes>
    </Router>
  )
}

export default { RouterApp }
