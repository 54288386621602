import { createSlice } from '@reduxjs/toolkit'
import { calPercentUpload, updateChunks } from 'utils/upload.util'

const initialState = {
  list: [],
  allFilePercent: [],
  uploadedOK: [],
  uploadedERROR: [],
}

const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {
    setAllFilePercents: (state, action) => {
      // state.allFilePercent.push(action.payload)
    },
    setUploadedOk: (state, action) => {
      state.uploadedOK.push(action.payload)
    },
    updateAllChunks: (state, action) => {
      const { name, percent } = action.payload
      // if (percent === 100) {
      //   state.uploadedOK.push(name)
      // }
      const file = state.allFilePercent.filter(e => e.name === name)
      if (file.length > 0) {
        file[0].percent = percent
      } else {
        state.allFilePercent.push(action.payload)
      }
      // state.allFilePercent = updateChunks({
      //   allFilePercent: state.allFilePercent,
      //   chunks: action.payload,
      // })
    },
    updateAllPercents: (state, action) => {
      state.allFilePercent = calPercentUpload({
        allFilePercent: state.allFilePercent,
        status: action.payload,
      })
    },
    setUploadProgress: (state, action) => {},
    successUploadFile: (state, action) => {},
    failureUploadFile: (state, action) => {},
    setUploadeds: (state, action) => {
      state.uploaded = state.uploaded.concat(action.payload)
    },
    setLists: (state, action) => {
      state.list = state.list.concat(action.payload)
    },
    reset: (state, action) => {
      state.list = []
      state.allFilePercent = []
      state.uploaded = []
    },
  },
})

const { reducer, actions } = uploaderSlice
export const {
  setAllFilePercents,
  updateAllPercents,
  updateAllChunks,
  setUploadeds,
  setUploadedOk,
  setLists,
  reset,
  setUploadProgress,
  successUploadFile,
  failureUploadFile,
} = actions
export default reducer
