import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createFolderExistedUser,
  createFolderNewUser,
  checkDuplicateFolder,
  updateUserSSOId,
} from 'services/upload'
import _path from 'path'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Modal, Spin } from 'antd'

import { DEFAULT_FOLDER_PATH } from 'config/constant'
import { useRouter } from 'hooks'
import { toast } from 'react-toastify'
import { badPath, removeLeadingSlash } from 'utils'

function AddFolderModal({ onCancel }) {
  const [newFolderName, setNewFolderName] = useState('')
  const { t } = useTranslation()
  const { reloadAll, currentFolder } = useRouter()

  const handleNewFolder = async () => {
    if (!newFolderName) {
      toast.error(t('required_error_message'), {
        containerId: 'alert',
      })
      return
    }
    if (badPath(newFolderName)) {
      toast.error(t('create_folder_invalid_name'), {
        containerId: 'alert',
      })
      return
    }
    onCancel()
    const newPath = _path.join(currentFolder, newFolderName, DEFAULT_FOLDER_PATH)
    const data = {
      path: removeLeadingSlash(newPath),
    }

    const duplicate = await checkDuplicateFolder({
      newName: newFolderName,
      currentName: '',
      path: `${newPath}`,
    })
    if (duplicate.status === 'ok') {
      await onSubmitCreateFolder(data)
    } else if (duplicate.status === 'existed') {
      toast.error(
        `${t('duplicate_alert_content_1')} ${newFolderName} ${t('duplicate_alert_content_2')}`,
        {
          containerId: 'alert',
        },
      )
    }
  }
  const onSubmitCreateFolder = async data => {
    // await createFolderExistedUser(data)
    await toast.promise(
      handleCreateFolder(data),
      {
        pending: t('create_folder_pending'),
        success: t('create_folder_success'),
        error: t('create_folder_fail'),
      },
      {
        containerId: 'alert',
      },
    )
    reloadAll()
  }

  const handleCreateFolder = async data => {
    let result = {}
    if (
      localStorage.getItem('uuid') &&
      localStorage.getItem('uuid') !== 'undefined' &&
      localStorage.getItem('uuid') !== 'null'
    ) {
      result = await createFolderExistedUser(data)
    } else {
      result = await createFolderNewUser(data)
      const { id } = result
      localStorage.setItem('uuid', id)
    }
    return result
  }

  const handleFolderNameChange = e => {
    setNewFolderName(e.target.value)
  }
  return (
    <Modal
      title={t('modal:conform_new_folder_title')}
      centered
      open
      onOk={handleNewFolder}
      onCancel={onCancel}
      okButtonProps={{ disabled: !newFolderName }}
      okText={t('modal:conform_new_folder_button')}
      cancelText={t('modal:cancel_button')}
    >
      <Input
        placeholder={t('add_folder')}
        value={newFolderName}
        // onPressEnter={handleNewFolder}
        onChange={handleFolderNameChange}
        required
        allowClear
        autoFocus
        style={{ width: '420px', height: 32 }}
      />
    </Modal>
  )
}

export default AddFolderModal
