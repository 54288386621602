import React, { useState } from 'react'

export function Doc({ URL }) {
  console.log(URL.toString())
  const src = encodeURIComponent(URL.toString())
  //  const view = `https://view.officeapps.live.com/op/embed.aspx?src=${src}`
  // const view = `https://docs.google.com/gview?url=${src}`
  const view = `https://docs.google.com/viewer?embedded=true&url=${src}`
  // const view = `https://sheet.zoho.com/sheet/view.do?url=${(URL.toString())}`
  const iframee = `<iframe 
  sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation" 
  width="100%" height="100%"
  style="position:"relative"; display="initial"; frameBorder="0" " scrolling="no" title="fx." 
  src="${view}" 
  frameborder="no" allowtransparency="true" allowfullscreen="true">Fullscreen <a href="${view}"></iframe>`

  const Iframe = propsFrame => {
    const { iframe } = propsFrame
    return <div style={{ height: 'inherit' }} dangerouslySetInnerHTML={{ __html: iframe }} />
  }

  return (
    <div className="iframe" style={{ height: '94vh' }}>
      <Iframe iframe={iframee} />
      <div
        style={{
          width: 40,
          height: 40,
          position: 'absolute',
          background: 'grey',
          opacity: 0,
          right: 12,
          top: 66,
        }}
      >
        &nbsp;
      </div>
    </div>
  )
}

export default Doc
