import { Tooltip } from 'antd'
import { truncate } from 'utils'
import React from 'react'

export function EditableName({ fileItem }) {
  return (
    <div className="card-title">
      <Tooltip placement="bottomLeft" title={fileItem.name} arrowPointAtCenter>
        <span> {truncate(fileItem.name, 15)}</span>
      </Tooltip>
    </div>
  )
}

export default EditableName
