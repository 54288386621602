import { createSlice } from '@reduxjs/toolkit'
import { USER_TYPE } from 'config/constant'
import { getCookie } from 'utils'
import { getUserDetails, userLogin, userLogout } from './userActions'

const initialState = {
  loading: false,
  userInfo: { type: USER_TYPE.FREE_NOT_LOGIN, isPremium: false, isAuth: false },
  error: null,
  success: false,
  isAuth: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload
      if (action.payload) state.isAuth = true
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: state => {
      state.loading = true
      state.error = null
      state.isAuth = false
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      if (payload?.isAuth) state.isAuth = true
      // state.userToken = payload.userToken
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.isAuth = false
    },

    // logout user
    [userLogout.pending]: state => {
      state.loading = true
      state.userInfo = {}
      state.error = null
    },
    [userLogout.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = {}
      state.error = null
      // state.userToken = payload.userToken
    },
    [userLogout.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    // get user details
    [getUserDetails.pending]: state => {
      state.loading = true
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.loading = false
    },
  },
})

// export const { logout } = userSlice.actions

const { reducer, actions } = userSlice
export const { setUser } = actions
export default reducer
