import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileSyncOutlined,
} from '@ant-design/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectedFileSelector } from 'redux/selectors'
import { useHandleAction } from 'hooks'

import { ItemMenu } from './ItemMenu'

export function FreebieContext({ controls }) {
  const { selectedFiles } = useSelector(selectedFileSelector)
  const { handlePreview, handleDownload, handleRename, handleConfirmDelete } = useHandleAction()

  if (selectedFiles.length === 0) return null
  // only show when select one file to preview/rename
  const selectOne = selectedFiles.length === 1

  // check isFile
  const isFileItem = selectedFiles[0]?.type === 'file'
  if (selectedFiles.length === 0) return null
  return (
    <div className="context-menu">
      <ItemMenu
        display={selectOne && isFileItem}
        icon={<EyeOutlined />}
        name="list_file_preview"
        handle={handlePreview}
        controls={controls}
      />
      <ItemMenu
        icon={<DownloadOutlined />}
        name="download_button"
        handle={handleDownload}
        controls={controls}
      />
      <ItemMenu
        display={selectOne}
        icon={<EditOutlined />}
        name="rename_button"
        handle={handleRename}
        controls={controls}
      />
      <hr style={{ margin: '5px 0px' }} />
      <ItemMenu
        icon={<DeleteOutlined />}
        name="delete_selected_files"
        handle={handleConfirmDelete}
        controls={controls}
      />
    </div>
  )
}

export default FreebieContext
