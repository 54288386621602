import { Button, Input, Tooltip } from 'antd'
import React, { useState } from 'react'
import { getSourceByName, truncate } from 'utils'
import { sharedIcon } from 'utils/icon.util'

function FolderItemLarge({ item }) {
  return (
    <div className="table-row-large">
      <div className="vertically-center mr-2" style={{ width: 50 }}>
        <img className="icon-48" src={getSourceByName('folder')} alt="" />
      </div>
      <div className="vertically-center">
        <Tooltip
          mouseLeaveDelay={0}
          placement="bottomLeft"
          title={item.fileName}
          arrowPointAtCenter
        >
          <span> {truncate(item.fileName, 40)}</span>
          <span>{item.shared ? sharedIcon : <></>}</span>
        </Tooltip>
      </div>
    </div>
  )
}

export default FolderItemLarge
