import { config } from 'config'
import { FREEBIE_FOLDER, SHARED_FOLDER_URL } from 'config/constant'
import path from 'path'
import { equalPath, getCookie, isUgly, notUgly } from 'utils'
import axios from './axios'

const source = axios.CancelToken.source()
const endpointApi = config.endpoint.api
const newApi = config.endpoint.new_api
const ssoApi = config.endpoint.sso_api
const oldApi = config.endpoint.api_old

export const getUUID = () => {
  const currentFolder = window.location.pathname || ''
  if (currentFolder.includes(SHARED_FOLDER_URL)) {
    const shareUUID = localStorage.getItem('share_uuid')
    if (shareUUID) return shareUUID
  }
  // if (currentFolder.includes(FREEBIE_FOLDER)) {
  //   const originUUID = localStorage.getItem('origin_uuid')
  //   if (originUUID) return originUUID
  // }
  // if (currentFolder.includes(FREEBIE_FOLDER)) {
  //   return 'f62ff766-1bb3-463f-b671-72f3974c07ACWORKS'
  // }
  return localStorage.getItem('uuid')
}
export const getRootUUID = () => {
  // const currentFolder = window.location.pathname || ''
  // if (currentFolder.includes(FREEBIE_FOLDER)) {
  //   localStorage.getItem('uuid')
  // }
  return localStorage.getItem('uuid')
}
export const validateShareUUID = async currentPath => {
  let shareUUID = ''
  let edit = false
  const result = await getSharedFiles()
  if (result?.folders && result?.folders?.length > 0) {
    // result.folders.forEach(element => {
    //   if (equalPath(currentPath, element.path) && element.share_uuid) {
    //   }
    // })
    // console.log(result.folders)
    edit = result.folders[0]?.canEdit

    const selectedFolder = result.folders.filter(element => {
      return equalPath(currentPath, element.path) && notUgly(element.share_uuid)
    })
    if (selectedFolder.length > 0) {
      localStorage.setItem('share_uuid', selectedFolder[0].share_uuid)
      shareUUID = selectedFolder[0].share_uuid
      // edit = selectedFolder[0].canEdit
    }
  }
  console.log(shareUUID, edit)
  return { shareUUID, canEdit: edit }
}

// export const validateFileShareUUID = async fileName => {
//   const result = await getSharedFiles()
//   if (result?.file)
//     result.folders.forEach(element => {
//       if (equalPath(fileName, element.name) && element.share_uuid) {
//         console.log('share_uuid: ', element.share_uuid)
//         localStorage.setItem('share_uuid', element.share_uuid)
//       }
//     })
// }

export const validateUUID = async sessionUserId => {
  if (sessionUserId) {
    const uuid = await validateSSOId(sessionUserId)
    if (notUgly(uuid)) return uuid
    const data = {
      path: `path`,
      filenames: `filenames`,
      totalFiles: 100,
    }
    const result = await startUpload(data, source.token)
    console.log(result)
    if (result) {
      localStorage.setItem('uuid', result.id)
      await updateUserSSOId({ sso: sessionUserId })
      return result.id
    }
  }
  return null
}

export const createShortCut = (data, uuid) => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/shortcut/create?id=${uuid}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}
export const checkSSOUserHaveACdataID = async ssoUser => {
  await fetch(`${endpointApi}/sso`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ sso: ssoUser }),
  })
    .then(ress => ress.json())
    .then(async response => {
      localStorage.setItem('uuid', response.uuid)
    })
}

export const getACdataId = async ssoUser => {
  await fetch(`${endpointApi}/sso`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ sso: ssoUser }),
  })
    .then(ress => ress.json())
    .then(response => {
      return response.uuid
    })
}

export const validateSSOId = async ssoUser => {
  const response = await axios.post(`${endpointApi}/sso`, {
    sso: ssoUser,
  })
  return response.data.uuid
}
export const jwtTokenSSO = async token => {
  const response = await axios.get(
    `${config.sso.serverUrl}/?jwtToken=${token}&redirect=${config.sso.redirect}`,
  )
  return response.data
}

export const downloadMaterials = (data, uuid) => {
  return axios
    .post(`${endpointApi}/download/materials?id=${uuid}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getUserBySSOId = data => {
  return axios
    .post(`${endpointApi}/sso`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getSSOIdByUUID = shareUUID => {
  const shareuuid = shareUUID || getUUID()
  return axios
    .get(`${endpointApi}/user/get-ssoId-by-uuid?id=${shareuuid}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const updateUserSSOId = async data => {
  try {
    const res = await axios.post(`${endpointApi}/sso/update?id=${getUUID()}`, data)
    return res.data
  } catch (err) {
    return err
  }
  // return axios
  //   .post(`${endpointApi}/sso/update?id=${getUUID()}`, data)
  //   .then(res => {
  //     return res.data
  //   })
  //   .catch(e => {
  //     return e
  //   })
}

export const searchFileFolder = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/search?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkOriginFile = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/shortcut/file?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkOriginFolder = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/shortcut/folder?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkDuplicateFile = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/file/duplicate?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkDuplicateFolder = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/folder/duplicate?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const renameFile = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/file/rename?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const renameFolder = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/folder/rename?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const moveFolder = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/folder/move?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const moveFile = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/file/move?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const uploadBigSizeNotify = data => {
  return axios
    .post(`${endpointApi}/upload/notify?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const startUpload = async (data, cancel) => {
  try {
    const res = await axios.post(`${endpointApi}/upload/part/start`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    return res.data
  } catch (err) {
    return err
  }
  // return axios
  //   .post(`${endpointApi}/upload/part/start`, data, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     cancelToken: cancel,
  //   })
  //   .then(res => {
  //     return res.data
  //   })
  //   .catch(e => {
  //     if (axios.isCancel(e)) {
  //       return 555
  //     }
  //     return e
  //   })
}

export const startUploadFolder = (data, cancel) => {
  return axios
    .post(`${endpointApi}/upload/folder/part/start?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      return e
    })
}

export const getPartUrl = (data, cancel) => {
  return axios
    .post(`${endpointApi}/upload/part/url?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      throw e
    })
}

export const completeUpload = (data, cancel) => {
  return axios
    .post(`${endpointApi}/upload/part/complete?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancel,
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      throw e
    })
}

export const feedback = (message, gRecaptcha) => {
  const formData = new FormData()
  formData.set('message', message)
  formData.set('g-recaptcha-response', gRecaptcha)
  return axios
    .post(`${config.sso.homePage}/feedback`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      // console.log(res)
      return 'success'
    })
    .catch(e => {
      // console.log(e)
      return 'fail'
    })
}

export const updateFilesExpiry = data => {
  return axios
    .post(`${endpointApi}/expiry/update?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const updateSharingPassword = data => {
  return axios
    .post(`${endpointApi}/password/update?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const zipFiles = data => {
  return axios
    .post(`${oldApi}/file/zip?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getCurrentStorage = () => {
  return axios
    .post(`${endpointApi}/storage?id=${getUUID()}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getFileUrlForView = (userId, data) => {
  return axios
    .post(`${endpointApi}/file/view?id=${getUUID()}`, data)
    .then(res => {
      return res.data.url
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFileUrl = data => {
  return axios
    .post(`${endpointApi}/file/url`, data)
    .then(res => {
      return res.data.url
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const deleteFilesForever = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/delete/forever?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const deleteFoldersForever = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/delete/forever/folder?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const restoreFiles = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/delete/restore?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const restoreFolders = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/delete/restore/folders?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getDeletedFiles = () => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/browse/deleted?id=${getUUID()}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const verifyEmail = data => {
  return axios
    .post(`${endpointApi}/verify/email`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const verifyCode = data => {
  return axios
    .post(`${endpointApi}/verify/code`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFolderStructure = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/folder?id=${getRootUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const createFolderNewUser = data => {
  return axios
    .post(`${endpointApi}/folder/new`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const createFolderExistedUser = data => {
  return axios
    .post(`${endpointApi}/folder/user?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const getPreSignedUrls = data => {
  return axios
    .post(`${endpointApi}/upload`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const getPreSignedUrlByFolder = data => {
  return axios
    .post(`${endpointApi}/upload/folder?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const uploadFromURL = data => {
  return axios
    .post(`${endpointApi}/upload/direct`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const uploadFromURLExistUser = data => {
  return axios
    .post(`${endpointApi}/upload/folder/direct?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw e
    })
}

export const uploadFile = ({ file, signedUrl, type, cancel, handlePercent }) => {
  const options = {
    headers: { 'Content-Type': file.type },
    cancelToken: cancel,
    onUploadProgress: progressEvent => {
      const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      handlePercent(file.name, percent)
    },
  }
  return axios
    .put(signedUrl, file, options)
    .then(res => {
      return res.status
    })
    .catch(e => {
      if (axios.isCancel(e)) {
        return 555
      }
      return e
    })
}

export const uploadFolder = file => {
  const formData = new FormData()
  formData.append('file', file)
  return axios
    .post(`${endpointApi}/upload/folder`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(e => {
      // console.log(e)
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const postSaveFiles = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/save?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const deleteFiles = data => {
  return axios
    .post(`${endpointApi}/delete/files?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSavedFiles = folder => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/browse?id=${getUUID()}`, folder, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      // console.log(res.data)
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const deleteFolder = data => {
  // axios.interceptors.request.use(authInterceptor)
  return axios
    .post(`${endpointApi}/delete/folder?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(e => {
      // console.log(e)
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const shareFiles = data => {
  return axios
    .post(`${endpointApi}/share/files?id=${getUUID()}`, data)
    .then(res => {
      return res
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const shareFolder = data => {
  return axios
    .post(`${endpointApi}/share/folder?id=${getUUID()}`, data)
    .then(res => {
      return res
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const checkPasswordShareFile = data => {
  return axios
    .post(`${endpointApi}/password`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const updateDownloadTime = data => {
  return axios
    .post(`${endpointApi}/download`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const downloadFilesAndFolders = data => {
  return axios
    .post(`${endpointApi}/download/files?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFileInformation = data => {
  return axios
    .post(`${endpointApi}/verify/file`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const verifySharedFolders = data => {
  return axios
    .post(`${endpointApi}/share/verify`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const verifySharedPassword = data => {
  return axios
    .post(`${endpointApi}/share/password`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSharedFolders = data => {
  return axios
    .post(`${endpointApi}/share`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSharedDownloadURL = data => {
  return axios
    .post(`${oldApi}/download/shared`, data, { timeout: 600000 })
    .then(res => {
      return res.data.url
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getInfoUserByUserId = userId => {
  return axios
    .get(`${ssoApi}/api/user-info-by-id/${userId}`)
    .then(res => {
      if (res.data.error) {
        return null
      }
      return res.data.user
    })
    .catch(e => {
      console.log(e)
      return null
    })
}

export const getInfoUserByEmail = data => {
  return axios
    .get(`${ssoApi}/api/user-info-by-email/${data}`)
    .then(res => {
      if (res.data.error) {
        return null
      }
      return res.data.user
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}
export const getInfoUserBySessionUserId = data => {
  return axios
    .get(`${ssoApi}/api/user-info-by-id/${data}`)
    .then(res => {
      console.log(res.data)
      if (res.data.error) {
        return null
      }
      return res.data.user
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const shareToAccount = async data => {
  // axios.interceptors.request.use(authInterceptor)
  try {
    const res = await axios.post(`${newApi}/share/create?id=${getUUID()}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return res.data
  } catch (err) {
    return err
  }
}

export const getSharedFiles = () => {
  // axios.interceptors.request.use(authInterceptor)
  const uuid = localStorage.getItem('uuid')
  return axios
    .get(`${newApi}/share/get-shared-files-folders?id=${uuid}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const checkShared = () => {
  // axios.interceptors.request.use(authInterceptor)
  const uuid = localStorage.getItem('uuid')
  return axios
    .post(`${endpointApi}/shortcut/folder?id=${uuid}`)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const getSharedAccountFolders = data => {
  return axios
    .post(`${newApi}/share/getSharedFileFolders?id=${getUUID()}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}
export const getSharedUser = data => {
  const uuid = localStorage.getItem('uuid')
  return axios
    .post(`${newApi}/share/user?id=${uuid}`, data)
    .then(res => {
      return res.data
    })
    .catch(e => {
      return e
    })
}

export const removeShared = async data => {
  try {
    const res = await axios.post(`${newApi}/share/remove?id=${getUUID()}`, data)
    return res.data
  } catch (e) {
    return e
  }
}
export const updatePermissionShared = async data => {
  try {
    const res = await axios.post(`${newApi}/share/update/permission?id=${getUUID()}`, data)
    return res
  } catch (e) {
    console.log(e)
  }
  return null
}
// export const checkUserCanShare = data => {
//   return axios
//     .get(`${newApi}/share/validate-user?ssoId=${data}`)
//     .then(res => {
//       console.log(res.data)
//       return res.data
//     })
//     .catch(e => {
//       return e
//     })
// }
export const checkUserCanShare = data => {
  return axios
    .get(`${newApi}/share/validate-user?ssoId=${data}`)
    .then(res => {
      if (res.data.error) {
        return null
      }
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getSharedByUser = data => {
  const uuid = localStorage.getItem('uuid')

  return axios
    .get(`${newApi}/share/file-folder?id=${uuid}`)
    .then(res => {
      if (res.data.error) {
        return null
      }
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const genURL = async item => {
  const uuid = item.share_uuid ? item.share_uuid : getUUID()
  console.log(item)
  const url = await getFileUrlForView(uuid, {
    filename: item.name,
    path: item.path === '' ? '/' : item.path,
    origin: item.origin,
  })
  return url
}

const downloadAs = async (file, onProgress, onSuccess, onError) => {
  axios
    .get(file.url, {
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        if (onProgress) {
          onProgress(percentCompleted)
        }
      },
    })
    .then(response => {
      if (onSuccess) {
        onSuccess()
      }
      const a = document.createElement('a')
      const blobUrl = window.URL.createObjectURL(response.data)
      a.href = blobUrl
      a.download = file.name
      a.click()
      window.URL.revokeObjectURL(blobUrl) // Clean up URL object
    })
    .catch(err => {
      if (onError) {
        onError()
      }
      console.log('err on downloadAs', err)
    })
}

export const downloadFile = async (file, onProgress, onSuccess, onError) => {
  await downloadAs(file, onProgress, onSuccess, onError)
}

function openInNewTab(url) {
  const win = window.open(url, '_blank')
  win.focus()
  return 'success'
}

export const logout = globalSessionToken => {
  return axios
    .get(
      `https://${config.sso.serverUrl}/logout?sessionID=${globalSessionToken}&serviceURL=${config.sso.redirect}`,
    )
    .then(res => {
      if (res.data.error) {
        return null
      }
      return res.data
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export const getFilesAndFolders = data => {
  return (
    axios
      .post(`${endpointApi}/folders?id=${getUUID()}`, data)
      // .post(`http://localhost:4000/dev/folders?id=${getUUID()}`, data)
      .then(res => {
        return res.data
      })
      .catch(e => {
        throw e
      })
  )
}
export const getFilesAndFoldersFreebies = data => {
  return (
    axios
      .post(`${endpointApi}/freebie?id=${getUUID()}`, data)
      // .post(`http://localhost:4000/dev/folders?id=${getUUID()}`, data)
      .then(res => {
        return res.data
      })
      .catch(e => {
        throw e
      })
  )
}

export const zipData = data => {
  return axios
    .post(`${oldApi}/zip?id=${getUUID()}`, data)
    .then(res => {
      return res
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}
export const share1File = data => {
  return axios
    .post(`${endpointApi}/sharing/create-shared-link?id=${getUUID()}`, data)
    .then(res => {
      return res
    })
    .catch(e => {
      // console.log(e)
      return e
    })
}

export default {
  verifyCode,
  verifyEmail,
  uploadFile,
  uploadFolder,
  uploadFromURL,
  uploadFromURLExistUser,
  deleteFolder,
  deleteFiles,
  shareFiles,
  shareFolder,
  checkPasswordShareFile,
  downloadFile,
  getFolderStructure,
  createFolderExistedUser,
  createFolderNewUser,
  getDeletedFiles,
  deleteFilesForever,
  deleteFoldersForever,
  restoreFiles,
  restoreFolders,
  getFileUrl,
  getFileUrlForView,
  updateDownloadTime,
  getCurrentStorage,
  zipFiles,
  updateSharingPassword,
  feedback,
  updateFilesExpiry,
  startUpload,
  startUploadFolder,
  getPartUrl,
  completeUpload,
  uploadBigSizeNotify,
  getSharedFolders,
  verifySharedFolders,
  verifySharedPassword,
  getSharedDownloadURL,
  checkOriginFile,
  checkOriginFolder,
  searchFileFolder,
  getInfoUserByEmail,
  getSharedFiles,
  checkShared,
  getSharedAccountFolders,
  getSharedUser,
  validateUUID,
  getFilesAndFolders,
  zipData,
  share1File,
  getInfoUserBySessionUserId,
}
