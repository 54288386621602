import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCurrentStorage } from 'services/upload'

export const getStorage = createAsyncThunk('storage/get', async (args, { rejectWithValue }) => {
  try {
    const storage = getCurrentStorage()
    return storage
  } catch (error) {
    return rejectWithValue('error')
  }
})

export default {
  getStorage,
}
