// require('dotenv').config()
require('dotenv').config()

export const config = {
  env: process.env.NODE_ENV,
  endpoint: {
    home: process.env.REACT_APP_REDIRECT_URL,
    api: process.env.REACT_APP_API_REQUEST,
    new_api: process.env.REACT_APP_API_REQUEST_NEW,
    sso_api: process.env.REACT_APP_SSO_SERVER_URL,
    api_old: process.env.REACT_APP_API_REQUEST_OLD,
  },
  acworks: {
    home: `https://acworks.co.jp`,
    illustAC: `https://www.ac-illust.com`,
    photoAC: `https://www.photo-ac.com,`,
    videoAC: `https://video-ac.com`,
    silhouetteAC: `https://www.silhouette-ac.com`,
  },
  nav: {
    guide: `${process.env.REACT_APP_REDIRECT_URL}/guide`,
    freebie: `https://help.freebie-ac.jp/category/35/ACdata`,
    freebieHelp: `https://help.freebie-ac.jp`,
  },
  sso: {
    header: `${process.env.REACT_APP_SSO_SERVER_URL}/get-header`,
    listApp: `${process.env.REACT_APP_SSO_SERVER_URL}/get-list-apps`,
    homePage: process.env.REACT_APP_REDIRECT_URL,
    serverUrl: process.env.REACT_APP_SSO_SERVER_URL,
    resourceUrl: process.env.REACT_APP_SSO_SERVER_URL,
    appToken: process.env.REACT_APP_APP_TOKEN,
    redirect: process.env.REACT_APP_REDIRECT_URL,
    myPage: `${process.env.REACT_APP_SSO_SERVER_URL}/?serviceURL=${process.env.REACT_APP_REDIRECT_URL}`,
    loginUrl: `${process.env.REACT_APP_SSO_SERVER_URL}/login?serviceURL=${process.env.REACT_APP_REDIRECT_URL}`,
    signUpUrl: `${process.env.REACT_APP_SSO_SERVER_URL}/signup?serviceURL=${process.env.REACT_APP_REDIRECT_URL}`,
  },
  sns: {
    facebook: `https://www.facebook.com/ACworks2011`,
    twitter: `https://twitter.com/ACworks2011`,
  },
  s3: {
    thumbnail: process.env.REACT_APP_THUMNAIL,
  },
  assetPrefix: process.env.REACT_APP_ASSET_PREFIX,
}

export default config
