import { useEffect, useRef, useState } from 'react'
import { isWindows } from 'utils'

export function useHandleKeyboard() {
  const [cmdKeyPress, setCmdKeyPress] = useState(false)
  const isWindowsOS = useRef(isWindows())
  const [escPress, setEscPress] = useState(false)

  useEffect(() => {
    const onKeydown = event => {
      if (event.key === 'Escape') {
        setEscPress(true)
      }
      if (isWindowsOS.current) {
        if (event.ctrlKey) {
          setCmdKeyPress(true)
        }
      } else if (event.metaKey) {
        setCmdKeyPress(true)
      }
    }
    const onKeyup = event => {
      setEscPress(false)
      if (isWindowsOS.current) {
        if (event.ctrlKey === false) {
          setCmdKeyPress(false)
        }
      } else if (event.metaKey === false) {
        setCmdKeyPress(false)
      }
    }
    document.addEventListener('keydown', onKeydown, false)
    document.addEventListener('keyup', onKeyup, false)

    return () => {
      document.addEventListener('keydown', onKeydown, false)
      document.addEventListener('keyup', onKeyup, false)
    }
  }, [])

  return { cmdKeyPress, escPress }
}

export default useHandleKeyboard
