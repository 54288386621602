/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import TopBar from 'layouts/elements/top-bar'
import Header from 'layouts/elements/header'
import Footer from 'layouts/elements/footer'
import { useTranslation, withTranslation } from 'react-i18next'
import { getSessionUser } from 'services/sso'
import { clearStorage } from 'services/users/user.services'
import './style.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from 'features/user/userActions'
import { managerSelector, userSelector } from 'redux/selectors'
import { getCookie, isUgly, notUgly, setCookies } from 'utils'
import { checkShared, checkUserCanShare } from 'services/upload'
import { setCanShare, setIsShared } from 'features/manager/managerSlice'
import TemplateSectionAbove from 'pages/dashboard/TemplateSectionAbove'
import TemplateSectionBelow from 'pages/dashboard/TemplateSectionBelow'
import { TAB } from 'config/constant'
import config from 'config'
import { BellFilled, CloseOutlined } from '@ant-design/icons'
import ModalNoti from '../elements/modal'

function AppLayout({ children }) {
  const { t, i18n } = useTranslation()
  const [isDisplaySurvey, setIsDisplaySurvey] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { userInfo } = useSelector(userSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tab } = useSelector(managerSelector)
  const [isHidden, setIsHidden] = useState(false)

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const handleHide = () => {
    setIsHidden(false)
    const today = new Date()
    localStorage.setItem(
      'dateHideNoti',
      `${formatDate(today)}-${getCookie('sessionUserId')}-${localStorage.getItem('uuid')}`,
    )
  }

  const checkUserLogin = () => {
    const date = localStorage.getItem('dateHideNoti')
    const today = new Date()
    const checkDataNever = localStorage.getItem('neverShowNoti')
    const specificDate = new Date('2025-01-06T00:00:00')
    if (today.getTime() >= specificDate.getTime()) {
      setIsHidden(false)
      return
    }
    if (
      getCookie('sessionUserId') &&
      localStorage.getItem('uuid') &&
      checkDataNever === `${getCookie('sessionUserId')}-${localStorage.getItem('uuid')}`
    ) {
      setIsHidden(false)
      return
    }
    if (
      date !==
        `${formatDate(today)}-${getCookie('sessionUserId')}-${localStorage.getItem('uuid')}` &&
      getCookie('sessionUserId') &&
      localStorage.getItem('uuid')
    ) {
      setIsHidden(true)
    }
  }

  useEffect(() => {
    checkShared().then(res => {
      if (res) dispatch(setIsShared(res.isShared))
    })
    checkUserCanShare(getCookie('sessionUserId')).then(result => {
      if (result) dispatch(setCanShare(result.canShare))
    })

    if (!userInfo.isPremium && !window.adsbygoogle) {
      const script = document.createElement('script')
      script.setAttribute('data-ad-client', 'ca-pub-6219550375267396')
      script.setAttribute('async', '')
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }

    checkUserLogin()
  }, [userInfo])

  useEffect(() => {
    checkUserLogin()
    // eslint-disable-next-line func-names
    window.addEventListener('message', async function(event) {
      if (event.origin === config.sso.serverUrl && event.data) {
        try {
          const ssoTokenData = JSON.parse(event.data)
          const globalSessionId = ssoTokenData?.sso_logged
          // if (
          //   notUgly(globalSessionId) &&
          //   isUgly(getCookie('global_session_id')) &&
          //   isUgly(getCookie('token'))
          // ) {
          //   console.log('msg', globalSessionId)
          //   dispatch(userLogin({ globalSessionId }))
          //   // navigate('/')
          // }
          const resp = await getSessionUser(globalSessionId)
          const { sessionUser } = resp.data
          if (sessionUser && isUgly(getCookie('global_session_id')) && isUgly(getCookie('token'))) {
            console.log('auto log')
            window.location.href = `${config.sso.loginUrl}&autoLogin=true&ssoToken=${globalSessionId}`
          }
          if (resp.error || isUgly(sessionUser)) {
            clearStorage(globalSessionId)
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }, [])

  return (
    <Layout className="prevent-select">
      <Header lang={i18n.language} />
      <Layout>
        <Layout.Header>
          <TopBar i18n={i18n} t={t} />
        </Layout.Header>
        <ModalNoti
          isVisible={isHidden}
          toggle={() => {
            handleHide()
          }}
        />
        {/* <div
          style={{
            backgroundColor: '#D1ECF1',
            borderLeft: '#17A2B8 3px solid',
            left: '40%',
            transform: 'translateX(-30%)',
            position: 'fixed',
            top: 0,
            color: '#0C5460',
            padding: '9px 0px',
            borderRadius: '4px',
            display: isHidden ? 'none' : 'inline-block',
            zIndex: '999999999',
          }}
        >
          <p
            style={{
              marginBottom: '0px',
              size: '13px',
            }}
          >
            <BellFilled
              style={{
                margin: '0px 20px',
                color: '#17A2B8',
              }}
            />
            午後22時から、メンテナンスのためACデータサイトは一時停止いたします。
            <a
              style={{
                fontWeight: 700,
                textDecoration: 'underline',
                color: '#0C5460',
              }}
              href="https://acworks.co.jp/maintenance_acdata_20241103/"
              target="_blank"
            >
              詳細はこちら
            </a>
            <CloseOutlined
              onClick={handleHide}
              style={{
                width: '14px',
                height: '14px',
                margin: '0px 20px',
                cursor: 'pointer',
              }}
            />
          </p>
        </div> */}
        <div
          id="div-gpt-ad-1604031951387-0"
          style={{
            textAlign: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          <script
            dangerouslySetInnerHTML={{
              __html: `
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1604031951387-0'); });
              `,
            }}
          />
        </div>
        <Layout.Content
          style={{
            minHeight: '500px',
            position: 'relative',
            width: '100%',
          }}
        >
          <div
            style={{
              borderBottom: '2px solid #1b55e3',
              marginTop: 49,
              width: '100%',
              position: 'absolute',
            }}
          >
            {' '}
          </div>
          <div>{children}</div>
          <a
            // href="https://docs.google.com/forms/d/e/1FAIpQLSfVTQ4YAJ3kIjTYU9SmrYmaA2UgQ50avbmv1iNrYy7UDUT6VA/viewform"
            href="https://forms.gle/DqNokr7jdYbiy29K9"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: isDisplaySurvey ? 'initial' : 'none',
            }}
          >
            <div className="button-survey">
              <button
                className="close-survey"
                type="button"
                onClick={() => setIsDisplaySurvey(false)}
              />
              <div className="button-survey-group">
                <img
                  alt="survey"
                  className="button-survey-icon"
                  src="/resources/images/survey-red.svg"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  className="button-survey-text"
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      height: 15,
                    }}
                  >
                    {t('text_user_only')}
                  </span>
                  {/* change text */}
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    {t('text_survey')}
                  </span>
                </div>
              </div>
            </div>
          </a>
        </Layout.Content>
        {tab === TAB.TRANSFER && (
          <div>
            <TemplateSectionAbove />
            <TemplateSectionBelow />
          </div>
        )}
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
      <iframe
        sandbox="allow-scripts allow-same-origin"
        width="0"
        height="0"
        style={{ display: 'none' }}
        id="ssoAutoLoginIframe"
        src={`${config.sso.serverUrl}/share-auth?token=${getCookie('global_session_id')}&from=${
          config.sso.redirect
        }&t=${Date.now()}`}
        hidden
      />
    </Layout>
  )
}

export default AppLayout
