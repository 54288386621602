import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Modal, Button } from 'antd'
import ShareFormDropzone from './ShareFormDropzone'

class ShareModalDropzone extends React.Component {
  render() {
    const { t } = this.props
    const { onCancel, data, expiry } = this.props

    return (
      <Modal
        {...this.props}
        open
        title={t('sharing_form_tittle')}
        footer={null}
        centered
        destroyOnClose
      >
        <ShareFormDropzone onCancel={onCancel} selectedFiles={data} expiry={expiry} />
      </Modal>
    )
  }
}

export default withTranslation()(ShareModalDropzone)
