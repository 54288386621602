import { Card, List } from 'antd'
import { setIsDrag } from 'features/manager/managerSlice'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TypeDrag, VIEW } from 'config/constant'
import { useHandleAction, useHandleMouse, useHandlePermission, useRouter } from 'hooks'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { managerSelector } from 'redux/selectors'
import CoverImage from 'components/CoverImage'
import EditableName from 'components/EditableName'

const { Meta } = Card

function FolderCard({ folderItem, viewSize }) {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const { selected, isDrag } = useSelector(managerSelector)
  const { preventDrag, preventUpload } = useHandlePermission()

  const { onDropToMove, onDropTreeToFolder, handleDragStart } = useHandleAction()
  const { handleClick, handleContextMenu, handleDoubleClick } = useHandleMouse()
  const isSelected = selected.includes(folderItem.key)

  const [{ isOver }, dropRef] = useDrop({
    accept: [TypeDrag.CARD, TypeDrag.ROW],
    drop: (item, monitor) => {
      if (preventDrag || preventUpload) return
      const typeItem = monitor.getItemType()
      if (typeItem === TypeDrag.ROW) onDropTreeToFolder(item, folderItem.path)
      if (typeItem === TypeDrag.CARD) onDropToMove(folderItem.path)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  })

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: TypeDrag.CARD,
    item: folderItem,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  dropRef(ref)
  dragRef(ref)

  useEffect(() => {
    dispatch(setIsDrag(isDragging))
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])
  const sizeClass = viewSize === VIEW.SIZE.SMALL ? ' card-small' : ' card-large'

  const className = `file-card 
    ${isSelected ? ' card-selected' : ''}
    ${isOver ? ' card-over' : ''}
    ${isDrag && isSelected ? ' item-dragging' : ''}
    ${sizeClass}
    `

  return (
    <List.Item ref={ref} className="item-ref" data-row-key={folderItem.key}>
      <div
        aria-hidden
        className={className}
        onDoubleClick={() => handleDoubleClick(folderItem)}
        onContextMenu={() => handleContextMenu(folderItem)}
        onClick={() => handleClick(folderItem)}
        onDragStart={() => handleDragStart(folderItem)}
      >
        <CoverImage viewSize={viewSize} fileItem={folderItem} />
      </div>
      <EditableName fileItem={folderItem} />
    </List.Item>
  )
}

export default FolderCard
