import React from 'react'
import { Card, List } from 'antd'
import CoverImage from 'components/CoverImage'
import EditableName from 'components/EditableName'
import { VIEW } from 'config/constant'
import { useHandleAction } from 'hooks'

const { Meta } = Card

// class SharedFileCard extends Component {
//   render() {
//     const {
//       _i,
//       setFileLoading,
//       setURL,
//       genURL,
//       setOpenFilePreviewModal,
//       setSelectedItem,
//       fileItem,
//     } = this.props

//     return (
//       <div style={{ padding: 0, margin: 0, border: 0 }}>
//         <Card
//           bordered={false}
//           hoverable
//           style={{
//             height: 170,
//             width: 150,
//             padding: '5px 0',
//             marginBottom: '10px',
//           }}
//           bodyStyle={{ padding: '5px !important' }}
//           onDoubleClick={async evt => {
//             evt.stopPropagation()
//             setSelectedItem(fileItem)
//             setOpenFilePreviewModal(true)
//             setURL(await genURL(fileItem))
//           }}
//           onClick={e => {
//             if (e.currentTarget.classList.contains('card-selected')) {
//               e.currentTarget.classList.remove('card-selected')
//             } else {
//               e.currentTarget.classList.add('card-selected')
//             }
//           }}
//           cover={
//             <Img
//               className="card-image-small"
//               src={[
//                 `https://s3-ap-northeast-1.amazonaws.com/thumbnail.ac-data.info/${_i}/${fileItem.path}${fileItem.fileName}`,
//                 `https://s3-ap-northeast-1.amazonaws.com/thumbnail.ac-data.info/f62ff766-1bb3-463f-b671-72f3974c07ACWORKS/${fileItem.path}${fileItem.fileName}`,
//               ]}
//               unloader={
//                 <img src={getSourceByName(fileItem.fileName)} alt="" className="card-image-small" />
//               }
//             />
//           }
//         >
//           <Tooltip placement="bottomLeft" title={fileItem.fileName} arrowPointAtCenter>
//             <Meta
//               description={truncate(fileItem.fileName, 15)}
//               style={{ width: 150, justifyContent: 'center' }}
//             />
//           </Tooltip>
//         </Card>
//       </div>
//     )
//   }
// }

function SharedFileCard({ fileItem, setURL, genURL, setOpenFilePreviewModal, setSelectedItem }) {
  const viewSize = VIEW.SIZE.SMALL
  const sizeClass = viewSize === VIEW.SIZE.SMALL ? ' card-small' : ' card-large'
  const className = `file-card ${sizeClass}`
  const { handlePreview, handleDownload } = useHandleAction()

  return (
    <List.Item
      style={{ display: 'flex', flexDirection: 'column' }}
      className="item-ref"
      data-row-key={fileItem.key}
    >
      <div
        aria-hidden
        className={className}
        onDoubleClick={async evt => {
          evt.stopPropagation()
          // handlePreview(fileItem)
          // setSelectedItem(fileItem)
          // setOpenFilePreviewModal(true)
          // setURL(await genURL(fileItem))
        }}
      >
        <CoverImage viewSize={viewSize} fileItem={fileItem} />
      </div>
      <EditableName fileItem={fileItem} />
    </List.Item>
  )
}
export default SharedFileCard
