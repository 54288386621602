import { Input } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import { isMobile } from 'config/constant'
import { filterSearch } from './filterSlice'

function SearchBar() {
  const { t } = useTranslation()

  // const { loading, userInfo, error } = useSelector(userSelector)
  // const {storage} = useSelector(storageSelector)
  const dispatch = useDispatch()
  // console.log({ storage })

  const [search, setSearch] = useState('')

  const handleSearch = value => {
    setSearch(value)
    dispatch(filterSearch(value))
  }

  return (
    <>
      <Input
        placeholder={t('search_placeholder')}
        onPressEnter={value => {
          // console.log(value.target.value)
          // handleSearch(value.target.value)
        }}
        value={search}
        onChange={e => handleSearch(e.target.value)}
        // loading={loading}
        prefix={<SearchOutlined style={{ fontSize: 19, color: 'gray' }} />}
        style={{
          width: isMobile() ? 120 : 255,
          marginRight: 15,
          alignSelf: 'center',
          fontSize: 14,
          height: 32,
        }}
      />
    </>
  )
}

export default SearchBar
