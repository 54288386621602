import { TypeDrag } from 'config/constant'
import { useHandleAction, useHandlePermission } from 'hooks'
import React, { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { truncateString } from 'utils'

function DragableNode({ value }) {
  const ref = useRef()
  const { onDropToMove } = useHandleAction()
  const { preventDrag, preventUpload } = useHandlePermission()

  const [{ isOver }, dropRef] = useDrop({
    accept: [TypeDrag.ROW, TypeDrag.CARD],
    drop: item => {
      if (preventDrag || preventUpload) return
      onDropToMove(value.path)
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const [{ isDragging }, dragRef, preview] = useDrag({
    type: TypeDrag.ROW,
    item: value,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    const node = ref.current.parentElement.parentElement
    dropRef(node)
    dragRef(node)
  }, [ref])

  useEffect(() => {
    const node = ref.current.parentElement.parentElement
    if (isOver) {
      node.classList.add('node-hover')
    } else {
      node.classList.remove('node-hover')
    }
  }, [isOver])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <span style={{ fontSize: 14 }} className="ant-tree-node" ref={ref}>
      {truncateString(value.title, 14)}
    </span>
  )
}

export default DragableNode
