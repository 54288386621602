import { boxesIntersect } from '@air/react-drag-to-select'
import { CloseOutlined, DownloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { Children } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'
import { getImageBase64ByName } from 'utils'

function LayoutPreview({ data,URL, children, onCancel }) {

  const downloadFile = () => {
    window.location.href = URL
  }
  const handleClick = e => {
    // const { target } = e
    // if (target instanceof HTMLElement) {
    //   const previewDom = document.getElementById('bodyPreview')
    //   const mousePos = { left: e.clientX, top: e.clientY, height: 3, width: 3 }
    //   const { left, top, width, height } = previewDom.getBoundingClientRect()
    //   const previewRect = { left, top, width, height }
    //   if (!boxesIntersect(previewRect, mousePos)) {
    //     onCancel()
    //   }
    // }
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 12345,
        display: 'block',
      }}
    >
      <div>
        <div id="preview-header">
          <div
            style={{
              height: '54px',
              backgroundColor: 'black',
              paddingTop: '9px',
            }}
          >
            <div className="d-flex justify-content-between px-3">
              <div style={{ alignSelf: 'center' }}>
                <img
                  src={getImageBase64ByName(data.name)}
                  alt=""
                  style={{
                    width: '26px',
                    height: '26px',
                    marginRight: 7,
                    userSelect: 'none',
                  }}
                />
                <span style={{ color: 'white', fontSize: 14 }}>{data.name}</span>
              </div>
              <div style={{ alignSelf: 'center' }}>
                <Button
                  onClick={downloadFile}
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: 'none',
                  }}
                >
                  <DownloadOutlined
                    style={{
                      fontSize: 18,
                      verticalAlign: 'middle',
                      color: 'white',
                    }}
                  />
                </Button>
                <Button
                  // className="ant-btn mr-3"
                  onClick={onCancel}
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    border: 'none',
                  }}
                >
                  <CloseOutlined
                    style={{
                      fontSize: 18,
                      verticalAlign: 'middle',
                      color: 'white',
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              zIndex: 2000,
              height: '30px',
              width: '30px',
              position: 'absolute',
              top: '50vh',
            }}
          >
            {/* <Button
              key="pre"
              ghost
              //    onClick={() => handlePrev(selectedFile.id)}
              style={{
                border: 0,
                fontSize: 30,
                verticalAlign: 'middle',
                height: 'fit-content',
              }}
            >
              <LeftOutlined />
            </Button> */}
          </div>
          <div
            style={{
              zIndex: 2000,
              height: '30px',
              width: '30px',
              position: 'absolute',
              top: '50vh',
              right: '27px',
            }}
          >
            {/* <Button
              key="next"
              ghost
              //    onClick={() => handleNext(selectedFile.id)}
              style={{
                border: 0,
                fontSize: 30,
                verticalAlign: 'middle',
                height: 'fit-content',
              }}
            >
              <RightOutlined />
            </Button> */}
          </div>
        </div>
        <div aria-hidden onClick={handleClick} id="preview-body">
          {children}
        </div>
      </div>
    </div>
  )
}

export default LayoutPreview
