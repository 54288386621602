import React, { useState, useEffect } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Button, Popconfirm, message, Input, Spin, Progress, Select } from 'antd'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { copy, humanFileSize, openNotificationWithIcon, truncate } from 'utils/common.util'
import { postSaveFiles, shareFiles, updateSharingPassword } from 'services/upload'
import crypto from 'crypto'
import { useResize } from 'hooks'
import config from 'config'
import { encryptId } from 'utils'
// import uuid from 'uuid/v4'
// import { formatDistance, addDays, parse } from 'date-fns'
// import { ja, enUS } from 'date-fns/locale'
// import { format } from 'date-fns/esm'
// import { uploadFile } from '../../../services/upload'

const { Option } = Select

const handleCopy = async (t, url) => {
  copy(url)
  openNotificationWithIcon('success', t('copy_file_url_success'))
}

const File = ({
  item,
  index,
  onDeleteFile,
  uploaded,
  isLoading,
  allFilePasswords,
  canceled,
  percent,
  expiry,
  isDropzoneModal,
}) => {
  const [password, setPassword] = useState('')
  const [sharedId, setSharedId] = useState('')
  const [isLoadingUrl, setIsLoadingUrl] = useState(true)
  const [url, setUrl] = useState('')
  const [status, setStatus] = useState('loading')
  const { windowSize } = useResize()
  const { t } = useTranslation()

  const handlePasswordChange = e => {
    setPassword(e.target.value)
    allFilePasswords.find(file => {
      if (file.key === item.key) {
        file.password = e.target.value
        return true
      }
      return false
    })
  }

  const handleUrlChange = e => {
    setUrl(e.target.value)
  }

  const handlePassword = async () => {
    setIsLoadingUrl(true)
    await updateSharingPassword({
      sharedId,
      password,
    })
      .then(res => {
        if (res.status === 'success') {
          setIsLoadingUrl(false)
          openNotificationWithIcon('success', t('sharing_password_set_success'))
        } else {
          setIsLoadingUrl(false)
          openNotificationWithIcon('success', t('sharing_password_set_fail'))
        }
      })
      .catch(e => {
        setIsLoadingUrl(false)
        openNotificationWithIcon('error', t('sharing_password_set_fail'))
      })
  }

  useEffect(() => {
    if (canceled) {
      setStatus('close-circle')
      setIsLoadingUrl(false)
    }
  }, [canceled])

  useEffect(() => {
    if (uploaded) {
      setStatus('check-circle')
      setIsLoadingUrl(true)
      const currentFolder = localStorage.getItem('current_folder')
      shareFiles({
        // password,
        sender: 'aaa@aaa.aaa',
        filesShare: [{ name: item.fileName, password: '' }],
        zipped: '',
        receivers: ['aaa@aaa.aaa'],
        message: "Shared by file's URL",
        notification: false,
        path: currentFolder,
        expiry: expiry || '3',
      })
        .then(res => {
          setSharedId(`${res.data.sharedIds[0]}`)
          localStorage.setItem(
            'sharedids',
            JSON.stringify(
              (JSON.parse(localStorage.getItem('sharedids')) || []).concat(res.data.sharedIds[0]),
            ),
          )
          // const mykey = crypto.createCipher('des', 'acdata.acworks')
          // let shareEncrypt = mykey.update(`${res.data.sharedIds[0]}`, 'utf8', 'base64')
          // shareEncrypt += mykey.final('base64')
          // shareEncrypt = shareEncrypt.replace(/\//g, '&').slice(0, -1)

          const shareEncrypt = encryptId(res.data.sharedIds[0])
          setUrl(`${config.sso.redirect}/d/${shareEncrypt}`)
          setIsLoadingUrl(false)
          // openNotificationWithIcon('success', `${item.fileName} ${t('upload_files_success')}`)
        })
        .catch(e => {
          setUrl('')
          setIsLoadingUrl(false)
          openNotificationWithIcon('error', `${item.fileName} ${t('upload_files_fail')}`)
        })
    }
  }, [uploaded])

  return (
    <tr key={index.toString()} className="ant-table-row ant-table-row-level-0">
      <td
        style={{ maxWidth: '300px', whiteSpace: 'nowrap', paddingTop: '24px', paddingRight: '0' }}
      >
        <span style={{ maxWidth: '325px' }}>
          {truncate(item.fileName, Math.ceil(windowSize.width / (isDropzoneModal ? 60 : 50)), true)}
        </span>
        <br />
        <span style={{ fontSize: '12px' }}>
          <i>{humanFileSize(item.fileSize)}</i>
        </span>
      </td>
      {/* <td style={{ minWidth: '70px' }}>{humanFileSize(item.fileSize)}</td> */}
      <td style={{ verticalAlign: 'middle', paddingLeft: '1vw' }}>
        {/* <Icon type={status} style={{ fontSize: '25px' }} /> */}
        {percent === 0 && !canceled && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 34, color: '#108ee9' }} spin />} />
        )}
        {(percent !== 0 || canceled) && (
          <Progress
            type="circle"
            percent={url ? percent : percent - 1}
            width={35}
            status={canceled ? 'exception' : ''}
          />
        )}
      </td>
      <td style={{ maxWidth: '450px', verticalAlign: 'middle' }}>
        <Input
          // readonly
          className="auto-width"
          placeholder="URL"
          value={url}
          onChange={handleUrlChange}
          style={{ minWidth: '170px', width: '100%' }}
          addonAfter={
            <Button
              type="link"
              onClick={() => handleCopy(t, url)}
              disabled={isLoadingUrl}
              loading={
                isLoadingUrl
                  ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
                  : false
              }
            >
              {t('button_copy')}
            </Button>
          }
        />
      </td>
      <td
        style={{
          maxWidth: '200px',
          minWidth: '140px',
          verticalAlign: 'middle',
          paddingLeft: '2vw',
        }}
      >
        <Input.Password
          // placeholder={t('sharing_form_password')}
          className="auto-width"
          value={password}
          onChange={handlePasswordChange}
          disabled={isLoadingUrl}
          style={{ minWidth: '140px', width: '100%' }}
          addonAfter={
            <Button
              type="link"
              onClick={() => handlePassword(t, sharedId, password)}
              disabled={password === ''}
              loading={
                isLoadingUrl
                  ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
                  : false
              }
            >
              {t('button_setup')}
            </Button>
          }
        />
      </td>
      <td
        style={{
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
          width: '3vw',
          textAlign: 'center',
          paddingLeft: 0,
        }}
      >
        <span>
          {/* {`${format(addDays(new Date(), 7), 'PPP', {
            locale: document.documentElement.lang === 'ja' ? ja : enUS,
          })}`} */}
          {t(`day${expiry}`)}
        </span>
      </td>
      <td style={{ verticalAlign: 'middle', padding: '0' }}>
        <Popconfirm
          placement="topLeft"
          title={t('deldete_file_confirmation')}
          onConfirm={() => onDeleteFile(item)}
          okText={t('button_yes')}
          cancelText={t('button_no')}
        >
          <Button type="link" disabled={isLoadingUrl}>
            <DeleteOutlined style={{ fontSize: '20px' }} />
          </Button>
        </Popconfirm>
      </td>
    </tr>
  )
}

export const TableFiles = ({
  isDragged,
  dataSource,
  uploaded,
  onDeleteFile,
  isLoading,
  allFilePasswords,
  canceled,
  allFilePercent,
  handleFilesExpiry,
  expiry,
  isDropzoneModal,
}) => {
  const { t } = useTranslation()
  const [expiredDate, setExpiredDate] = useState(t('list_file_expired'))

  const handleSelectFilesExpiry = value => {
    if (value !== t('list_file_expired')) {
      handleFilesExpiry(value)
    }
    setExpiredDate(t('list_file_expired'))
  }

  return (
    <div className={`${isDragged ? 'table-drop-hover' : ''} ant-table ant-table-default`}>
      <div className="ant-table-content">
        <div className="ant-table-body table-responsive">
          <table className="table table-borderless">
            <thead className="ant-table-thead">
              <tr className="ant-table-row ant-table-row-level-0">
                <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', fontSize: 14 }}>
                  {t('list_file_name')}
                </th>
                {/* <th>{t('list_file_size')}</th> */}
                <th
                  style={{
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                    paddingLeft: '1vw',
                    fontSize: 14,
                  }}
                >
                  {t('upload_status')}
                </th>
                <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', fontSize: 14 }}>
                  {t('list_copy_url')}
                </th>
                <th
                  style={{
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                    paddingLeft: '2vw',
                    fontSize: 14,
                  }}
                >
                  {t('sharing_form_password')}
                </th>
                <th
                  style={{
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                    paddingLeft: 0,
                    fontSize: 14,
                  }}
                >
                  <Select
                    value={expiredDate}
                    style={{ width: '110px', textAlign: 'center', fontSize: 14 }}
                    placeholder={t('list_file_expired')}
                    onSelect={handleSelectFilesExpiry}
                    suffixIcon={<span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>▼</span>}
                  >
                    <Option value="1">
                      <span style={{ fontSize: 14 }}>{t('day1')}</span>
                    </Option>
                    <Option value="3">
                      <span style={{ fontSize: 14 }}>{t('day3')}</span>
                    </Option>
                    <Option value="7">
                      <span style={{ fontSize: 14 }}>{t('day7')}</span>
                    </Option>
                    <Option value="10">
                      <span style={{ fontSize: 14 }}>{t('day10')}</span>
                    </Option>
                    <Option value="30">
                      <span style={{ fontSize: 14 }}>{t('day30')}</span>
                    </Option>
                    <Option value="65">
                      <span style={{ fontSize: 14 }}>{t('day65')}</span>
                    </Option>
                  </Select>
                </th>
                <th style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', fontSize: 14 }}>
                  {t('list_delete_file')}
                </th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {dataSource.map((item, index) => (
                <File
                  key={item.key}
                  t={t}
                  uploaded={uploaded.includes(item.key)}
                  canceled={canceled.includes(item.key)}
                  item={item}
                  index={index}
                  onDeleteFile={onDeleteFile}
                  isLoading={
                    isLoading
                      ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
                      : false
                  }
                  allFilePasswords={allFilePasswords}
                  // percent={allFilePercent}
                  expiry={expiry}
                  percent={allFilePercent.filter(file => file.key === item.key)[0].percent}
                  isDropzoneModal={isDropzoneModal}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TableFiles
