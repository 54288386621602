import React, { useState } from 'react'

export function Audio({ URL }) {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '95vh',
        display: 'flex',
      }}
    >
      <audio style={{ width: '50%' }} controls src={URL.toString()}>
        <track default kind="captions" src="" />
      </audio>
    </div>
  )
}

export default Audio
