import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { managerSelector } from 'redux/selectors'

export function OverlayUpload(props) {
  const { isDragActive, inputProps } = props


  return (
    <Fragment>
      {isDragActive ? (
        <div
          id="overLayUpload"
          style={{
            height: '100%',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 4000,
            backgroundColor: 'rgba(31, 75, 219, 0.2)',
          }}
        />
      ) : null}
      <input disabled {...inputProps()} />
    </Fragment>
  )
}

export default OverlayUpload
