import './style.css'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { config } from 'config'
import UserInfo from 'features/user/UserInfo'
import { isMobile } from 'config/constant'
import { useSelector } from 'react-redux'
import { userSelector } from 'redux/selectors'
import style from './style.module.scss'

function TopBar() {
  const { t } = useTranslation()
  const { userInfo } = useSelector(userSelector)
  const { isAuth } = userInfo
  return (
    <div className={style.topbar}>
      <div
        className="mr-auto ml-auto justify-content-between d-flex"
        style={
          isMobile()
            ? {
                width: '100',
                height: 50,
              }
            : {
                width: '75vw',
                maxWidth: 1400,
                height: 50,
              }
        }
      >
        <div style={{ display: 'flex' }} className="logo header-logo">
          <div
            style={{
              display: 'flex',
              alignSelf: 'end',
              marginBottom: '2px',
              marginRight: '10px',
            }}
          >
            <a href="/">
              <img src="/resources/images/ACdata_logo.png" alt="ACdata" style={{ height: 36 }} />
            </a>
          </div>
          <div style={{ display: 'flex', alignSelf: 'end' }}>
            <Trans>
              {(!isMobile() || isAuth) && (
                <div>
                  <p
                    className="ac-logo-text-description"
                    style={{
                      fontSize: 12,
                      minWidth: 111,
                      color: '#333333',
                      marginBottom: 0,
                    }}
                  >
                    {t('logo_description')}
                  </p>
                  <p
                    className="ac-logo-text-description"
                    style={{
                      fontSize: 12,
                      minWidth: 111,
                      color: '#333333',
                      marginBottom: 0,
                    }}
                  >
                    {t('logo_description1')}
                  </p>
                </div>
              )}
            </Trans>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
          }}
        >
          <nav
            className="main-nav float-right d-inline-block font-weight-bold text-nowrap"
            style={{}}
          >
            <ul>
              <li className="header-menu-item">
                <a
                  className="btn-hover"
                  style={{
                    marginRight: 20,
                  }}
                  href={config.nav.guide}
                >
                  {t('guide_blog')}
                </a>
              </li>
              <li className="header-menu-item">
                <a
                  className="btn-hover"
                  style={{
                    marginRight: 20,
                  }}
                  href={config.nav.freebie}
                >
                  {t('faqs')}
                </a>
              </li>
              <UserInfo />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default TopBar
