import React, { useState } from 'react'
import { Button } from 'antd'
import { useTranslation, withTranslation } from 'react-i18next'
import classNames from 'classnames'
import { config } from 'config'
import style from './style.module.scss'
import Feedback from './feedback'

function Footer() {
  const { t } = useTranslation()
  const [openFeedback, setopenFeedback] = useState(false)
  const homePageUrl = config.sso.homePage
  return (
    <div
      className={classNames(
        style.footer,
        //    {
        //   [style.footerFullWidth]: isContentNoMaxWidth,
        // }
      )}
    >
      <footer id="footer" className="section-bg">
        <div className="footer-top text-center">
          <div className="col-12">
            <div className="copyright">
              <div className="social-links sns-icon">
                <a
                  href={config.sns.facebook}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="facebook"
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  href={config.sns.twitter}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="twitter"
                >
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 1200 1227"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                      fill="currentColor"
                    />
                  </svg>
                  {/* <i className="fa fa-twitter" /> */}
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-info">
                  <a href={`${homePageUrl}/terms`}>
                    <img
                      src="/resources/images/ACdata_white.png"
                      alt="ACdata"
                      className="img-fluid"
                      style={{ width: 150 }}
                    />
                  </a>
                </div>
                <div className="footer-links">
                  <ul>
                    <li>
                      <a href={`${homePageUrl}/guide`}>{t('guide_blog')}</a>
                    </li>
                    <li>
                      <a href={config.nav.freebie} target="_blank" rel="noreferrer noopener">
                        {t('faqs')}
                      </a>
                    </li>
                    <li>
                      <a href={`${homePageUrl}/terms`}>{t('terms')}</a>
                    </li>
                    <li>
                      <a href={`${homePageUrl}/personal-policy`}>{t('personal_policy')}</a>
                    </li>
                    <li>
                      <a href={`${homePageUrl}/policy`}>{t('policy')}</a>
                    </li>
                    <li>
                      <a href={config.acworks.home} target="_blank" rel="noreferrer noopener">
                        {t('our_company')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright">
              <div className="social-links" style={{ paddingBottom: 20 }}>
                <Button
                  style={{
                    fontSize: '80%',
                    verticalAlign: 'middle',
                    width: '160px',
                    height: '36px',
                    background: '#4d4d4d',
                    border: '1px solid #4d4d4d',
                    color: '#b9b9b9',
                  }}
                  className="ant-btn"
                  onClick={() => {
                    window.open(config.nav.freebieHelp, '_blank')
                  }}
                >
                  <i
                    style={{ fontSize: '18px', paddingRight: '2px' }}
                    className="fa fa-commenting-o"
                  />
                  {t('feedback')}
                </Button>
              </div>
              {/* Modal */}
              <Feedback
                isVisible={openFeedback}
                toggle={() => {
                  setopenFeedback(false)
                }}
              />
            </div>
            <div className="credits">{t('copyright')}</div>
          </div>
        </div>
      </footer>
    </div>
    // </div>
  )
}

export default Footer
