import { CloudOutlined, MailOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs } from 'antd'
import Dropzone from 'components/dropzone'
import { TAB, VIEW } from 'config/constant'
import FileManager from 'features/manager/Manager'
import { setTab } from 'features/manager/managerSlice'
import ModalContainer from 'features/modal'
import { setView } from 'features/view-by/type/viewTypeSlice'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { updateFilesExpiry } from 'services/upload'
import { useLocation, useNavigate } from 'react-router-dom'

const { TabPane } = Tabs

function DashboardFiles() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // const dashboardFiles = useRef()
  const [isOpenShareModal, setOpenShareModal] = useState(false)
  const [zippedFile, handleZippedFile] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [expiry, setExpiry] = useState('3')

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location?.pathname === '/cloud') {
      navigate('/')
    }
  }, [])

  // const executeScroll = () => {
  //   dashboardFiles.current.scrollIntoView({ be`havior: 'smooth' })
  // }
  useEffect(() => {
    let view = { type: VIEW.TYPE.LIST, size: VIEW.SIZE.SMALL }
    if (localStorage.getItem('view_type')) {
      view = { type: localStorage.getItem('view_type'), size: localStorage.getItem('view_size') }
    }
    dispatch(setView(view))
  }, [])

  const handleFilesExpiry = async value => {
    const allFileSharedIds = JSON.parse(localStorage.getItem('sharedids')) || []
    if (allFileSharedIds.length > 0) {
      await updateFilesExpiry({ sharedIds: allFileSharedIds, expiredDate: value })
    }
    setExpiry(value)
  }

  if (
    (localStorage.getItem('tabIndex') === null || localStorage.getItem('sessionUserId') === null) &&
    localStorage.getItem('cloud') !== '1'
  ) {
    localStorage.setItem('tabIndex', '1')
    localStorage.setItem('cloud', '1')
  }

  const tabChanged = activeKey => {
    if (activeKey === '1') {
      localStorage.setItem('tabIndex', '1')
      dispatch(setTab(TAB.TRANSFER))
      navigate('/transfer')
    } else if (activeKey === '2') {
      localStorage.setItem('tabIndex', '2')
      dispatch(setTab(TAB.CLOUD))
      navigate('/folder')
    }
  }
  const defaultActiveKey = localStorage.getItem('tabIndex') || 1
  return (
    <div id="dashboard-files">
      <div
        className="container-fluid py-3 mr-auto ml-auto"
        style={{ padding: '0', width: '90vw', maxWidth: '1400px' }}
      >
        <Helmet title={t('my_files')} />
        <Tabs
          defaultActiveKey={defaultActiveKey}
          onChange={tabChanged}
          style={{ minHeight: '60vh' }}
          type="card"
        >
          <TabPane
            tab={
              <span style={{ width: 236 }}>
                <MailOutlined style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                <span style={{ fontSize: '14px' }}>{t('transfer_tab')}</span>
              </span>
            }
            key="1"
          >
            <ModalContainer />
            <Dropzone
              disableShareByURL
              handleZippedFile={handleZippedFile}
              expiry={expiry}
              handleFilesExpiry={handleFilesExpiry}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <CloudOutlined style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                <span style={{ fontSize: '14px' }}>{t('stored_tab')}</span>
              </span>
            }
            key="2"
          >
            <div id="file-manager">
              <ModalContainer />
              <FileManager />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default DashboardFiles
