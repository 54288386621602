import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  checkDuplicateFile,
  checkUserCanShare,
  deleteFiles,
  deleteFolder,
  genURL,
  renameFile,
  renameFolder,
  shareFolder,
} from 'services/upload'
import { DEFAULT_FOLDER_PATH } from 'config/constant'

export const downloadFileThunk = createAsyncThunk(
  'files/download',
  async (fileItem, { rejectWithValue }) => {
    try {
      const data = {
        url: await genURL(fileItem),
        name: fileItem.name,
      }
      return data
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)

export const createLinkToShare = createAsyncThunk(
  'share/link',
  async (folder, { rejectWithValue }) => {
    try {
      const share = await shareFolder({
        sender: 'aaa@aaa.aaa',
        receivers: ['aaa@aaa.aaa'],
        notification: false,
        selected: [folder],
        message: '',
      })
      return share
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)

export const renameThunk = createAsyncThunk('rename/', async (data, { rejectWithValue }) => {
  try {
    const duplicate = await checkDuplicateFile(data)
    if (duplicate.status === 'ok') {
      if (data.type === 'file') await renameFile(data)
      if (data.type === 'folder') await renameFolder(data)
      return true
    }
    if (duplicate.status === 'existed') {
      return 'existed'
    }
    return 'error'
  } catch (error) {
    return rejectWithValue('error')
  }
})

export const getUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState()

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`,
        },
      }

      const { data } = await axios.get(`/api/user/profile`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      }
      return rejectWithValue(error.message)
    }
  },
)

export const checkCanShareThunk = createAsyncThunk(
  'share/checkCanShare',
  async (sessionUserId, { rejectWithValue }) => {
    try {
      const resultCanShare = await checkUserCanShare(sessionUserId)
      return resultCanShare
    } catch (error) {
      return rejectWithValue('error')
    }
  },
)
