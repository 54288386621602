import { DEFAULT_ICON_PROFILE_URL } from 'config/constant'
import { getSessionUser, logoutSession, verifyUser } from 'services/sso'
import config from 'config'
import crypto from 'crypto'
import {
  deleteCookie,
  genDisplayName,
  getCookie,
  isUgly,
  notUgly,
  setCookie,
  setCookies,
} from 'utils'
import {
  createFolderNewUser,
  createShortCut,
  getInfoUserByUserId,
  updateUserSSOId,
  validateSSOId,
  validateUUID,
} from '../upload'

const { verifyJwtToken } = require('../jwt_verify')

export const login = async ssoToken => {
  console.log('lsso')
  try {
    if (notUgly(ssoToken)) {
      const res = await verifyUser(ssoToken)
      const token = res.data?.token
      const decoded = await verifyJwtToken(token)
      const resp = await getSessionUser(decoded.global_session_id)
      const { sessionUser } = resp.data
      if (resp.error || isUgly(sessionUser)) {
        clearStorage()
        return null
      }
      if (sessionUser) {
        const uuid = await validateUUID(decoded.user_id)
        if (isUgly(uuid)) return null
        localStorage.setItem('uuid', uuid)
        updateProfile(sessionUser)
        updateCookies(decoded, token)
        return sessionUser
      }
    }
  } catch (err) {
    console.log(err)
    console.log('errrorrrr')
  }
  console.log('return')
  clearStorage()
  return null
}
const decrypt = (encrypted, method, secret, hmac) => {
  try {
    if (
      crypto
        .createHmac('md5', secret)
        .update(encrypted)
        .digest('hex') === hmac.value
    ) {
      const decryptor = crypto.createDecipheriv(
        method,
        secret,
        // eslint-disable-next-line new-cap
        new Buffer.from(encrypted.substr(0, 24), 'base64').toString(),
      )
      let dec = decryptor.update(encrypted.substr(24), 'base64', 'utf8')
      dec += decryptor.final('utf8')
      return dec
    }
    return null
  } catch (e) {
    console.log('e, ', e)
    return null
  }
}
const decryptWithTSValidation = (encrypted, method, secret, hmac, intervalThreshold) => {
  try {
    const decrypted = decrypt(encrypted, method, secret, hmac)
    if (decrypted) {
      const now = new Date()
      const year = parseInt(decrypted.substr(0, 4), 10)
      const month = parseInt(decrypted.substr(5, 2), 10) - 1
      const day = parseInt(decrypted.substr(8, 2), 10)
      const hour = parseInt(decrypted.substr(11, 2), 10)
      const minute = parseInt(decrypted.substr(14, 2), 10)
      const second = parseInt(decrypted.substr(17, 2), 10)
      const msgDate = new Date(Date.UTC(year, month, day, hour, minute, second))
      if (Math.round((now - msgDate) / 1000) <= intervalThreshold) {
        return decrypted.substr(19)
      }
    }
    return null
  } catch (e) {
    return null
  }
}
const method = 'AES-256-CBC'
const secret = 'ZAQWSD12345dQWUHACWORKSSKROWCAAA'

export const preSaveMaterial = async (metadata, expired) => {
  try {
    let expiredTime = null
    const currentTime = Math.floor(new Date() / 1000)

    if (expired && metadata) {
      const hmac = {
        value: crypto
          .createHmac('md5', secret)
          .update(expired.replace(/@/g, '/').replace(/-/g, '+'))
          .digest('hex'),
      }

      expiredTime = decryptWithTSValidation(
        expired.replace(/@/g, '/').replace(/-/g, '+'),
        method,
        secret,
        hmac,
        60 * 60 * 24,
      )
    }

    if (expiredTime && currentTime <= +expiredTime) {
      const sessionUserId = getCookie('sessionUserId')
      console.log('save material ', metadata, expired, sessionUserId)
      if (notUgly(sessionUserId)) {
        const uuid = await validateSSOId(sessionUserId)
        console.log('uu', uuid)
        if (isUgly(uuid)) {
          await createFolderNewUser({
            path: 'メルマガ素材/',
          }).then(async res => {
            localStorage.setItem('uuid', res.id)
            await updateUserSSOId({ sso: sessionUserId })
          })
        } else {
          localStorage.setItem('uuid', uuid)
        }
        // const { uuid } = await getUserBySSOId({ sso: localStorage.getItem('sessionUserId') })
        await createShortCut(
          {
            path: 'メルマガ素材/',
            fileName: metadata,
            origin: 'f62ff766-1bb3-463f-b671-72f3974c07ACWORKS',
          },
          localStorage.getItem('uuid'),
        )
        return metadata
      }
      const link = document.createElement('a')
      link.href = `${config.sso.serverUrl}/login?serviceURL=${config.sso.redirect}`
      document.body.appendChild(link)
      link.click()
    }
    return ''
  } catch (e) {
    return false
  }
}

export const isPremium = async userId => {
  console.log('prei')
  try {
    const user = await getInfoUserByUserId(userId)
    if (user && user.premium_info && user.premium_info.using_service === null) {
      console.log('iiii')
      return true
    }
  } catch (err) {
    console.log(err)
    return false
  }
  console.log('pre false')
  return false
}

export const getUsersByTokenCookies = async globalSessionId => {
  console.log('bco', globalSessionId)

  try {
    if (notUgly(globalSessionId)) {
      const resp = await getSessionUser(globalSessionId)
      const { sessionUser } = resp.data
      if (resp.error || isUgly(sessionUser)) {
        clearStorage()
        return null
      }
      const uuid = await validateSSOId(sessionUser.user_id)
      if (isUgly(uuid)) return null
      localStorage.setItem('uuid', uuid)
      updateProfile(sessionUser)
      return sessionUser
    }
  } catch (err) {
    console.log(err)
  }
  console.log('co return')
  return null
}

export const autoLoginWithSSO = async globalSessionId => {
  try {
    if (notUgly(globalSessionId)) {
      const resp = await getSessionUser(globalSessionId)
      const { sessionUser } = resp.data
      if (resp.error || isUgly(sessionUser)) {
        clearStorage()
        return null
      }
      if (sessionUser) {
        window.location.href = `${config.sso.loginUrl}&autoLogin=true&ssoToken=`
        // const { token } = sessionUser
        // const decoded = await verifyJwtToken(token)
        // console.log(decoded)
        // const uuid = await validateUUID(decoded.user_id)
        // if (isUgly(uuid)) return null
        // localStorage.setItem('uuid', uuid)
        // updateProfile(sessionUser)
        // updateCookies(decoded, token)
        // return sessionUser
      }
    }
  } catch (err) {
    console.log(err)
  }
  clearStorage()
  return null
}

export const clearStorage = ssoLogged => {
  localStorage.removeItem('uuid')
  localStorage.removeItem('share_uuid')
  localStorage.removeItem('global_session_id')
  deleteCookie('global_session_id', '/')
  deleteCookie('sessionUserId', '/')
  deleteCookie('token', '/')
  deleteCookie('name_usr', '/')
  deleteCookie('email_usr', '/')
  deleteCookie('avatar_usr', '/')
  if (ssoLogged) {
    const iframe = document.getElementById('ssoAutoLoginIframe')?.contentWindow
    if (iframe)
      iframe.postMessage(
        JSON.stringify({
          delete_sso_logged: ssoLogged,
        }),
        '*',
      )
  }
}

const updateProfile = userInfo => {
  const name = genDisplayName({
    userEmail: userInfo.email,
    userName: userInfo.nick_name,
  })
  setCookies({ name: 'name_usr', value: name })
  setCookies({ name: 'email_usr', value: userInfo.email })
  if (notUgly(userInfo?.avatar)) setCookies({ name: 'avatar_usr', value: userInfo.avatar })
  else setCookies({ name: 'avatar_usr', value: DEFAULT_ICON_PROFILE_URL })
}

const updateCookies = (decoded, token) => {
  setCookies({ name: 'token', value: token, timestime: decoded.exp })
  setCookies({
    name: 'global_session_id',
    value: decoded.global_session_id,
    timestime: decoded.exp,
  })
  setCookies({
    name: 'sessionUserId',
    value: decoded.user_id,
    timestime: decoded.exp,
  })
}

export const validatePremiumUser = userInfo => {
  if (userInfo) {
    return notUgly(userInfo.premium_info)
  }
  return false
}

export const logout = async () => {
  const globalSessionToken = getCookie('global_session_id')
  clearStorage()
  const result = await logoutSession(globalSessionToken)
  console.log(result)
  // window.location.href = `${config.sso.serverUrl}/logout?sessionID=${globalSessionToken}&serviceURL=${config.sso.redirect}`
}

export default {
  login,
  logout,
  getUsersByTokenCookies,
}
