import { useCallback, useEffect, useState } from 'react'
import { useDragDropManager } from 'react-dnd'

export function useContextMenu() {
  const [xPos, setXPos] = useState('0px')
  const [yPos, setYPos] = useState('0px')
  const [showMenu, setShowMenu] = useState(false)

  // const dragDropManager = useDragDropManager()
  // const monitor = dragDropManager.getMonitor()

  // useEffect(
  //   () =>

  //     monitor.subscribeToOffsetChange(() => {
  //       const offset = monitor.getClientOffset()
  //       console.log(monitor.getItem())
  //     }),
  //   [monitor],
  // )

  const handleContextMenu = useCallback(e => {
    e.preventDefault()
    if (e.target instanceof HTMLElement) {
      const fileCardDom = e.target.closest('.file-card')
      const fileRowDom = e.target.closest('.row-selected')
      if (fileCardDom || fileRowDom) {
        setShowMenu(true)
        setXPos(`${e.clientX}px`)
        setYPos(`${e.clientY}px`)
      }
    }
  }, [])

  const handleClick = useCallback(() => {
    setShowMenu(false)
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    document.addEventListener('contextmenu', handleContextMenu)
    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  return { xPos, yPos, showMenu }
}

export default useContextMenu
