import { DeleteOutlined, DownloadOutlined, EyeOutlined, LinkOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { SharedAvatar } from 'components/share/SharedAvatar'
import { VIEW } from 'config/constant'
import { format, parse } from 'date-fns'
import { ja, enUS } from 'date-fns/locale'
import FileItem from 'features/manager/components/main-view/components/list-view/Columns/FileItem'
import FileItemLarge from 'features/manager/components/main-view/components/list-view/Columns/FileItemLarge'
import FolderItem from 'features/manager/components/main-view/components/list-view/Columns/FolderItem'
import FolderItemLarge from 'features/manager/components/main-view/components/list-view/Columns/FolderItemLarge'
import { useRouter, useHandleAction, useHandleMouse } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { managerSelector, viewSelector } from 'redux/selectors'
import { getInfoUserBySessionUserId } from 'services/upload'
import { formatTimeFromText } from 'utils'

export function useHandleColumns() {
  const { t } = useTranslation()
  const { router } = useRouter()
  const { handleConfirmDelete, handleDownload, handleCreateLinkShare } = useHandleAction()
  const { handlePreview } = useHandleMouse()
  const { inTrashBin, inFreebieFolder, inMyFolder, inSharedFolder } = router
  const { selected, notSelected, canEdit } = useSelector(managerSelector)
  const { viewSize } = useSelector(viewSelector)

  const selectOne = selected.length > 1
  const disableInShare = selectOne || !canEdit

  const ItemAction = ({ title, icon, onClick, hidden = false }) => {
    return {
      title,
      align: 'center',
      width: 120,
      render: item => {
        if (inTrashBin) return <span>-</span>
        return (
          <Tooltip mouseLeaveDelay={0} placement="bottomLeft" title={title} arrowPointAtCenter>
            <Button
              type="link"
              disabled={hidden}
              className="btn-hover-border"
              // style={{
              //   background: hidden ? '#f2f4f8' : 'none',
              //   color: hidden ? 'grey !important' : 'black',
              // }}
              icon={icon}
              onClick={e => {
                e.preventDefault()
                onClick(item)
              }}
            />
          </Tooltip>
        )
      },
    }
  }
  const fileColumn = [
    {
      title: <Tooltip title={t('click_to_sort')}>{t('list_file_name')}</Tooltip>,
      key: 'fileName',
      width: '55%',

      render: item => {
        if (item.type === 'file') {
          return viewSize === VIEW.SIZE.SMALL ? (
            <FileItem item={item} />
          ) : (
            <FileItemLarge item={item} />
          )
        }
        return viewSize === VIEW.SIZE.SMALL ? (
          <FolderItem item={item} />
        ) : (
          <FolderItemLarge item={item} />
        )
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ]

  const lastModifiedColumns = [
    {
      title: <Tooltip title={t('click_to_sort')}>{t('list_last_modified')}</Tooltip>,
      key: 'lastModified',
      width: 155,
      render: item => {
        return item.lastModified
          ? format(parse(item.lastModified, 'yyyy-MM-dd HH:mm:ss', new Date()), 'PPP', {
              locale: document.documentElement.lang === 'ja' ? ja : enUS,
            })
          : '-'
      },
      sorter: (a, b) => new Date(a.lastModified) - new Date(b.lastModified),
    },
  ]
  const lastDeletedColumns = [
    {
      title: <Tooltip title={t('click_to_sort')}>{t('deleted')}</Tooltip>,
      key: 'lastModified',
      // align: 'center',
      render: item => {
        return item.lastModified
          ? format(parse(item.lastModified, 'yyyy-MM-dd HH:mm:ss', new Date()), 'PPP', {
              locale: document.documentElement.lang === 'ja' ? ja : enUS,
            })
          : '-'
      },
      sorter: (a, b) => new Date(a.lastModified) - new Date(b.lastModified),
    },
  ]

  const lastSharedColumns = [
    {
      title: <Tooltip title={t('click_to_sort')}>{t('shared_date')}</Tooltip>,
      key: 'lastShared',
      // align: 'center',
      render: item => {
        const lastDate = item.updated_at || item.lastModified
        return lastDate ? (
          <span style={{ fontSize: 14 }}>{formatTimeFromText(lastDate, ja)}</span>
        ) : (
          '-'
        )
      },
      // render: item => {
      //   const lastDate = item.updated_at || item.lastModified
      //   return lastDate
      //     ? format(Date.parse(lastDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'PPP', {
      //         locale: document.documentElement.lang === 'ja' ? ja : enUS,
      //       })
      //     : '-'
      // },
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
    },
  ]
  const sharedByColumns = [
    {
      title: t('shared_by'),
      key: 'sharedBy',
      // align: 'center',
      render: item => {
        return <SharedAvatar shareUUID={item.share_uuid} />
      },
    },
  ]

  const previewColumns = [
    {
      title: t('list_file_preview'),
      key: 'preview',
      align: 'center',
      width: 100,
      render: item =>
        item.type === 'file' ? (
          <Tooltip
            mouseLeaveDelay={0}
            placement="bottomLeft"
            title={t('list_file_preview')}
            arrowPointAtCenter
          >
            <Button
              disabled={selectOne}
              type="link"
              className="btn-hover-border"
              icon={<EyeOutlined />}
              onClick={() => {
                handlePreview(item)
              }}
            />
          </Tooltip>
        ) : (
          '-'
        ),
    },
  ]
  const sizeColumns = [
    {
      title: t('list_file_size'),
      key: 'preview',
      // align: 'center',
      width: 120,
      render: item =>
        item.type === 'file' ? (
          <Tooltip
            mouseLeaveDelay={0}
            placement="bottomLeft"
            title={t('list_file_size')}
            arrowPointAtCenter
          >
            <span style={{ fontSize: 14 }}>{item.size}</span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
  ]

  const actionColums = [
    ItemAction({
      title: t('copy_link'),
      icon: <LinkOutlined />,
      onClick: handleCreateLinkShare,
      hidden: selectOne,
    }),
    ItemAction({
      title: t('list_download'),
      icon: <DownloadOutlined />,
      onClick: handleDownload,
      hidden: selectOne,
    }),
    // ItemAction({
    //   title: t('list_delete_file'),
    //   icon: <DeleteOutlined />,
    //   onClick: handleConfirmDelete,
    //   hidden: selectOne,
    // }),
  ]

  const inSharedActionColums = [
    ItemAction({
      title: t('list_download'),
      icon: <DownloadOutlined />,
      onClick: handleDownload,
      hidden: selectOne,
    }),
    ItemAction({
      title: t('list_delete_file'),
      icon: <DeleteOutlined className={disableInShare ? 'icon-disable' : ''} />,
      onClick: handleConfirmDelete,
      hidden: disableInShare,
    }),
  ]

  let columns = []
  if (inTrashBin) {
    columns = columns.concat(fileColumn, lastDeletedColumns, sizeColumns)
  } else if (inMyFolder) {
    columns = columns.concat(fileColumn, lastModifiedColumns, sizeColumns, actionColums)
  } else if (inFreebieFolder) {
    columns = columns.concat(fileColumn, lastModifiedColumns, previewColumns)
  } else if (inSharedFolder) {
    columns = columns.concat(fileColumn, sharedByColumns, lastSharedColumns)
  }

  return { columns }
}

export default useHandleColumns
