import { DeleteOutlined, FileSyncOutlined } from '@ant-design/icons'
import React from 'react'
import { useHandleAction } from 'hooks'
import { useSelector } from 'react-redux'
import { selectedFileSelector } from 'redux/selectors'
import { ItemMenu } from './ItemMenu'

export function TrashContext({ controls }) {
  const { selectedFiles } = useSelector(selectedFileSelector)
  const { handleConfirmRestores, handleConfirmDeleteForever } = useHandleAction()

  if (selectedFiles.length === 0) return null

  return (
    <div className="context-menu">
      <ItemMenu
        icon={<FileSyncOutlined />}
        name="restore_file"
        handle={handleConfirmRestores}
        controls={controls}
      />
      <ItemMenu
        icon={<DeleteOutlined />}
        name="delete_forever"
        handle={handleConfirmDeleteForever}
        controls={controls}
      />
    </div>
  )
}

export default TrashContext
