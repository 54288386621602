// export const handleDeleteFiles = async (data, files) => {
//   const { folder } = this.state
//   const { t } = this.props

import { DEFAULT_FOLDER_PATH } from 'config/constant'
import { verifyPath } from 'utils'
import {
  deleteFiles,
  deleteFilesForever,
  deleteFolder,
  deleteFoldersForever,
  restoreFiles,
  restoreFolders,
} from '../upload'

//   if (isSharedFolder === 'true' && currentFolder === '') {
//     try {
//       await Promise.all(
//         files.map(async file => {
//           const params = {
//             name: file.name,
//             path: file.path,
//             share_uuid: file.share_uuid,
//             shared_uuid: localStorage.getItem('uuid'),
//           }
//           await removeShared(params)
//         }),
//       )
//       localStorage.removeItem('share_uuid')
//       openNotificationWithIcon('success', t('delete_files_success'))
//       this.handleSelectShared()
//     } catch (e) {
//       openNotificationWithIcon('fail', t('delete_folder_success'))
//     }
//   } else if (isSharedFolder === 'false') {
//     deleteFiles(data)
//       .then(res => {
//         openNotificationWithIcon('success', t('delete_files_success'))
//         // this.setState({})
//         this.loadFiles(folder)
//       })
//       .catch(e => openNotificationWithIcon('fail', t('delete_files_fail')))
//   } else {
//     deleteFiles(data)
//       .then(res => {
//         openNotificationWithIcon('success', t('delete_files_success'))
//         // this.setState({})
//         this.handleSharedFolders(localStorage.getItem('current_folder'))
//       })
//       .catch(e => openNotificationWithIcon('fail', t('delete_files_fail')))
//   }
// }

export const handleRestoreMultipleFiles = async ({ selectedFiles, returnError }) => {
  let reloadAll = false
  if (returnError) throw Error
  const files = selectedFiles.filter(item => item.type === 'file')
  const folders = selectedFiles.filter(item => item.type === 'folder')
  const filesData = {
    files: files.map(item => ({
      path: item.path,
      name: item.name,
    })),
  }
  try {
    const foldersData = { folders: folders.map(item => item.path) }
    if (filesData.files.length > 0) await restoreFiles(filesData)
    if (foldersData.folders.length > 0) {
      reloadAll = true
      await restoreFolders(foldersData)
    }
    return reloadAll
  } catch (error) {
    return error
  }
}
export const handleDeleteFilesForever = async ({ selectedFiles }) => {
  let reloadAll = false
  const files = selectedFiles.filter(item => item.type === 'file')
  const folders = selectedFiles.filter(item => item.type === 'folder')
  const filesData = {
    files: files.map(item => ({
      path: item.path,
      name: item.name,
    })),
  }
  try {
    const foldersData = { folders: folders.map(item => item.path) }
    if (filesData.files.length > 0) await deleteFilesForever(filesData)
    if (foldersData.folders.length > 0) {
      reloadAll = true
      await Promise.all(
        foldersData.folders.map(async folder => {
          await deleteFoldersForever({ folder })
        }),
      )
    }
    return reloadAll
  } catch (error) {
    console.log(error)
    return error
  }
}

export const handleDeleteFiles = async ({ selectedFiles, currentFolder }) => {
  try {
    currentFolder = verifyPath(currentFolder)
    if (selectedFiles.length > 0) {
      const fileNames = []
      const folderNames = []
      selectedFiles.forEach(element => {
        if (element.type === 'folder') folderNames.push(element.path)
        else if (element.type === 'file') fileNames.push(element.name)
      })

      if (fileNames.length > 0) {
        const data = {
          path: currentFolder || DEFAULT_FOLDER_PATH,
          filenames: fileNames,
        }
        await deleteFiles(data)
      }
      if (folderNames.length > 0) {
        await Promise.all(
          folderNames.map(async path => {
            await deleteFolder({
              path: `${path}/`,
            })
          }),
        )
      }
    }
    return true
  } catch (error) {
    return error
  }
}
