import { RightOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu } from 'antd'
import { config } from 'config'
import { DEFAULT_ICON_PROFILE_URL, DEFAULT_FREEBIE_PATH, USER_TYPE } from 'config/constant'
import { getFolders } from 'features/manager/folder/folderActions'
import GoogleOneTap from 'features/manager/google_onetap'
import { userLogin, userLogout } from 'features/user/userActions'
import { useRouter } from 'hooks'
import ListApp from 'layouts/elements/top-bar/ListApp'
import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Img from 'react-image'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams, useLocation } from 'react-router-dom'
import { userSelector } from 'redux/selectors'
import { preSaveMaterial } from 'services/users/user.services'
import { getCookie, notUgly } from 'utils'

export function UserInfo() {
  const { t, i18n } = useTranslation()
  const { metadata, expired } = useParams()

  const [displayUser, setDisplayUser] = useState()
  const [displayAvatar, setDisplayAvatar] = useState()
  const { userInfo } = useSelector(userSelector)
  const { isAuth } = userInfo
  const dispatch = useDispatch()
  const { navigate } = useRouter()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.includes('m/')) {
      localStorage.setItem('urlPathInput', location.pathname)
    }
    const ssoToken = searchParams.get('ssoToken')
    dispatch(userLogin({ ssoToken }))
    // if (ssoToken) navigate('/')
  }, [])

  const saveMaterial = async () => {
    const result = await preSaveMaterial(metadata, expired)
    if (result) {
      localStorage.removeItem('urlPathInput')
      navigate(`/freebie/${DEFAULT_FREEBIE_PATH}/${result}`)
    }
    // else navigate(`/`)
  }
  useEffect(() => {
    setDisplayUser(getCookie('name_usr'))
    setDisplayAvatar(getCookie('avatar_usr'))
    saveMaterial()
  }, [userInfo])

  const handleLogout = () => {
    dispatch(userLogout())
  }

  const userMenu = (
    <Menu style={{ top: 5 }} className="shadow-lg">
      <Menu.Item>
        <a
          type="link"
          style={{
            color: 'black',
          }}
          href={config.sso.myPage}
        >
          {t('my_page')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          type="link"
          style={{
            color: 'black',
          }}
          href="/folder"
        >
          {t('my_folder')}
        </a>
      </Menu.Item>
      {/* <Fragment>
        <LanguageSwitcher />
      </Fragment> */}
      <Menu.Item>
        <a
          href=""
          style={{
            color: 'black',
          }}
          onClick={() => {
            handleLogout()
          }}
        >
          {t('logout_button')}
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Fragment>
      {!isAuth && (
        <>
          <li className="header-menu-item-show">
            <a
              type="primary"
              style={{
                color: '#1f4be6',
                fontSize: 14,
                padding: '5px 10px',
                whiteSpace: 'nowrap',
                height: 31,
                marginRight: 20,
                border: '1px solid #1f4be6',
                borderRadius: '.25rem',
              }}
              href={config.sso.signUpUrl}
            >
              <span>{t('signup_button')}</span>
            </a>
          </li>
          <li className="header-menu-item-show">
            <a
              type="primary"
              style={{
                color: 'white',
                background: '#1f4be6',
                fontSize: 14,
                padding: '5px 10px',
                whiteSpace: 'nowrap',
                height: 31,
                marginRight: 20,
                width: 108,
                textAlign: 'center',
                borderRadius: '.25rem',
              }}
              href={config.sso.loginUrl}
            >
              <span>
                <img
                  style={{ height: '14px', marginRight: '6px', marginBottom: '2px' }}
                  src="/resources/sign-in.png"
                  alt=""
                />
                {t('login_button')}
              </span>
            </a>
          </li>
          <li className="hide-phone-component">
            <ListApp lang={i18n.language === 'ja' ? 'jp' : 'en'} />
          </li>
        </>
      )}
      {isAuth && (
        <>
          <li className="hide-phone-component">
            <div style={{ height: 40, width: 40 }}>
              <ListApp lang={i18n.language === 'ja' ? 'jp' : 'en'} />
            </div>
          </li>
          <li className="hide-phone-component">
            <Dropdown
              overlay={userMenu}
              trigger={['click']}
              placement="bottomLeft"
              overlayStyle={{ top: 5, color: 'red' }}
            >
              <div
                style={{
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ height: 40, width: 40 }}>
                  <Img
                    src={[displayAvatar]}
                    style={{
                      borderRadius: '50%',
                      height: 40,
                      width: 40,
                    }}
                    unloader={
                      <Avatar
                        shape="circle"
                        size={50}
                        style={{
                          borderRadius: '50%',
                          height: 40,
                          width: 40,
                        }}
                        src={DEFAULT_ICON_PROFILE_URL}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <span
                    style={{
                      fontSize: 11,
                      display: 'flex',
                    }}
                  >
                    {userInfo.isPremium ? (
                      <>
                        <img
                          src="/resources/images/ico_premium.png"
                          alt="premium"
                          style={{ height: 17, marginRight: 5 }}
                        />
                        <span>{t('user_premium')}</span>
                      </>
                    ) : (
                      <span>{t('user_free')}</span>
                    )}
                  </span>
                  <span
                    style={{
                      fontSize: 11,
                      display: 'flex',
                      textTransform: 'none',
                    }}
                  >
                    {displayUser}
                    {t('san')}
                  </span>
                </div>
                <div>
                  <RightOutlined style={{ fontSize: '12px', marginLeft: 3 }} />
                </div>
              </div>
            </Dropdown>
          </li>
        </>
      )}
      <GoogleOneTap />
    </Fragment>
  )
}

export default memo(UserInfo)
