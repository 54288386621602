export const GRID_VIEW = 'grid'
export const TABLE_VIEW = 'table'

export const LARGE_VIEW_SIZE = 'large_view'
export const DEFAULT_VIEW_SIZE = 'default_view'
// export const SMALL_VIEW_SIZE = 'small_view'

//

export const TAB = {
  CLOUD: 'cloud',
  TRANSFER: 'transfer',
}
export const CLOUD = 'cloud'
export const TRANSFER = 'transfer'

export const ROOT_PATH = '/'
export const LANGUAGE = 'language'
export const LANG_ENGLISH = 'en'
export const LANG_JAPAN = 'ja'

export const TRASH_BIN = 'trash_bin'
export const SHARED_FOLDER = 'shared_folder'
export const MY_FOLDER = 'my_folder'
export const FREEBIE_FOLDER = 'freebie'

export const TRASH_BIN_URL = '/trash'
export const SHARED_FOLDER_URL = '/shared-folder'
export const MY_FOLDER_URL = '/folder'
export const FREEBIE_FOLDER_URL = '/freebie'

export const DEFAULT_FOLDER_PATH = '/'
export const DEFAULT_FREEBIE_PATH = 'メルマガ素材'
export const DEFAULT_ICON_PROFILE = '/profile-images/human/0-11.jpg'
export const DEFAULT_ICON_PROFILE_URL =
  'https://accounts.ac-illust.com/profile-images/human/0-11.jpg'
export const DEFAULT_ORIGIN_UUID = 'f62ff766-1bb3-463f-b671-72f3974c07ACWORKS'
export const DEFAULT_WIDTH = 290

export const VIEW = {
  TYPE: {
    LIST: 'list',
    GRID: 'grid',
  },
  SIZE: {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
  },
  SIZE_PX: {
    SMALL: 130,
    MEDIUM: 150,
    LARGE: 160,
  },
}

export const FOLDER_STATUS = {
  COLLAPSED: 'COLLAPSED',
  EXPANDED: 'EXPANDED',
}

export const FILE_TYPE = {
  FILE: 'file',
  FOLDER: 'folder',
}

export const ItemTypes = {
  CARD: 'card',
  ROW: 'row',
}

export const PERMISSION_SHARE = {
  VIEW: '閱覧者',
  EDIT: '編集者',
}

export const MODAL_TYPES = {
  duplicate: 'duplicate',
  mailMaga: 'mailMaga',
  filePreview: 'filePreview',
  restore: 'restore',
  trial: 'trial',
  addFolder: 'addFolder',
  shareToMail: 'shareToMail',
  shareToMailDropzone: 'shareToMailDropzone',
  shareToUser: 'shareToUser',
  rename: 'rename',
}

export const GB_BYTE = 1024 * 1024 * 1024
export const MB_BYTE = 1024 * 1024
export const BYTE = 1024

export const CONTEXT_MENU_FILE = 'context_menu_for_file'
export const CONTEXT_MENU_FOLDER = 'context_menu_for_folder'

export const SORT_BY_TYPE = {
  NAME: 'sort_by_name',
  LAST_MODIFIDED: 'sort_by_modified',
}
export const SORT_BY_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const ACTION = {
  RELOAD: 'reload',
  SHOW_MODAL: 'show_modal',
  HIDE_MODAL: 'hide_modal',

  VIEW: 'view',
  SHARE_TO_USER: 'share_to_user',
  RENAME: 'rename',
  UPDATE_NAME: 'update_name',
  DOWNLOAD: 'download',
  DOWNLOAD_MULTI: 'download_multiple_file',
  CREATE_LINK_SHARE: 'create_link_to_share',
  SUCCESS: 'OK',
  ERROR: 'error',

  DELETE: 'delete',
}

export const MODE = {
  DRAG_TO_SELECT: 'DRAG_TO_SELECT',
  DRAG_TO_DROP: 'DRAG_TO_DROP',
  MENU_CONTEXT: 'MENU_CONTEXT',
  NORMAL: 'NORMAL',
}

export const TypeDrag = {
  CARD: 'card',
  ROW: 'row',
}

export const USER_TYPE = {
  LOGIN: 'login',
  FREE_NOT_LOGIN: 'not_login',
}

export const isMobile = () => {
  return window.innerWidth < 1050
}
