import { Button, Empty } from 'antd'
import { useRouter } from 'hooks'
import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { fileSelector } from 'redux/selectors'

export function EmptyComponent() {
  const { t } = useTranslation()
  const { inFreebieFolder, inTrashBin } = useRouter().router
  const { empty } = useSelector(fileSelector)

  if (inFreebieFolder && empty)
    return (
      <div style={{ color: 'black' }}>
        <h3>{t('freebies_registration_message')}</h3>
        <p>{t('freebies_registration_description')}</p>
        <div>
          <div className="freebie-button">
            <a
              style={{ color: 'white' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.freebie-ac.jp/"
            >
              {t('freebies_registration_button_text')}
            </a>
          </div>
        </div>
      </div>
    )

  if (inTrashBin)
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_DEFAULT}
        description={
          <div>
            <p style={{ fontSize: 17, color: 'grey' }}>{t('no_data')}</p>
          </div>
        }
      />
    )

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_DEFAULT}
      description={
        <div>
          <p style={{ fontSize: 17, color: 'grey' }}>{t('no_data')}</p>
          <span>{t('no_data_description')}</span>
        </div>
      }
    />
  )
}
export default EmptyComponent
