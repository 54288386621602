import axios from 'axios'
import { getCookie } from 'utils'
import { startUpload, updateUserSSOId, validateSSOId } from './upload'
// import config from 'config/constant'
const source = axios.CancelToken.source()

// axios.defaults.baseURL = config.service.BASE_URL

const auth = axiosConfig => {
  // axiosConfig.headers.token = localStorage.getItem('token')
  // const uuid  = localStorage.getItem('uuid');
  // if(uuid){

  // }else
  return axiosConfig
}

export const validateUUID = async () => {
  const uuid = localStorage.getItem('uuid')
  if (uuid) return true
  const sessionUserId = getCookie('sessionUserId')
  if (sessionUserId) {
    const resp = await validateSSOId(sessionUserId)
    localStorage.setItem('uuid', resp)
    if (resp === '') {
      const data = {
        path: `path`,
        filenames: `filenames`,
        totalFiles: 100,
      }
      startUpload(data, source.token).then(result => {
        localStorage.setItem('uuid', result.id)
        updateUserSSOId({ sso: localStorage.getItem('sessionUserId') })
      })
    }
    return true
  }
  return false
}

axios.interceptors.request.use(auth)

export default axios
