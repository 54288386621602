import { Modal, Radio, Space, Spin } from 'antd'
import React from 'react'
import {
  ACTION,
  DEFAULT_FOLDER_PATH,
  DEFAULT_ORIGIN_UUID,
  GB_BYTE,
  MB_BYTE,
  MODAL_TYPES,
} from 'config/constant'
import { config } from 'config'
import { publish } from 'events/event'
import {
  onSelect,
  onSelectContext,
  onSelectMore,
  setPermissionEdit,
} from 'features/manager/managerSlice'
import { useHandleKeyboard, useRouter, useHandlePermission } from 'hooks'
import _path from 'path'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  managerSelector,
  selectedFileSelector,
  storageSelector,
  userSelector,
} from 'redux/selectors'
import {
  handleDeleteFiles,
  handleDeleteFilesForever,
  handleRestoreMultipleFiles,
} from 'services/files/delete.service'
import { createLinkShare, downloadMultiFiles } from 'services/files/download.services'
import { moveFile, moveFolder } from 'services/upload'
import { copy, isUgly, notUgly, removeSlash, validFile } from 'utils'
import confirmModal from 'components/modals/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { getStorage } from '../features/storage/storageActions'

const { confirm } = Modal

export function useHandleAction() {
  const dispatch = useDispatch()
  const { currentFolder, reloadAll, router, navigateTo } = useRouter()
  const { selectedFiles } = useSelector(selectedFileSelector)
  const { storage } = useSelector(storageSelector)
  const { userInfo } = useSelector(userSelector)
  const { isPremium } = userInfo
  const { selected } = useSelector(managerSelector)
  const { t } = useTranslation()

  const handleNewFolder = () => {
    publish(ACTION.SHOW_MODAL, { type: MODAL_TYPES.addFolder, data: '' })
  }

  const handleShareToMail = () => {
    publish(ACTION.SHOW_MODAL, {
      type: MODAL_TYPES.shareToMail,
      data: selectedFiles,
    })
  }

  const handlePreview = file => {
    const previewFile = validFile(file) ? file : selectedFiles[0]
    publish(ACTION.SHOW_MODAL, {
      type: MODAL_TYPES.filePreview,
      data: previewFile,
    })
  }

  const handleCreateLinkShare = async file => {
    const files = validFile(file) ? [file] : selectedFiles
    toast(
      <>
        <Spin indicator={<LoadingOutlined style={{ color: 'grey' }} spin />} />
        {t('obtaining_link')} ...
      </>,
      { containerId: 'alert' },
    )
    // await toast.promise(
    await createLinkShare({ selectedFiles: files })
    //   {
    //     pending: 'リンクを取得中です ...',
    //     success: { render: t('copy_file_url_success'), delay: 2000 },
    //     error: 'リンク作成エラー',
    //   },
    //   {
    //     containerId: 'alert',
    //   },
    // )
    toast.dismiss()
    Modal.success({
      content: t('copy_file_url_success'),
      centered: true,
      bodyStyle: { width: 400 },
      closable: false,
      maskClosable: false,
      keyboard: false,
      okText: t('button_yes'),
      onOk() {
        const link = `${config.endpoint.home}/${localStorage.getItem('share-id')}`
        copy(link)
        localStorage.removeItem('share-id')
      },
    })
  }

  const handleShareToUser = () => {
    publish(ACTION.SHOW_MODAL, {
      type: MODAL_TYPES.shareToUser,
      data: selectedFiles,
    })
  }

  const handleDownload = async file => {
    const files = validFile(file) ? [file] : selectedFiles
    const toastId = toast.loading(t('create_file_download_link'))
    const handleProgress = percentCompleted => {
      toast.update(toastId, {
        render: `${t('downloading_progress')} ${percentCompleted}%`,
        type: 'info',
        isLoading: true,
      })
    }

    const handleSuccess = () => {
      toast.update(toastId, {
        render: t('file_downloaded'),
        type: 'success',
        isLoading: false,
        autoClose: 1500,
      })
    }
    const handleError = () => {
      toast.update(toastId, {
        render: t('create_file_download_link_error'),
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      })
    }
    await downloadMultiFiles(
      {
        selectedFiles: files,
        currentFolder,
      },
      handleProgress,
      handleSuccess,
      handleError,
    )
  }

  const handleDelete = async file => {
    const files = validFile(file) ? [file] : selectedFiles
    await toast.promise(
      handleDeleteFiles({ selectedFiles: files, currentFolder }),
      {
        pending: t('notification:delete_loading', { length: selectedFiles.length }),
        success: t('notification:delete_success', { length: selectedFiles.length }),
        error: 'Delete error',
      },
      {
        containerId: 'alert',
      },
    )
    reloadAll()
    dispatch(getStorage())
  }

  function handleConfirmDelete() {
    localStorage.removeItem('origin_uuid')
    let content = ''
    if (selectedFiles.length === 1)
      content = t('modal:confirm_delete_content', { fileName: selectedFiles[0].name })
    if (selectedFiles.length > 1)
      content = t('modal:confirm_delete_content_many', { length: selectedFiles.length })
    confirmModal({
      content: <Trans>{content}</Trans>,
      handle: handleDelete,
      title: t('modal:confirm_delete_title'),
      okText: t('modal:confirm_delete_button'),
      cancelText: t('modal:cancel_button'),
    })
  }

  const handleRename = () => {
    publish(ACTION.SHOW_MODAL, {
      type: MODAL_TYPES.rename,
      data: selectedFiles[0],
    })
  }

  const onDropToMove = async folderPath => {
    if (selected.includes(removeSlash(folderPath)) || currentFolder === _path.join(folderPath, '/'))
      return
    const move = Promise.all(
      selectedFiles.map(async item => {
        if (item.type === 'file') {
          await moveFile({
            name: item.name,
            currentPath: currentFolder === '' ? '/' : _path.join(currentFolder, '/'),
            newPath: `${_path.join(folderPath, '/')}`,
          })
        } else if (item.type === 'folder') {
          await moveFolder({
            name: item.name,
            currentPath: `${currentFolder === '/' ? '' : _path.join(currentFolder, '/')}`,
            newPath: `${_path.join(folderPath, '/')}`,
          })
        }
      }),
    )
    await toast.promise(
      move,
      {
        pending: t('moving_files'),
        success: t('moving_file_success'),
        error: t('moving_file_error'),
      },
      {
        containerId: 'alert',
      },
    )
    reloadAll()
  }

  const onDropTreeToFolder = async (item, folderPath) => {
    if (selected.includes(removeSlash(folderPath))) return
    const move = async () => {
      await moveFolder({
        name: item.name,
        currentPath: `${currentFolder === '/' ? '' : _path.join(currentFolder, item.path)}/`,
        newPath: `${_path.join(folderPath, '/')}`,
      })
    }

    await toast.promise(
      move(),
      {
        pending: t('moving_files'),
        success: t('moving_file_success'),
        error: t('moving_file_error'),
      },
      {
        containerId: 'alert',
      },
    )
    reloadAll()
  }

  const handleConfirmRestores = async () => {
    localStorage.removeItem('origin_uuid')
    console.log('storeage: ', storage)
    let returnError = false
    if (storage >= 30 * GB_BYTE - 10 * MB_BYTE && isPremium) returnError = true
    if (storage >= 10 * GB_BYTE - 10 * MB_BYTE && !isPremium) returnError = true
    const reloadAllPage = await toast.promise(
      handleRestoreMultipleFiles({ selectedFiles, returnError }),
      {
        pending: t('notification:restore_loading', { length: selectedFiles.length }),
        success: t('notification:restore_success', { length: selectedFiles.length }),
        error: t('notification:restore_error'),
      },
      {
        containerId: 'alert',
      },
    )
    reloadAll(reloadAllPage)
    dispatch(getStorage())
  }

  const handleConfirmDeleteForever = () => {
    localStorage.removeItem('origin_uuid')
    confirmModal({
      content: t('delete_forever_content'),
      handle: () => {
        toast
          .promise(
            handleDeleteFilesForever({ selectedFiles }),
            {
              pending: t('delete_files_pending'),
              success: t('delete_files_success'),
              error: t('delete_files_fail'),
            },
            {
              containerId: 'alert',
            },
          )
          .then(reloadAllPage => {
            reloadAll(reloadAllPage)
            dispatch(getStorage())
          })
      },
      title: t('delete_forever_confirmation'),
      okText: t('button_yes'),
      cancelText: t('button_no'),
    })
  }

  const handleConfirmDuplicateOptions = () => {
    const a = confirmModal({
      content: (
        <div>
          {t('modal:confirm_duplicate_options_content')}
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>{t('replace_existing_file')}</Radio>
              <Radio value={2}>{t('keep_both_file')}</Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
      handle: () => {
        toast
          .promise(
            handleDeleteFilesForever({ selectedFiles }),
            {
              pending: t('delete_files_pending'),
              success: t('delete_files_success'),
              error: t('delete_files_fail'),
            },
            {
              containerId: 'alert',
            },
          )
          .then(reloadAllPage => reloadAll(reloadAllPage))
      },
      title: t('modal:confirm_duplicate_options_title'),
      okText: t('button_yes'),
      cancelText: t('button_no'),
    })
    console.log({ a })
  }

  // const handleClick = value => {
  //   updateUUID(value)
  //   if (cmdKeyPress) dispatch(onSelectMore(value.key))
  //   else dispatch(onSelect(value.key))
  // }

  // const handleContextMenu = value => {
  //   updateUUID(value)
  //   dispatch(onSelectContext(value.key))
  // }

  // const handleDragStart = value => {
  //   if (preventDrag || preventUpload) return
  //   updateUUID(value)
  //   dispatch(onSelectContext(value.key))
  // }

  // const handleDoubleClick = value => {
  //   if (inTrashBin) {
  //     return
  //   }

  //   if (inSharedFolder && notUgly(value.canEdit)) {
  //     dispatch(setPermissionEdit(value.canEdit))
  //   }

  //   if (value.type === 'folder') {
  //     navigateTo(value.path)
  //   }
  //   if (value.type === 'file') {
  //     handlePreview(value)
  //   }
  // }

  // const updateUUID = value => {
  //   if (inSharedFolder && notUgly(value.share_uuid)) {
  //     localStorage.setItem('share_uuid', value.share_uuid)
  //   }
  //   if (inSharedFolder && notUgly(value.updated_at)) {
  //     localStorage.setItem('updated_at', value.updated_at)
  //   }
  // }

  return {
    handlePreview,
    handleConfirmDelete,
    handleDownload,
    handleCreateLinkShare,
    handleShareToMail,
    handleNewFolder,
    handleShareToUser,
    handleRename,

    onDropToMove,
    onDropTreeToFolder,

    handleConfirmRestores,
    handleConfirmDeleteForever,
    handleConfirmDuplicateOptions,

    // handleClick,
    // handleContextMenu,
    // handleDragStart,
    // handleDoubleClick,
  }
}

export default useHandleAction
