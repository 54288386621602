import crypto from 'crypto'

const AC_DATA_ENCODE_URL_NEW = '.acdata.acworks.'

// function encrypt3DES(data) {
//   console.log('des', data, AC_DATA_ENCODE_URL_NEW)
//   const md5Key = Buffer.from(AC_DATA_ENCODE_URL_NEW.substring(0, 8), 'utf-8')
//   const cipher = crypto.createCipheriv('des-cbc', md5Key, md5Key)
//   let encrypted = cipher.update(data, 'utf8', 'base64')
//   encrypted += cipher.final('base64')
//   return encrypted.replace('/', '&').slice(0, -1)
// }

function encrypt3DES(data) {
  const key = AC_DATA_ENCODE_URL_NEW + AC_DATA_ENCODE_URL_NEW.substring(0, 8)
  console.log('des', data, key)
  const md5key = Buffer.from(key, 'utf-8')
  const md5IV = Buffer.from(AC_DATA_ENCODE_URL_NEW.substring(0, 8), 'utf-8')
  const cipher = crypto.createCipheriv('des-ede3-cbc', md5key, md5IV)
  let encrypted = cipher.update(data, 'utf8', 'base64')
  encrypted += cipher.final('base64')
  return encrypted.replaceAll('/', '&').slice(0, -1)
}

export const encryptId = id => {
  // console.log(id, '===id==')
  // const mykey = crypto.createCipher('des', 'acdata.acworks')
  // let shareEncrypt = mykey.update(`${id}`, 'utf8', 'base64')
  // shareEncrypt += mykey.final('base64')
  // shareEncrypt = shareEncrypt.replace(/\//g, '&').slice(0, -1)
  // return shareEncrypt
  return encrypt3DES(JSON.stringify(id))
}

export default { encryptId }
