import { FOLDER_STATUS } from 'config/constant'
import { format, parse } from 'date-fns'
import { isUgly } from './path.util'

const { COLLAPSED, EXPANDED } = FOLDER_STATUS

export const listToTrees = (t, list) => {
  // const currentFolder = localStorage.getItem('current_folder')
  if (list === undefined || list === null) return []
  const result = []
  const level = { result }
  const openedFolders = JSON.parse(localStorage.getItem('opened_folders') || '[]')
  list.map(key => {
    // if (currentFolder.startsWith(key)) setActiveId(key)
    let status = COLLAPSED
    const isOpened = openedFolders.some(folder => folder.startsWith(key))

    if (isOpened) {
      status = EXPANDED
    }

    return key.split('/').reduce((r, name) => {
      if (!r[name]) {
        r[name] = { result: [] }
        r.result.push({
          name,
          type: 'folder',
          children: r[name].result,
          path: key,
          status,
          isHover: false,
          // origin: folders.shortcutFolders.includes(key) ? 'true' : '',
          origin: '',
        })
      }
      return r[name]
    }, level)
  })
  const tree = {
    children: [
      {
        name: t('my_folder'),
        type: 'folder',
        isRoot: true,
        path: '',
        children: result,
        isT: true,
        isHover: false,
        status: COLLAPSED,
        origin: false,
      },
    ],
  }

  return tree
}
export const listToTree = list => {
  if (list === undefined || list === null || !Array.isArray(list)) return []
  const result = []
  const level = { result }
  list.map(key => {
    return key.split('/').reduce((r, name) => {
      if (!r[name]) {
        r[name] = { result: [] }
        const obj = {
          title: name,
          fileName: name,
          name,
          type: 'folder',
          path: key,
          origin: '',
          key,
          children: r[name].result,
        }
        r.result.push(obj)
      }
      return r[name]
    }, level)
  })

  return result
}

export const formatTimeFromText = (time, locale) => {
  if (isUgly(time)) return null
  try {
    // const date = parse(time)
    const date = new Date(time)
    const formatedDate = format(date, 'PPP', { locale })
    return formatedDate
  } catch (e) {
    console.error(e)
    return null
  }
}

export default { listToTree }
